import { PriceState } from 'src/store/price/types';
import { PayloadAction } from '@reduxjs/toolkit';

import apiTypes from 'src/api/optimalprint-sdk.d';

export interface SetPricesActionPayload {
  categoryId: number;
  prices: apiTypes.AppBundle.Api.Entity.Product.V1.IProductPriceListProduct[];
}

const setPricesReducer = (state: PriceState, action: PayloadAction<SetPricesActionPayload>) => {
  const { prices, categoryId } = action.payload;

  for (let i = 0; i < prices.length; i += 1) {
    const price = prices[i];
    const { productId } = price;

    if (!state.prices[categoryId]) {
      state.prices[categoryId] = {};
    }

    // @ts-ignore
    state.prices[categoryId][productId] = prices[i];
  }
};

export default setPricesReducer;
