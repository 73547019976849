import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

interface Props {
  disabled?: boolean;
  on?: boolean;
  withPadding?: boolean;
  primary?: boolean;
  onClick?: (e: MouseEvent) => void;
  stopPropagation?: boolean;
  className?: string;
  icon?: React.ReactNode;
  text?: string | null | undefined;
  buttonStyle?: { [key: string]: string };
}

const Button = (props: Props) => {
  const {
    icon, text, disabled, on, onClick, className, stopPropagation, withPadding, primary, buttonStyle,
  } = props;

  const handleClick: any = (e: MouseEvent) => {
    if (stopPropagation) {
      e.stopPropagation();
    }
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <button
      style={buttonStyle}
      className={cn({
        [styles.button]: true,
        [styles.on]: on,
        [styles.disabled]: disabled,
        [styles.primary]: primary,
        [styles.withPadding]: withPadding,
        [className || '']: true,
      })}
      disabled={disabled}
      onClick={handleClick}
    >
      {icon || null}
      {text ? <span>{text}</span> : null}
    </button>
  );
};

export default Button;
