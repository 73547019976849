import { BundledProduct, ProductBundleState } from 'src/store/productBundle/types';
import { PayloadAction } from '@reduxjs/toolkit';

const changeBundledProductReducer = (state: ProductBundleState, action: PayloadAction<BundledProduct>) => {
  const targetBundledProduct = state.selectedProducts.find((selectedProduct) => selectedProduct.bundleGroupId === action.payload.bundleGroupId);
  if (targetBundledProduct) {
    targetBundledProduct.productId = action.payload.productId;
  } else {
    state.selectedProducts.push(action.payload);
  }
};

export default changeBundledProductReducer;
