import { AnyAction } from 'redux';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import { BundledProduct } from 'src/store/productBundle/types';
import { setProductBundleSelectedProductsAction } from '../slice';
import updateBundledProductsPriceOperation from './updateBundledProductsPriceOperation';

const setProductBundleSelectedProductsOperation = (selectedProducts: BundledProduct[]) => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => {
  await dispatch(setProductBundleSelectedProductsAction(selectedProducts));
  await dispatch(updateBundledProductsPriceOperation());
};

export default setProductBundleSelectedProductsOperation;
