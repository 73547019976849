import Axios, { AxiosRequestConfig } from 'axios';
import { GelatoApiDesignRendersLoader } from 'gelato-api-design-renders-loader';
import { opApiRequest } from 'src/api/apiRequest';
import opEndpoints from 'src/api/opEndpoints';
import parser from 'query-string-parser';

export interface RequestOptions {
  body?: any;
  headers?: any;
}

const requestProxy = (url: string, params: RequestOptions, method: 'POST' | 'GET') => {
  // use OP api to do calls required auth
  if (url.indexOf('image_from_json') >= 0) {
    const getParams = parser.parseQuery(url);
    return opApiRequest(
      opEndpoints.CUSTOMER_DESIGN_V3_PREVIEW_FOR_STRUCTURE,
      {
        structure: params.body,
        productUid: params.body.product_uid,
        page: getParams.page_nr,
        format: getParams.format,
      },
      'data',
      'POST',
    ).then((result) => ({ data: result }));
  }

  return new Promise((resolve, reject) => {
    const config: AxiosRequestConfig = {
      url,
      method,
      headers: params.headers,
    };

    if (method === 'POST') {
      config.headers = {
        ...config.headers,
        'Content-Type': 'application/json',
      };
      config.data = JSON.stringify(params.body);
    }
    Axios(config)
      .then((result) => {
        resolve({
          data: result.data,
        });
      })
      .catch((error) => {
        reject(error);
      });
  }) as Promise<{ data: any; error?: any }>;
};

const gelatoApiDesignRendersLoader = new GelatoApiDesignRendersLoader(
  { preflightUrl: window.integrationLayer.preFlightApiBaseUrl },
  (url: string, params: RequestOptions) => requestProxy(url, params, 'GET'),
  (url: string, params: RequestOptions) => requestProxy(url, params, 'POST'),
);

export default gelatoApiDesignRendersLoader;
