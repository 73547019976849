import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { Store } from 'src/store/';
import isPopupActive from 'src/store/app/selector/isPopupActive';
import hidePopupOperation from 'src/store/app/operation/hidePopupOperation';
import getIsMobile from 'src/store/app/selector/getIsMobile';
import { BsX } from 'react-icons/bs';
import styles from './index.module.scss';

interface OwnProps {
  popupId: string;
  title?: string;
  applyButtonTitle?: string;
  popupStyle?: { [key: string]: string };
  children: React.ReactNode;
  onClosePopup?: () => void;
  onShowPopup?: () => void;
  onApplyClick?: () => void;
}

interface Props extends OwnProps {
}

const Popup = ({
  popupId, title, applyButtonTitle, children, onClosePopup, onShowPopup, onApplyClick, popupStyle,
}: Props) => {
  const isActive = useSelector((store: Store) => isPopupActive(store, popupId));
  const isMobile = useSelector(getIsMobile);
  const dispatch = useDispatch();

  if (isActive) {
    document.documentElement.getElementsByTagName('body')[0].style.overflow = 'hidden';
  }

  const applyClick = () => {
    if (onApplyClick) onApplyClick();
    dispatch(hidePopupOperation());
  };

  const closeClick = () => {
    if (onClosePopup) onClosePopup();
    dispatch(hidePopupOperation());
  };

  useEffect(() => (
    () => {
      if (onShowPopup) onShowPopup();
    }
  ), [isActive]);

  if (!isActive) return null;

  return (
    <div
      className={cn({
        [styles.popup]: true,
        [styles.mobile]: isMobile,
      })}
      style={popupStyle}
    >
      <div
        className={cn({
          [styles.header]: true,
          [styles.desktop]: !isMobile,
        })}
      >
        {title}
        {!isMobile && (
          <div
            className={styles.x}
            onClick={closeClick}
          >
            <BsX />
          </div>
        )}
      </div>
      <div className={styles.popupContent}>
        {children}
      </div>
      {applyButtonTitle && <div className={styles.applyButton} onClick={applyClick}>{applyButtonTitle}</div>}
    </div>
  );
};

export default Popup;
