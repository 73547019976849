import { DesignData } from 'src/store/design/types';
import layoutSchemas from '../layoutSchemas/layoutSchemas';

const getCurrentLayoutIndex = (
  layoutSchemasSetName: string,
  design: DesignData | undefined,
  shemaIndexOffset: number,
  defaultLayoutIndex: number,
) => {
  if (!design) {
    return defaultLayoutIndex;
  }

  const currentLayoutName = design.spreads[0].pages[0].layoutSchemaName;
  let currentLayoutIndex = layoutSchemas[layoutSchemasSetName].findIndex((layoutSchema) => layoutSchema.name === currentLayoutName);

  if (currentLayoutIndex >= 0) {
    currentLayoutIndex += shemaIndexOffset;
  } else {
    currentLayoutIndex = defaultLayoutIndex;
  }

  return currentLayoutIndex;
};

export default getCurrentLayoutIndex;
