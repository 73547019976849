import { AnyAction } from 'redux';
import { DesignData } from 'src/store/design/types';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import { setDesignStructureAction, setDesignTotalPagesCountAction } from 'src/store/design/slice';
import countNumberOfPages from 'src/util/design/countNumberOfPages';

const setDesignStructureOperation = (structure: DesignData) => (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => {
  dispatch(setDesignStructureAction(structure));
  dispatch(setDesignTotalPagesCountAction(structure.page_count || countNumberOfPages(structure)));
};

export default setDesignStructureOperation;
