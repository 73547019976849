import { AnyAction } from 'redux';
import { opApiRequest } from 'src/api/apiRequest';
import endpoints from 'src/api/opEndpoints';
import apiTypes from 'src/api/optimalprint-sdk.d';
import isDesignSaving from 'src/store/design/selector/isDesignSaving';
import { DesignData } from 'src/store/design/types';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import getDesignContentHash from 'src/util/design/getDesignContentHash';
import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';
import {
  setDesignIsSavingAction,
  setDesignIdAction,
  setDesignIsPublicAction,
  setDesignIsLoadedAction,
} from 'src/store/design/slice';

const addCustomerDesignOperation = (categoryId: number, productTypeId: number, structure: DesignData, encPublicDesignId?: string, productId?: number) => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const store = getState();

  if (isDesignSaving(store)) {
    return;
  }
  const {
    clientPlatform,
  } = useIntegrationLayer();

  await dispatch(setDesignIsSavingAction(true));
  let response;
  try {
    response = (await opApiRequest(
      endpoints.CUSTOMER_DESIGN_V3_ADD,
      {
        encPublicDesignId,
        categoryId,
        productId,
        structure,
        isDesignCompleted: true,
        clientPlatform,
        contentHash: getDesignContentHash(structure),
      },
      'data',
      'POST',
    )) as apiTypes.AppBundle.Api.Response.Customer.Design.Design.V3.CustomerDesignAddV3Response;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Cannot add customer design, exception: ', e);
  }

  if (!response) {
    // eslint-disable-next-line no-console
    console.error('Cannot add customer design');
  }

  if (response) {
    await dispatch(setDesignIdAction(response.customerDesignId));
    // eslint-disable-next-line no-restricted-globals
    history.replaceState({}, '', `/designer?designId=${response.customerDesignId}`);
    await dispatch(setDesignIsPublicAction(false));
    await dispatch(setDesignIsLoadedAction(true));
  }

  await dispatch(setDesignIsSavingAction(false));
};

export default addCustomerDesignOperation;
