import { PayloadAction } from '@reduxjs/toolkit';
import { UpsellState, UpsellFeature } from 'src/store/upsell/types';
import update from 'immutability-helper';

const addUpsellPriceReducer = (
  state: UpsellState, action: PayloadAction<UpsellFeature>,
) => {
  if (!action.payload) return state;
  return update<UpsellState>(state, {
    upsellPrice: {
      [action.payload.upsellName]: { $set: action.payload },
    },
  });
};

export default addUpsellPriceReducer;
