import apiTypes from 'src/api/optimalprint-sdk.d';
import { Price } from 'src/store/price/types';

export interface AddonState {
  clipArtCategories: {
    [categoryId: string]: apiTypes.AppBundle.Api.Response.ClipArt.V1.ClipArtListV1Response.IClipArtResultCategory;
  };
  usedClipArts: {
    clipArts: apiTypes.AppBundle.Api.Response.Customer.Design.Design.V3.CustomerDesignGetClipArtsV3Response.IClipArt[];
    clipArtsHash: string;
    clipArtsPrice: Price;
  };
  searchString: string;
}

export interface AddonCategory {
  id: string;
  parentId?: string;
  name: string;
  subcategories?: AddonCategory[];
  elements: Addon[];
  count: number;
}

export enum AddonType {
  ADDON_VECTOR = 'ADDON_VECTOR',
  ADDON_BITMAP = 'ADDON_BITMAP',
  ADDON_DESIGN = 'ADDON_DESIGN',
  ADDON_CATEGORY = 'ADDON_CATEGORY',
}

export interface Addon {
  id: string;
  type: AddonType;
  name: string;
  preview?: string;
}

export interface AddonVector extends Addon {
  price?: string;
  tags?: string[];
  type: AddonType.ADDON_VECTOR;
  allowColorChange?: boolean;
  preview: string;
}

export interface AddonBitmap extends Addon {
  price?: string;
  tags?: string[];
  type: AddonType.ADDON_BITMAP;
  preview: string;
}

export interface AddonDesign extends Addon {
  type: AddonType.ADDON_DESIGN;
}
