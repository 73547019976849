import {
  LayoutSchema,
} from './layoutTypes';
import defineLayoutSchema from './defineLayoutSchema';

const INTERNAL_PADDING = 4;
const EXTERNAL_PADDING = 10;

const layoutSchemasMugSimplePhotoLeft: LayoutSchema[] = [
  defineLayoutSchema('msph_i1_1', 2, 1, 10, 0, 0, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      {},
    ],
  ], true),
  defineLayoutSchema('msph_i1_2', 2, 1, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      {},
    ],
  ], false),
  defineLayoutSchema('msph_i2_1', 2, 2, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
    ],
  ], false),
  defineLayoutSchema('msph_i2_2', 4, 1, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
  ], false),
  defineLayoutSchema('msph_i3_1', 4, 2, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      { width: 1, height: 2 },
    ],
    [
      { width: 1, height: 1 },
    ],
  ], false),
  defineLayoutSchema('msph_i4_1', 4, 2, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
  ], false),
  defineLayoutSchema('msph_i1t1_1', 2, 5, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 4 },
    ],
    [],
    [],
    [],
    [
      { width: 1, height: 1, type: 'text' },
    ],
  ], false),
  defineLayoutSchema('msph_i1t1_2', 2, 5, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1, type: 'text' },
    ],
    [
      { width: 1, height: 4 },
    ],
  ], false),
];

export default layoutSchemasMugSimplePhotoLeft;
