import { FileUploader } from 'file-uploader';
import React, { MutableRefObject, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Store } from 'src/store';
import { EditorPopupHash } from 'src/store/app/types';

type Props = {
  dispatch: ThunkDispatch<Store, void, AnyAction>;
  uploaderRef: MutableRefObject<FileUploader | undefined>;
};

const TrackBackButton = ({ dispatch, uploaderRef }: Props) => {
  const lastHash = useRef<string>('');

  const onHashChange = () => {
    const newHash = window.location.hash;
    if (newHash === lastHash.current) {
      return;
    }

    switch (lastHash.current) {
      case EditorPopupHash.uploader:
        uploaderRef.current && uploaderRef.current.close();
        break;
      default:
    }
    lastHash.current = window.location.hash;
  };

  useEffect(() => {
    window.addEventListener('hashchange', onHashChange, false);

    return () => {
      window.removeEventListener('hashchange', onHashChange);
    };
  }, []);
  return (<></>);
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(TrackBackButton);
