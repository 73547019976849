import { AnyAction } from 'redux';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import { MESSAGE_TYPE } from 'src/store/app/types';
import { ThunkDispatch } from 'redux-thunk';
import { Store } from 'src/store/index';

const sendSetDesignDataPostMessageOperation = (designData: any) => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => dispatch(sendPostMessage({
  type: MESSAGE_TYPE['design.setDesignData'],
  data: designData,
}));

export default sendSetDesignDataPostMessageOperation;
