import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import preflightApiRequest from 'src/api/preflightApiRequest';
import preFlightEndpoints from 'src/api/preFlightEndpoints';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import { MESSAGE_TYPE } from 'src/store/app/types';
import getProductUid from 'src/store/design/selector/getProductUid';
import { Store } from 'src/store/index';
import dataLayerPush from 'src/util/analytics/dataLayer';
import adjustEmptyDesignStructure from 'src/util/design/adjustEmptyDesignStructure';
import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';
import forceFormatChange from '../selector/forceFormatChange';
import getInternalUid from '../selector/getInternalUid';
import { setDesignIsLoadingAction } from '../slice';
import { DesignData } from '../types';

const sendProductUidChangedPostMessageOperation = (input: {
  productUid: string; internalUid: string; productId: number; categoryId: number;
 }) => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const store = getState();
  const currentInternalUid = getInternalUid(store);
  const currentUid = getProductUid(store);
  const integrationLayer = useIntegrationLayer();

  if (currentInternalUid === input.internalUid) {
    return;
  }
  dataLayerPush('apiEditorFormatSwitch', {
    switchedFrom: currentUid,
    switchedTo: input.productUid,
  });

  const callback = {
    type: MESSAGE_TYPE['product.productUidChanged'],
    data: input,
  };
  dispatch(setDesignIsLoadingAction(true));

  let designTemplate = await preflightApiRequest(
    preFlightEndpoints.PRODUCT_TEMPLATE,
    {
      product_uid: input.productUid,
      type: 'json',
    },
    'data',
  ) as DesignData;

  designTemplate = adjustEmptyDesignStructure(integrationLayer, store, designTemplate);

  await dispatch(sendPostMessage({
    type: MESSAGE_TYPE['design.changeFormat'],
    data: {
      productUid: input.productUid,
      forceFormatChange: forceFormatChange(store),
      designTemplate,
    },
    callback,
  }));
};

export default sendProductUidChangedPostMessageOperation;
