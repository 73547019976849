import React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Store } from 'src/store';
import isFeatureEnabled from 'src/store/app/selector/isFeatureEnabled';
import { Feature } from 'src/store/app/types';
import getAbstractProduct from 'src/store/design/selector/getAbstractProduct';
import { AbstractProduct } from 'src/store/design/types';
import Dropdown from './Dropdown';

interface Props {
  dispatch: ThunkDispatch<Store, void, AnyAction>;
  abstractProduct?: AbstractProduct;
  isFormatsTabAvailable: boolean;
}

const specificSelectors: string[] = [];

const Selectors = (
  {
    abstractProduct,
    isFormatsTabAvailable,
  }: Props,
) => {
  if (!abstractProduct || isFormatsTabAvailable) {
    return null;
  }

  return (
    <div>
      {abstractProduct.editorSelectors.map((selector) => {
        if (specificSelectors.indexOf(selector.typeName) !== -1) {
          return <span key={selector.typeName}>not implemented</span>;
        }
        return <Dropdown selector={selector} key={selector.typeName} />;
      })}
    </div>
  );
};

const mapStateToProps = (store: Store) => ({
  abstractProduct: getAbstractProduct(store),
  isFormatsTabAvailable: isFeatureEnabled(store, Feature.allowFormats),
});

export default connect(mapStateToProps)(Selectors);
