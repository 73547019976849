import apiTypes from 'src/api/optimalprint-sdk.d';
import { AddonVector, AddonType } from 'src/store/addon/types';

type ClipArt = apiTypes.AppBundle.Api.Response.ClipArt.V1.ClipArtListV1Response.IClipArt;

const clipArtToAddon = (
  clipArt: ClipArt,
  getPrice: (productId: number) => string,
): AddonVector => ({
  id: String(clipArt.clipArtId),
  type: AddonType.ADDON_VECTOR,
  name: clipArt.title,
  preview: clipArt.imageUrl,
  price: getPrice(clipArt.productId),
  tags: clipArt.tags || [],
  allowColorChange: clipArt.allowColorChange,
});

export default clipArtToAddon;
