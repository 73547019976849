import { AppBundle } from 'src/api/optimalprint-sdk.d';
import { MESSAGE_TYPE } from 'src/store/app/types';
import { Selector } from 'src/store/design/types';
import { BundledProduct } from 'src/store/productBundle/types';

type BundleGroup = AppBundle.Api.Entity.App.V1.AbstractProduct.IBundleGroup;
type ProductItem = AppBundle.Api.Entity.App.V1.AbstractProduct.ProductItem;

const getBundleProductsSelectors = (
  product: ProductItem, bundledProducts: BundledProduct[],
) => {
  // get Bundles for current product
  const { bundleGroups } = product;
  if (!bundleGroups || !bundleGroups.length) {
    return [];
  }

  // prepare selectors and mark selected
  const resultSelectors: Selector[] = [];

  bundleGroups.forEach((bundleGroup) => {
    const selectedProductId = bundledProducts.find((bundledProduct) => bundledProduct.bundleGroupId === bundleGroup.bundleGroupId)?.productId || 0;
    const selector = {
      typeName: bundleGroup.productTypeGroup,
      name: bundleGroup.label || '',
      options: bundleItemsToOptions(bundleGroup, selectedProductId),
      optionDisplay: 'fill',
    };
    resultSelectors.push(selector);
  });

  return resultSelectors;
};

const bundleItemsToOptions = (bundleGroup: BundleGroup, productId?: number) => (
  bundleGroup.items?.map((item, order) => ({
    value: String(item.productId),
    name: item.name || '',
    order,
    productId: item.productId,
    categoryId: (item as any).categoryId,
    preActivated: true,
    active: item.productId === productId,
    iconUrl: item.iconUrl,
    callback: {
      type: MESSAGE_TYPE['host.changeBundledProduct'],
      data: {
        bundleGroupId: bundleGroup.bundleGroupId,
        productId: item.productId,
      },
    },
  })) || []
);

export default getBundleProductsSelectors;
