/* eslint-disable max-len */
import React from 'react';
import Icon from 'src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const IconSave = ({ className }: Props) => (
  <Icon className={className}>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 7H20.5L25 12V25H7V7Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <rect x="12" y="7" width="8" height="5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <circle cx="16" cy="18" r="2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </Icon>
);

export default IconSave;
