import { Price } from 'src/store/price/types';
import { UpsellFeature } from 'src/store/upsell/types';

const getUpsellFeature = (upsellName: string, pricePerUnit: Price, quantity: number, totalPrice: Price): UpsellFeature => ({
  upsellName,
  pricePerUnit,
  quantity,
  totalPrice,
});

export default getUpsellFeature;
