import { AnyAction } from 'redux';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import { MESSAGE_TYPE } from 'src/store/app/types';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import getTemplateCanAddPages from 'src/store/template/selector/getTemplateCanAddPages';

const sendSetTemplateCanAddPagesPostMessageOperation = () => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const store = getState();
  await dispatch(sendPostMessage({
    type: MESSAGE_TYPE['template.setCanAddPages'],
    data: getTemplateCanAddPages(store),
  }));
};

export default sendSetTemplateCanAddPagesPostMessageOperation;
