import { FileUploader } from 'file-uploader';
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  BrowserRouter,
  Switch,
  Route,
} from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import PostMessageReceiver from 'src/component/PostMessageReceiver';
import { Store } from 'src/store';
import setAppInitialStateFromIntegrationLayerOperation
  from 'src/store/app/operation/setAppInitialStateFromIntegrationLayerOperation';
import setDesignInitialStateFromIntegrationLayerOperation
  from 'src/store/design/operation/setDesignInitialStateFromIntegrationLayerOperation';
import setTemplateInitialStateFromIntegrationLayerOperation
  from 'src/store/template/operation/setTemplateInitialStateFromIntegrationLayerOperation';
import Home from 'src/view/Home';
import TrackBackButton from './component/TrackBackButton';
import TrackBundledProducts from './component/TrackBundledProducts';
import hideMobileMenuBar from './util/op/hideMobileMenuBar';

export interface Props {
  dispatch: ThunkDispatch<Store, void, AnyAction>;
}

const App = ({ dispatch }: Props) => {
  const uploaderRef = useRef<FileUploader>();

  useEffect(() => {
    dispatch(setAppInitialStateFromIntegrationLayerOperation());
    dispatch(setDesignInitialStateFromIntegrationLayerOperation());
    dispatch(setTemplateInitialStateFromIntegrationLayerOperation());
    hideMobileMenuBar();
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/">
          <Home uploaderRef={uploaderRef} />
        </Route>
      </Switch>
      <PostMessageReceiver uploaderRef={uploaderRef} />
      <TrackBackButton uploaderRef={uploaderRef} />
      <TrackBundledProducts />
    </BrowserRouter>
  );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(App);
