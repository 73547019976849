interface Dictionary {
  [name: string]: number;
}

const spreadNameToUpsellProductTypeId = (spreadName: string) => (({
  front: 133,
  back: 134,
} as Dictionary)[spreadName]);

export default spreadNameToUpsellProductTypeId;
