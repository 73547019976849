import classNames from 'classnames';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import Spinner from 'src/component/Spinner';
import { Store } from 'src/store';
import isDesignLoading from 'src/store/design/selector/isDesignLoading';
import isDesignSaving from 'src/store/design/selector/isDesignSaving';
import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';
import getOpenedUploader from 'src/store/app/selector/getOpenedUploader';
import styles from './index.module.scss';

interface OwnProps {
}

interface Props extends OwnProps {
  isLoading: boolean;
  isSaving: boolean;
  isUploaderOpen?: boolean;
}

export const editorIframeElementId = 'editorIframe';

const Editor = (
  {
    isLoading,
    isSaving,
    isUploaderOpen,
  }: Props,
) => {
  const [timestamp] = useState(new Date().getTime());
  const { designerAppUrl } = useIntegrationLayer();
  return (
    <div
      className={classNames({
        [styles.editor]: true,
      })}
    >
      {isLoading || isSaving ? (<div className={styles.spinner}><Spinner /></div>) : null}
      <iframe
        src={`${designerAppUrl}?${timestamp}`}
        title="designer"
        data-hj-allow-iframe=""
        frameBorder="0"
        height="100%"
        width="100%"
        id={editorIframeElementId}
        className={classNames({
          [styles.noEvents]: isLoading || isUploaderOpen,
        })}
      />
    </div>
  );
};

const mapStateToProps = (store: Store, ownProps: OwnProps) => ({
  isLoading: isDesignLoading(store),
  isSaving: isDesignSaving(store),
  isUploaderOpen: getOpenedUploader(store),
});

export default connect(mapStateToProps)(Editor);
