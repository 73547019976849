import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import Carousel from 'src/component/Carousel';
import getFrameUpsellData from 'src/store/upsell/selector/getFrameUpsellData';
import getCurrencyId from 'src/store/price/selector/getCurrencyId';
import getPriceFormat from 'src/store/price/selector/getPriceFormat';
import Price from 'src/component/Price';
import getPrice from 'src/store/price/selector/getPrice';
import { Store } from 'src/store';
import productPriceToPrice from 'src/util/price/productPriceToPrice';
import getIsMobile from 'src/store/app/selector/getIsMobile';
import getFrameUpsellFrameTitle from 'src/store/upsell/selector/getFrameUpsellFrameTitle';
import { setFrameUpsellSelectedFrameAction } from 'src/store/upsell/slice';
import getFrameUpsellFrameProductId from 'src/store/upsell/selector/getFrameUpsellFrameProductId';
import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';
import styles from './index.module.scss';

const FrameSelector = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useSelector(getIsMobile);
  const frames = useSelector(getFrameUpsellData)?.items || [];
  const activeFrameProductId = useSelector(getFrameUpsellFrameProductId);
  const currencyId = useSelector(getCurrencyId);
  const priceFormat = useSelector(getPriceFormat);
  const categoryId = useIntegrationLayer().bundledItemsCategoryId;
  const frameTitle = useSelector(getFrameUpsellFrameTitle);
  const getPriceFromStore = useSelector((store: Store) => (productId: number) => getPrice(store, categoryId, productId, 1));

  const selectFrame = (index: number) => {
    dispatch(setFrameUpsellSelectedFrameAction(index));
  };

  return (
    <div className={styles.frameSelector}>
      <div className={cn(styles.selectorArea, { [styles.mobile]: isMobile })}>
        <div className={styles.addFrameTitle}>
          {t('opeh_add_frame')}
          :
          &nbsp;
          {frameTitle}
        </div>
        <Carousel>
          {frames.map((frame, index) => {
            const framePrice = getPriceFromStore(frame.productId);
            return (
              <div>
                <div
                  className={cn(styles.frame, {
                    [styles.active]: activeFrameProductId === frame.productId,
                  })}
                  onClick={() => selectFrame(index)}
                >
                  {frame.thumbnailUrl && <img src={frame.thumbnailUrl} alt="" />}
                  {!frame.thumbnailUrl && <span>{frame.name}</span>}
                </div>
                {framePrice
                  && currencyId
                  && priceFormat
                  && <Price className={styles.price} price={productPriceToPrice(framePrice)} currencyId={currencyId} priceFormat={priceFormat} />}
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default FrameSelector;
