import apiTypes from 'src/api/optimalprint-sdk.d';
import getCategoryId from 'src/store/design/selector/getCategoryId';
import getProductId from 'src/store/design/selector/getProductId';
import { Store } from 'src/store/index';
import getUpsellPriceData from 'src/store/upsell/selector/getUpsellPriceData';

const getCurrentProductUpsellPrice = (store: Store, upsellName: string, upsellProductTypeId: number, quantity: number) => {
  if (!store.price.prices) {
    return undefined;
  }

  const categoryId = getCategoryId(store);
  const productId = getProductId(store);
  if (!categoryId || !productId) {
    return undefined;
  }

  const categoryPrices = getUpsellPriceData(store)[categoryId];
  if (!categoryPrices) {
    return undefined;
  }

  const productPrice = categoryPrices[productId] as unknown as apiTypes.AppBundle.Api.Entity.Product.V1.IProductUpsellItems;
  if (!productPrice) {
    return undefined;
  }

  const upsellItem = (productPrice as any)[upsellName] as apiTypes.AppBundle.Api.Entity.Product.V1.IUpsellItem[];
  if (!upsellItem) {
    return undefined;
  }

  const upsellProduct = upsellItem.find((upsellProd) => upsellProd.productTypeId === upsellProductTypeId);
  if (!upsellProduct || !upsellProduct.prices) {
    return undefined;
  }

  const priceForQuantity = upsellProduct.prices.find((pq: apiTypes.AppBundle.Api.Entity.Product.V1.IProductPrice) => pq.q === quantity);
  return priceForQuantity;
};

export default getCurrentProductUpsellPrice;
