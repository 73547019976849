import { AnyAction } from 'redux';
import { opApiRequest } from 'src/api/apiRequest';
import endpoints from 'src/api/opEndpoints';
import apiTypes from 'src/api/optimalprint-sdk.d';
import sendSetDesignDataPostMessageOperation from 'src/store/app/operation/sendSetDesignDataPostMessageOperation';
import getDesignId from 'src/store/design/selector/getDesignId';
import getEncPublicDesignId from 'src/store/design/selector/getEncPublicDesignId';
import getQuantity from 'src/store/design/selector/getQuantity';
import isDesignLoading from 'src/store/design/selector/isDesignLoading';
import isDesignSaving from 'src/store/design/selector/isDesignSaving';
import isPublicDesign from 'src/store/design/selector/isPublicDesign';
import { DesignData } from 'src/store/design/types';
import fetchDesignImagesOperation from 'src/store/gallery/operation/fetchDesignImagesOperation';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import {
  setDesignIsLoadingAction,
  setDesignEncPublicIdAction,
  setDesignProductTypeIdAction,
  setDesignCategoryIdAction,
  setDesignQuantityAction,
  setDesignProductUidAction,
  setDesignIdAction,
  setDesignIsPublicAction,
  setDesignProductIdAction,
  setDesignStructureAction,
  setDesignTotalPagesCountAction, setDesignIsLoadedAction, setDesignInternalUidAction,
} from 'src/store/design/slice';
import fixPhpJSONConvertedObject from 'src/util/design/fixPhpJSONConvertedObject';

const fetchCustomerDesignOperation = () => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const store = getState();
  if (isDesignLoading(store) || isDesignSaving(store) || isPublicDesign(store)) {
    return;
  }
  const encPublicDesignId = getEncPublicDesignId(store);
  const quantity = getQuantity(store);
  await dispatch(setDesignIsLoadingAction(true));

  const designId = getDesignId(store);

  await dispatch(fetchDesignImagesOperation());

  let response;
  try {
    response = (await opApiRequest(
      endpoints.CUSTOMER_DESIGN_V3_GET,
      {
        designId,
      },
      'data',
      'POST',
    )) as apiTypes.AppBundle.Api.Response.Customer.Design.Design.V3.CustomerDesignGetV3Response;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Cannot fetch customer design, exception', e);
  }

  if (response) {
    let {
      productTypeId, productId, productUid, internalUid, categoryId,
    } = response;
    const { designStructure, totalPages } = response;

    if (!productTypeId) {
      productTypeId = window.integrationLayer.productTypeId;
      productId = window.integrationLayer.productId;
      productUid = String(window.integrationLayer.productUid);
      internalUid = String(window.integrationLayer.internalUid);
      categoryId = window.integrationLayer.categoryId;
    }

    const fixedDesignStructure = fixPhpJSONConvertedObject(designStructure);

    await dispatch(setDesignIsPublicAction(false));
    await dispatch(setDesignEncPublicIdAction(encPublicDesignId));
    await dispatch(setDesignIdAction(designId));
    await dispatch(setDesignStructureAction(fixedDesignStructure as unknown as DesignData));
    await dispatch(setDesignProductTypeIdAction(productTypeId));
    await dispatch(setDesignProductIdAction(productId));
    await dispatch(setDesignProductUidAction(productUid));
    await dispatch(setDesignInternalUidAction(internalUid));
    await dispatch(setDesignCategoryIdAction(categoryId));
    await dispatch(setDesignQuantityAction(quantity));
    await dispatch(setDesignIsLoadedAction(true));
    await dispatch(setDesignTotalPagesCountAction(totalPages));
    await dispatch(sendSetDesignDataPostMessageOperation(fixedDesignStructure));
  } else {
    // eslint-disable-next-line no-console
    console.error('Cannot fetch customer design');
  }

  await dispatch(setDesignIsLoadingAction(false));
};

export default fetchCustomerDesignOperation;
