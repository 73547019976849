import { DesignData, MediaAddon } from 'src/store/design/types';

interface AddonCounter {
  clipArtId: number;
  quantity: number;
}

interface ClipArtUsageData {
  [key: string]: {
    clipArtId: number;
    quantity: number;
  };
}

const collectAllUsedAddons = (design: DesignData) => {
  const usedAddons: ClipArtUsageData = {};
  design.spreads.forEach((spread) => {
    if (spread.pages[0] && spread.pages[0].groups && spread.pages[0].groups.media) {
      spread.pages[0].groups.media.forEach((element) => {
        if (element.type === 'addon') {
          const { addonId } = (element as MediaAddon);
          if (usedAddons[addonId]) {
            usedAddons[addonId].quantity += 1;
          } else {
            usedAddons[addonId] = {
              clipArtId: Number(addonId),
              quantity: 1,
            };
          }
        }
      });
    }
  });
  return (Object.values(usedAddons) as AddonCounter[]);
};

export default collectAllUsedAddons;
