import { UpsellState } from 'src/store/upsell/types';
import { PayloadAction } from '@reduxjs/toolkit';

import apiTypes from 'src/api/optimalprint-sdk.d';

export interface SetPricesActionPayload {
  categoryId: number;
  prices: apiTypes.AppBundle.Api.Entity.Product.V1.IProductUpsellItems[];
}

const setUpsellPriceDataReducer = (state: UpsellState, action: PayloadAction<SetPricesActionPayload>) => {
  const { prices, categoryId } = action.payload;

  for (let i = 0; i < prices.length; i += 1) {
    const price = prices[i];
    const { productId } = price;

    if (!state.upsellPriceData[categoryId]) {
      state.upsellPriceData[categoryId] = {};
    }

    // @ts-ignore
    state.upsellPriceData[categoryId][productId] = prices[i];
  }
};

export default setUpsellPriceDataReducer;
