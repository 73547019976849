import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import { MESSAGE_TYPE } from 'src/store/app/types';
import getProductUid from 'src/store/design/selector/getProductUid';
import { Store } from 'src/store/index';
import gelatoApiDesignRendersLoader from 'src/util/preview/gelatoApiDesignRendersLoader';

const sendEditorPreviewAvailabilityPostMessageOperation = (callback?: any) => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const store = getState();
  const productUid = getProductUid(store);
  if (!productUid) {
    return;
  }

  let isFlatPreviewAvailable = false;
  let is3DPreviewAvailable = false;

  const pageCount = 1;
  const previews = await gelatoApiDesignRendersLoader.getAvailablePreviews(productUid, pageCount);
  if (previews) {
    isFlatPreviewAvailable = previews.flat;
    is3DPreviewAvailable = previews.the3d;
  }

  await dispatch(sendPostMessage({
    type: MESSAGE_TYPE['preview.setFlatPreviewAvailable'],
    data: isFlatPreviewAvailable,
  }));

  await dispatch(sendPostMessage({
    type: MESSAGE_TYPE['preview.setThe3dPreviewAvailable'],
    data: is3DPreviewAvailable,
  }));
};

export default sendEditorPreviewAvailabilityPostMessageOperation;
