import { AnyAction } from 'redux';
import apiTypes from 'src/api/optimalprint-sdk.d';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import { MESSAGE_TYPE } from 'src/store/app/types';
import { ThunkDispatch } from 'redux-thunk';
import { UploadedImageData } from 'src/store/gallery/types';
import { Store } from 'src/store/index';

const sendGalleryUploadedImagesPostMessageOperation = (images: apiTypes.AppBundle.Api.Entity.Photo.V1.IPhoto[]) => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => {
  const imagesToSend = images.map((image) => ({
    url: image.url,
    file: {
      id: image.sourcePictureId || '',
      width: image.ow,
      height: image.oh,
    },
  } as UploadedImageData));

  dispatch(sendPostMessage({
    type: MESSAGE_TYPE['gallery.loadUploadedImages'],
    data: imagesToSend,
  }));
};

export default sendGalleryUploadedImagesPostMessageOperation;
