import apiTypes from 'src/api/optimalprint-sdk.d';
import { Price } from 'src/store/price/types';
import { isPhotobook } from 'src/util/product/productTypeIdsHelper';

export default (
  productPrice: apiTypes.AppBundle.Api.Entity.Product.V1.IProductPrice,
  productTypeId: number,
  minPages?: number,
  totalPages?: number,
) => {
  if (isPhotobook(productTypeId) && totalPages && minPages) {
    let extraPagesCount = totalPages - minPages;
    const extraPagePriceInitial = productPrice.appi || 0;
    const extraPagePrice = productPrice.app || 0;

    let priceInitial = productPrice.pi;
    let price = productPrice.p;

    if (extraPagesCount > 0) {
      extraPagesCount /= 2; // As we keep prices for 2 pages
      priceInitial += (extraPagesCount * extraPagePriceInitial);
      price += (extraPagesCount * extraPagePrice);
    }

    return {
      price,
      priceInitial,
    } as Price;
  }

  return {
    price: productPrice.p,
    priceInitial: productPrice.pi,
  } as Price;
};
