import React, { RefObject } from 'react';
import { BsChevronRight } from 'react-icons/bs';
import { SCROLL_TOLERANCE } from '../index';
import styles from './index.module.scss';

export interface OwnProps {
  scrollArea: RefObject<HTMLDivElement>;
  scrollOffset: number;
}

const CarouselRightArrow = ({ scrollArea }: OwnProps) => {
  if (!scrollArea.current) {
    return null;
  }
  const scrollRight = () => {
    scrollArea.current
    && scrollArea.current.scrollTo({ behavior: 'smooth', left: scrollArea.current.scrollLeft + scrollArea.current.offsetWidth });
  };

  const isOverflow = scrollArea.current.scrollWidth > scrollArea.current.scrollLeft + scrollArea.current.offsetWidth + SCROLL_TOLERANCE;
  if (!isOverflow) {
    return null;
  }

  return (
    <div onClick={scrollRight} className={styles.carouselRightArrow}>
      <BsChevronRight />
    </div>
  );
};

export default CarouselRightArrow;
