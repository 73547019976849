import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
}

const Icon = (props: Props) => {
  const { children, className } = props;
  return (
    <div
      className={cn(styles.opehIcon, 'opehIcon', className)}
    >
      {children}
    </div>
  );
};

export default Icon;
