import { UpsellState } from 'src/store/upsell/types';
import { Price } from 'src/store/price/types';
import addExtraPrice from 'src/util/price/addExtraPrice';

const updateUpsellTotalPriceReducer = (state: UpsellState) => {
  const upsellPrices = state.upsellPrice;
  state.upsellTotalPrice = Object.keys(upsellPrices).reduce((total: Price, upsellName: string) => {
    const upsellItem = upsellPrices[upsellName];
    return addExtraPrice(total, upsellItem.totalPrice, upsellItem.quantity);
  }, {
    price: 0,
    priceInitial: 0,
  } as Price);
};

export default updateUpsellTotalPriceReducer;
