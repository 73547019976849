import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import goToOptimalprintOperation from 'src/store/app/operation/goToOptimalprintOperation';
import hidePopupOperation from 'src/store/app/operation/hidePopupOperation';
import getForwardUrl from 'src/store/app/selector/getForwardUrl';
import { ReactComponent as OpplusLogo } from 'src/assets/opplus-logo.svg';
import getPersonalizedOpplusDetails from 'src/store/upsell/selector/getPersonilizedOpplusDetails';
import { opApiRequest } from 'src/api/apiRequest';
import opEndpoints from 'src/api/opEndpoints';
import Button from '../Button';
import Popup from '../Popup';
import styles from './index.module.scss';

const PromotePlusPopup = () => {
  const dispatch = useDispatch();
  const forwardUrl = useSelector(getForwardUrl);
  const personalizedOpplusDetails = useSelector(getPersonalizedOpplusDetails);

  const closePopup = () => {
    dispatch(hidePopupOperation());
    dispatch(goToOptimalprintOperation(forwardUrl));
  };

  const addPlusToBasket = async () => {
    try {
      await opApiRequest(opEndpoints.OP_PLUS_ADD_TO_CART, {}, 'data', 'POST');
      closePopup();
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.error('Could not add Opplus to cart', ex);
    }
  };

  const continueWithoutPlus = () => {
    closePopup();
  };

  return (
    <div>
      {personalizedOpplusDetails ? (
        <Popup
          popupId="PromotePlusPopup"
          onClosePopup={closePopup}
          popupStyle={{
            backgroundColor: '#CAE2E9',
          }}
        >
          <div className={styles.promotePlusPopup}>
            <div className={styles.promotePlusHeader}>
              {personalizedOpplusDetails.title.split('{logo}')[0]}
              &nbsp;
              <OpplusLogo style={{ height: '22px' }} />
            </div>
            <div className={styles.promotePlusText}>
              {personalizedOpplusDetails.description}
            </div>
            <div className={styles.promotePlusTextSubheader}>
              {personalizedOpplusDetails.details.txtPaymentTerms}
            </div>
            <Button
              onClick={addPlusToBasket}
              primary
              className={styles.addPlusButton}
              text={personalizedOpplusDetails.details.txtCheckoutWithPlus}
              buttonStyle={{
                backgroundColor: '#00909A',
                fontWeight: 'bold',
                borderRadius: '5px',
              }}
            />
            <Button
              onClick={continueWithoutPlus}
              text={personalizedOpplusDetails.details.txtCheckoutWithoutPlus}
              className={styles.continueToOpButton}
              buttonStyle={{
                backgroundColor: '#FFFFFF',
                color: '#00909A',
                border: '2px solid #00909A',
                fontWeight: 'bold',
                borderRadius: '5px',
              }}
            />
            <a
              href={personalizedOpplusDetails.details.learnMoreUrl}
              className={styles.promotePlusLearnMore}
            >
              {personalizedOpplusDetails.details.txtLearnMore}
            </a>
            <div className={styles.promoteOpplusImage}>
              <img src={personalizedOpplusDetails.details.backgroundImg} alt="" />
            </div>
          </div>
        </Popup>
      ) : null}
    </div>
  );
};

export default PromotePlusPopup;
