import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Store } from 'src/store';
import getProductId from 'src/store/design/selector/getProductId';
import getProductBundleSelectedProducts from 'src/store/productBundle/selector/getProductBundleSelectedProducts';
import { BundledProduct } from 'src/store/productBundle/types';
import globalVars from 'src/util/globalVars/globalVars';

type Props = {
  dispatch: ThunkDispatch<Store, void, AnyAction>;
  bundledProducts: BundledProduct[];
  productId?: number;
};

const TrackBundledProducts = ({ bundledProducts, productId }: Props) => {
  useEffect(() => {
    globalVars.bundledProducts = bundledProducts;
    globalVars.productId = productId;
  }, [bundledProducts, productId]);
  return (<></>);
};

const mapStateToProps = (store: Store) => ({
  bundledProducts: getProductBundleSelectedProducts(store),
  productId: getProductId(store),
});

export default connect(mapStateToProps)(TrackBundledProducts);
