import { AnyAction } from 'redux';
import { opApiRequest } from 'src/api/apiRequest';
import apiTypes from 'src/api/optimalprint-sdk.d';
import endpoints from 'src/api/opEndpoints';
import goToOptimalprintOperation from 'src/store/app/operation/goToOptimalprintOperation';
import getForwardUrl from 'src/store/app/selector/getForwardUrl';
import updateCustomerDesignOperation from 'src/store/design/operation/updateCustomerDesignOperation';
import getCategoryId from 'src/store/design/selector/getCategoryId';
import getDesignId from 'src/store/design/selector/getDesignId';
import getProductUid from 'src/store/design/selector/getProductUid';
import getQuantity from 'src/store/design/selector/getQuantity';
import isDesignSaving from 'src/store/design/selector/isDesignSaving';
import { setDesignIsSavingAction, setDesignIsLoadingAction, setDesignOrderItemIdAction } from 'src/store/design/slice';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import getProductBundleSelectedProducts from 'src/store/productBundle/selector/getProductBundleSelectedProducts';
import fetchFrameUpsellDataOperation from 'src/store/upsell/operation/fetchFrameUpsellDataOperation';
import isFeatureEnabled from 'src/util/feature/isFeatureEnabled';
import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';
import getFrameUpsellData from 'src/store/upsell/selector/getFrameUpsellData';
import getStructure from 'src/store/design/selector/getStructure';
import isDesignEmpty from 'src/util/design/isDesignEmpty';
import sendEmptyProductWarningOperation from './sendEmptyProductWarningOperation';
import showPopupOperation from './showPopupOperation';
import { Feature } from '../types';

const addToBasketOperation = () => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const store = getState();
  const designData = getStructure(store);
  const categoryId = getCategoryId(store);

  if (isDesignSaving(store)) {
    return;
  }

  if (isFeatureEnabled(Feature.DisplayWarningPopupWhenEmptyDesign) && designData && isDesignEmpty(designData)) {
    dispatch(sendEmptyProductWarningOperation());
    return;
  }

  try {
    await dispatch(setDesignIsLoadingAction(true));
    await dispatch(setDesignIsSavingAction(true));
    await dispatch(updateCustomerDesignOperation());

    const orderData = await opApiRequest(
      endpoints.CART_V1_DESIGN_ADD,
      {
        quantity: getQuantity(store),
        customerDesignId: getDesignId(store),
      },
      'data',
      'POST',
    ) as apiTypes.AppBundle.Api.Response.Cart.V1.IItemAddV1Response;

    orderData?.orderItemId && dispatch(setDesignOrderItemIdAction(orderData.orderItemId));

    const bundledProducts = getProductBundleSelectedProducts(store);

    for (let i = 0; i < bundledProducts.length; i += 1) {
      if (bundledProducts[i].productId > 0) {
      // eslint-disable-next-line no-await-in-loop
        await opApiRequest(
          endpoints.CART_V1_PRODUCT_ADD,
          {
            quantity: getQuantity(store),
            customerDesignId: getDesignId(store),
            productId: bundledProducts[i].productId,
            categoryId,
            includeAnalytics: true,
            bundleOwnerOrderItemId: orderData.orderItemId,
          },
          'data',
          'POST',
        );
      }
    }

    if (orderData) {
      // eslint-disable-next-line no-restricted-globals
      history.replaceState({}, '', `/designer?order_item_id=${orderData.encOrderItemId}&back_url=${window.integrationLayer.forwardUrl}`);
    }

    const { categoryName } = useIntegrationLayer();
    const productUid = getProductUid(store);

    window.dataLayer.push({
      event: 'editorAddToBasket',
      category: categoryName,
      product: productUid,
      editorVersion: 'New',
    });

    const bundledProductNotSelected = bundledProducts.length === 0 || bundledProducts[0].productId === 0;
    if (bundledProductNotSelected) {
      await dispatch(fetchFrameUpsellDataOperation());
      const bundledProductsAvailable = getFrameUpsellData(getState());
      if (bundledProductsAvailable) {
        dispatch(showPopupOperation('SelectFramePopup'));
        return;
      }
    }

    dispatch(goToOptimalprintOperation(getForwardUrl(store)));
  } catch (e) {
    await dispatch(setDesignIsLoadingAction(false));
    await dispatch(setDesignIsSavingAction(false));
    // eslint-disable-next-line no-console
    console.error('Cannot update customer order item quantity', e);
  }
};

export default addToBasketOperation;
