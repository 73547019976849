import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Store } from 'src/store';
import { showBackdropAction } from 'src/store/app/slice';

const showBackdropOperation = (show: boolean) => (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => {
  dispatch(showBackdropAction(show));
};

export default showBackdropOperation;
