import apiTypes from 'src/api/optimalprint-sdk.d';

export interface FormatState {
  currentFormat?: FormatData;
  /**
   * available format is a list of alternative formats that the main design (loaded) can be switched to
   * ex. for layflat PB 28x28, the list will have other sizes: 21x28 & 20x20
   */
  availableFormatsAreLoading: boolean;
  availableFormatsAreLoaded: boolean;
  availableFormats?: FormatData[];
  /**
   * All formats is a list of all possible formats that can be purchased in given locale
   */
  allFormats?: apiTypes.AppBundle.Api.Entity.PaperFormat.V1.IPaperFormat[];
  allFormatsAreLoading: boolean;
  allFormatsAreLoaded: boolean;
}

export interface FormatData {
  productUid: string;
  internalUid?: string;
  encPublicDesignId: string;
  productId: number;
  paperFormatId: number;
  productTypeId: number;
  categoryId: number;
  orientationId: number;
  name: string;
}

export enum FormatProductType {
  FLIPBOOKS = 1,
  CANVASES = 2,
  MUGS = 3,
  POSTERS = 4,
  ACRYLIC_PRINTS = 5,
  METALLIC_PRINTS = 6,
  LAYFLAT = 7,
}
