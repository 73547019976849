export interface DesignState {
  isPublic: boolean;
  encPublicId?: string;
  designId?: number;
  structure?: DesignData;
  productTypeId?: number;
  productId?: number;
  orderItemId?: number;
  productUid?: string;
  internalUid?: string;
  quantity?: number;
  originalQuantity?: number;
  categoryId?: number;
  totalPagesCount?: number;
  hasStructureChanged: boolean;
  isLoaded: boolean;
  errors: DesignError[];
  abstractProduct?: AbstractProduct;
  selectedProductAttributes: { [typeName: string]: Attribute };

  isLoading: boolean;
  isSaving: boolean;
}

export interface AbstractProduct {
  abstractProductId: number;
  categoryId: number;
  productTypeId: number;
  baseProductUid: string;
  productPageSelectors: Selector[];
  products: Product[];
  filteredProducts: Product[];
  editorSelectors: Selector[];
  name: string;
}

export interface Selector {
  typeName: string;
  name: string;
  isHidden?: boolean;
  options: SelectorOption[];
  type?: 'complex' | 'color';
}

export interface Attribute {
  typeName: string;
  value: string;
}

export interface Product {
  productId: number;
  productTypeId: number;
  paperFormatId: number;
  paperTypeId: number;
  colorTypeId: number;
  productUid: string;
  internalUid: string;
  abstractProductId: number;
  previews: string[];
  attributes: Attribute[];
}

export interface SelectorOption {
  value: string;
  name: string;
  hexColor?: string;
  hexColor2?: string;
  order: number;
}

export enum DesignError {
  uploadInProgress = 'uploadInProgress',
}

// COPIED FROM EDITOR
// https://github.com/gelatoas/gelato-api-ui-design-editor/blob/staging/src/store/design/types.ts
// Should be extracted to separate module and included via node_modules

export interface PageCountLimit {
  min: number;
  max: number;
  increment: number;
}

export interface DesignData {
  product_uid: string;
  spreads: Spread[];
  page_count?: number;
  page_count_limit?: PageCountLimit;
}

export interface Spread {
  pages: Page[];
  name: string;
  layoutSchemaName?: string;
}

export interface Page {
  x: number;
  y: number;
  width: number;
  height: number;
  page_nr: number;
  groups: Groups;
  layoutSchemaName?: string;
}

export interface Groups {
  bleed?: Bleed[];
  mediabox?: MediaBox[];
  content?: Content[];
  media?: MediaElement[];
  diecut?: Diecut[];
}

export interface GroupElement {
  type: string;
  name: string;
  group: string;
  x: number;
  y: number;
  width: number;
  height: number;
  path?: string;
  extra?: {
    [key: string]: any;
  };
}

export interface Bleed extends GroupElement {
  path: string;
}

export interface Diecut extends GroupElement {
  path: string;
  sx: number;
  sy: number;
}

export interface MediaBox extends GroupElement {

}

export interface Content extends GroupElement {
  margin?: {
    left: number;
    right: number;
    top: number;
    bottom: number;
  } | number;
  title?: string;
  description?: string[];
}

export interface MediaElement extends GroupElement {
  zIndex: number;
  uuid: number;
  r: number;
  sample?: boolean;
}

export interface MediaImage extends MediaElement {
  url: string;
  px: number;
  py: number;
  pw: number;
  ph: number;
  pr: number;
}

export interface MediaText extends MediaElement {
  px: number;
  py: number;
  pw: number;
  ph: number;
  pr: number;
  sx: number;
  sy: number;
  fill: string;
  path: string;
  extra: {
    text: string;
    fontFamily: string;
    textAlign: string;
    fontSize: number;
    lineHeight: number;
    italic: boolean;
    fontWeight: string;
    underline: boolean;
    textDirection: string;
  };
}

export interface MediaAddon extends MediaElement {
  addonId: string;
  subType: string;
  px: number;
  py: number;
  pw: number;
  ph: number;
  pr: number;
}

// EOF COPIED FROM EDITOR
