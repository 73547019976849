import { LayoutPadding } from './layoutTypes';

// eslint-disable-next-line object-curly-newline
const numberToPadding = (offset: number, defaultPadding: LayoutPadding = { left: 1, right: 1, top: 1, bottom: 1 }) => ({
  left: defaultPadding.left > 0 ? offset : 0,
  right: defaultPadding.right > 0 ? offset : 0,
  top: defaultPadding.top > 0 ? offset : 0,
  bottom: defaultPadding.bottom > 0 ? offset : 0,
} as LayoutPadding);

export default numberToPadding;
