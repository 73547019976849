import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { opApiRequest } from 'src/api/apiRequest';
import endpoints from 'src/api/opEndpoints';
import apiTypes from 'src/api/optimalprint-sdk.d';
import { Store } from 'src/store/index';
import getDesignId from 'src/store/design/selector/getDesignId';
import fetchPricesOperation from 'src/store/price/operation/fetchPricesOperation';
import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';
import { setFrameUpsellDataAction, setFrameUpsellSelectedFrameAction } from '../slice';

const fetchFrameUpsellDataOperation = () => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const store = getState();
  const customerDesignId = getDesignId(store);
  const response = await opApiRequest(
    endpoints.CUSTOMER_UPSELL_V1_EDITOR_SCREEN,
    {
      customerDesignId,
    },
    'data',
    'GET',
  ) as apiTypes.AppBundle.Api.Response.ProductUpsell.V1.ProductUpsellEditorScreenV1Response;

  if (!response) {
    // eslint-disable-next-line no-console
    console.error('Cannot fetch editor screen upsell data');
    return;
  }
  const upsellData = response.items.length > 0 ? response : undefined;
  dispatch(setFrameUpsellSelectedFrameAction(upsellData ? 1 : undefined));

  dispatch(setFrameUpsellDataAction(upsellData));
  if (upsellData) {
    // apply voucher
    try {
      await opApiRequest(
        endpoints.CUSTOMER_CART_V1_VOUCHER_ADD,
        {
          code: response.voucher,
        },
        'data',
        'POST',
      );
    // eslint-disable-next-line no-empty
    } catch {
    }
    // fetching updated prices
    const productIdsToUpdatePrices = upsellData.items.map((item) => item.productId);
    await dispatch(fetchPricesOperation(useIntegrationLayer().bundledItemsCategoryId, productIdsToUpdatePrices));
  }
};

export default fetchFrameUpsellDataOperation;
