import React from 'react';
import { connect } from 'react-redux';
import getSelectorAvailableOptions from 'src/store/design/selector/getSelectorAvailableOptions';
import { Selector, SelectorOption } from 'src/store/design/types';
import { Store } from 'src/store';
import styles from './index.module.scss';
import Item from './Item';

interface OwnProps {
  selector: Selector;
}

interface Props extends OwnProps {
  options: SelectorOption[];
}

const Items = ({ options, selector }: Props) => (
  <div>
    <div className={styles.formatContainer}>
      {options.map((o: SelectorOption) => (
        <Item selectorTypeName={selector.typeName} option={o} key={`${selector.name}${o.value}`} />
      ))}
    </div>
  </div>
);

const mapStateToProps = (store: Store, props: OwnProps) => ({
  options: getSelectorAvailableOptions(store, props.selector.typeName),
});

export default connect(mapStateToProps)(Items);
