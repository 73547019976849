import React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import changeProductAttributeOperation from 'src/store/design/operation/changeProductAttributeOperation';
import isAttributeSelected from 'src/store/design/selector/isAttributeSelected';
import { SelectorOption } from 'src/store/design/types';
import setOpenedDropdownMenuOperation from 'src/store/app/operation/setOpenedDropdownMenuOperation';
import { Store } from 'src/store';
import styles from './index.module.scss';

interface OwnProps {
  selectorTypeName: string;
  option: SelectorOption;
}

interface Props extends OwnProps {
  isSelected: boolean;
  dispatch: ThunkDispatch<Store, void, AnyAction>;
}

const Item = (
  {
    option,
    selectorTypeName,
    isSelected,
    dispatch,
  }: Props,
) => {
  const onClick = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    await dispatch(setOpenedDropdownMenuOperation(undefined));
    await dispatch(changeProductAttributeOperation(selectorTypeName, option.value));
  };

  return (
    <div
      className={styles.format}
      onClick={onClick}
    >
      <div className={styles.checkbox}>
        <input
          type="radio"
          name="alternate_format"
          value={option.value}
          defaultChecked={isSelected}
        />
      </div>
      <div className={styles.nameAndPrice}>
        <div>{option.name}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (store: Store, ownProps: OwnProps) => ({
  isSelected: isAttributeSelected(store, ownProps.selectorTypeName, ownProps.option.value),
});

export default connect(mapStateToProps)(Item);
