const ProductType = {
  BUSINESS_CARDS: 1,
  LETTERHEADS: 2,
  CORRESPONDENCE_CARDS: 3,
  INVITATIONS: 4,
  GREETING_CARDS: 5,
  PLACEMENT_CARDS: 6,
  POSTCARDS: 7,
  BROCHURES: 9,
  WEDDING_INVITATION: 10,
  FOLDED_POSTCARDS: 17,
  FOLDERS: 18,
  APPOINTMENT_CARDS: 21,
  DRIVING_INSTRUCTIONS: 22,
  CHURCH_PROGRAM: 23,
  MENU: 25,
  TYPE_PLACE_CARD: 26,
  THANK_YOU_CARD: 27,
  TYPE_ENVELOPES: 28,
  TYPE_BLANK_ENVELOPES: 29,
  UPLOAD_PRODUCT: 30,
  RSVP_CARDS: 32,
  CUSTOM_DESIGN_SERVICE: 33,
  UPLOAD_PRODUCT_150G: 36,
  PRINTED_INVOICE_FEE: 37,
  INVOICE_FEE: 34,
  PEN: 38,
  FLYERS: 47,
  TYPE_COATING: 48,
  WALL_CALENDAR: 52,
  LABEL_ADDRESS: 53,
  POSTERS: 54,
  LABEL_CLOTHES: 55,
  LABEL_MARKET: 56,
  BC_HOLDER: 57,
  PHOTOBOOK: 58,
  PHOTOBOOK_COVER: 59,
  LABEL_ADDRESS_BUSINESS: 60,
  POSTERS_BUSINESS: 61,
  POSTCARDS_BUSINESS: 62,
  INVITATIONS_BUSINESS: 63,
  TYPE_VARIABLE_DATA_FEE: 66,
  TYPE_MANUALLY_LOOK_FEE: 68,
  TYPE_PREMIUM_PAPER: 70,
  TYPE_BLANK_ENVELOPES_WITH_PRINT: 72,
  BAPTISM_INVITATIONS: 73,
  CONFIRMATION_INVITATIONS: 74,
  BIRTHDAY_ANNOUNCEMENT_CARDS: 75,
  TYPE_PREMIUM: 76,
  WALL_CALENDAR_WITH_HOOK_PRODUCT_TYPE_ID: 77,
  PHOTOBOOK_HARDCOVER: 78,
  FLYPBOOK_SOFTCOVER: 79,
  FLYPBOOK_HARDCOVER: 80,
  POSTERS_LARGE: 81,
  FLYPBOOK_SERIES_SOFTCOVER: 82,
  SUBSTRATE_STANDARD: 83,
  ARTISTIC_POSTERS: 84,
  FAMILY_PLANNER_CALENDAR: 85,
  SPOT_FINISH: 86,
  TRIM: 87,
  CANVAS_SLIM: 88,
  CANVAS_PREMIUM: 89,
  CANVAS_MEDIUM: 90,
  MUG_SIMPLE_PHOTO: 91,
  MUG_WRAP_AROUND: 92,
  STICKER_GIFT: 93,
  STICKER_RETURN_ADDRESS: 94,
  STICKER_ADDRESS: 95,
  BIRTH_POSTER: 96,
  NOTEBOOK_SOFTBACK: 97,
  NOTEBOOK_SPIRAL: 98,
  ACTIVITY_POSTER: 99,
  POSTER_FRAME: 100,
  POSTER_HANGER: 101,
  BOOK_SOFTBACK: 102,
  BOOK_SPIRAL: 103,
  PHOTOBOOK_LAY_FLAT: 106,
};

export const photoBookIds = [
  ProductType.FLYPBOOK_SOFTCOVER,
  ProductType.FLYPBOOK_HARDCOVER,
  ProductType.PHOTOBOOK_HARDCOVER,
  ProductType.PHOTOBOOK,
  ProductType.FLYPBOOK_SERIES_SOFTCOVER,
  ProductType.PHOTOBOOK_LAY_FLAT,
];

export const apparelProductTypeIds = [
  107, 108, 109, 110, 111, 112, 113, 114, 115, 116,
  117, 118, 119, 120, 121, 122, 123, 141, 142, 143,
  146, 147, 148, 149, 151, 152, 154, 155, 156,
  157, 158, 159,
];

export const mugProductTypeIds = [
  127,
];

export default ProductType;
