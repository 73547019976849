import React from 'react';
import { connect } from 'react-redux';
import DropdownMenu from 'src/component/DropdownMenu';
import Items from 'src/component/TopBar/CenterSection/Selectors/Dropdown/DropdownItems/Items';
import { Store } from 'src/store';
import getOpenedDropdownMenu from 'src/store/app/selector/getOpenedDropdownMenu';
import { Selector } from 'src/store/design/types';
import styles from './index.module.scss';

interface OwnProps {
  selector: Selector;
}

interface Props extends OwnProps {
  isDropdownVisible: boolean;
}

const DropdownItems = ({ isDropdownVisible, selector }: Props) => (
  <DropdownMenu
    isVisible={isDropdownVisible}
    className={styles.dropDown}
  >
    <Items selector={selector} />
  </DropdownMenu>
);

const mapStateToProps = (store: Store, props: OwnProps) => ({
  isDropdownVisible: getOpenedDropdownMenu(store) === props.selector.typeName,
});

export default connect(mapStateToProps)(DropdownItems);
