import apiTypes from 'src/api/optimalprint-sdk.d';
import { AddonCategory } from 'src/store/addon/types';
import clipArtToAddon from './clipArtToAddon';

type ClipArtCategory = apiTypes.AppBundle.Api.Response.ClipArt.V1.ClipArtListV1Response.IClipArtResultCategory;

const clipArtCategoryToAddonCategory = (
  clipArtCategory: ClipArtCategory,
  parentCategoryId: number,
  getPrice: (productId: number) => string,
): AddonCategory => ({
  id: String(clipArtCategory.clipArtCategoryId),
  parentId: parentCategoryId > 0 ? String(parentCategoryId) : undefined,
  name: clipArtCategory.title,
  subcategories: clipArtCategory.subCategories?.map((clipArtCat) => clipArtCategoryToAddonCategory(clipArtCat, clipArtCategory.clipArtCategoryId, getPrice)),
  elements: clipArtCategory.clipArts ? clipArtCategory.clipArts.map((clipArt) => clipArtToAddon(clipArt, getPrice)) : [],
  count: clipArtCategory.clipArts ? clipArtCategory.clipArts.length : 0,
});

export default clipArtCategoryToAddonCategory;
