import React, { RefObject } from 'react';
import { BsChevronLeft } from 'react-icons/bs';
import { SCROLL_TOLERANCE } from '../index';
import styles from './index.module.scss';

export interface OwnProps {
  scrollArea: RefObject<HTMLDivElement>;
  scrollOffset: number;
}

const CarouselLeftArrow = ({ scrollArea }: OwnProps) => {
  if (!scrollArea.current) {
    return null;
  }
  const scrollLeft = () => {
    if (!scrollArea.current) {
      return;
    }
    const stickToTheLeftEdge = scrollArea.current.scrollLeft - scrollArea.current.offsetWidth < SCROLL_TOLERANCE;
    const scrollToLeft = stickToTheLeftEdge ? 0 : scrollArea.current.scrollLeft - scrollArea.current.offsetWidth;
    scrollArea.current.scrollTo({ behavior: 'smooth', left: scrollToLeft });
  };

  if (scrollArea.current.scrollLeft < SCROLL_TOLERANCE) {
    return null;
  }

  return (
    <div onClick={scrollLeft} className={styles.carouselLeftArrow}>
      <BsChevronLeft />
    </div>
  );
};

export default CarouselLeftArrow;
