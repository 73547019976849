import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';
import { AnyAction } from 'redux';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import {
  setAppBackUrlAction,
  setAppBasketUrlAction, setAppDraftsUrlAction,
  setAppEditorModeAction,
  setAppForwardUrlAction,
  setAppIsMobileAction,
  setAppFeaturesAction,
} from 'src/store/app/slice';

const setAppInitialStateFromIntegrationLayerOperation = () => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => {
  const {
    backUrl,
    editorMode,
    forwardUrl,
    basketUrl,
    draftsUrl,
    isMobile,
    features,
  } = useIntegrationLayer();
  await dispatch(setAppFeaturesAction(features));
  await dispatch(setAppBackUrlAction(backUrl));
  await dispatch(setAppEditorModeAction(editorMode));
  await dispatch(setAppBasketUrlAction(basketUrl));
  await dispatch(setAppForwardUrlAction(forwardUrl));
  await dispatch(setAppDraftsUrlAction(draftsUrl));
  await dispatch(setAppIsMobileAction(isMobile));
};

export default setAppInitialStateFromIntegrationLayerOperation;
