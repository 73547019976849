import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import isBackdropVisible from 'src/store/app/selector/isBackdropVisible';
// import showBackdropOperation from 'src/store/app/operation/showBackdropOperation';
// import hidePopupOperation from 'src/store/app/operation/hidePopupOperation';
import styles from './index.module.scss';

const Backdrop = () => {
  const showBackdrop = useSelector(isBackdropVisible);
  // const dispatch = useDispatch();

  const hideBackdrop = () => {
    // dispatch(showBackdropOperation(false));
    // dispatch(hidePopupOperation());
  };

  return (
    <div
      className={cn({
        [styles.backdrop]: true,
        [styles.visible]: showBackdrop,
      })}
      onClick={hideBackdrop}
    />
  );
};

export default Backdrop;
