import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Store } from 'src/store/index';
import { hidePopupAction } from 'src/store/app/slice';
import showBackdropOperation from './showBackdropOperation';

const hidePopupOperation = () => (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => {
  document.documentElement.getElementsByTagName('body')[0].style.overflow = 'auto';
  dispatch(showBackdropOperation(false));
  dispatch(hidePopupAction());
};

export default hidePopupOperation;
