import { AnyAction } from 'redux';
import { opApiRequest } from 'src/api/apiRequest';
import endpoints from 'src/api/opEndpoints';
import apiTypes from 'src/api/optimalprint-sdk.d';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import { debounce } from 'lodash';
import { addClipartCategoryAction, setClipArtSearchStringAction } from '../slice';
import getClipArtCategory from '../selector/getClipArtCategory';
import sendClipArtCategoryPostMessageOperation from './sendClipArtCategoryPostMessageOperation';
import fetchClipArtsPriceOperation from './fetchClipArtsPriceOperation';
import sendClipArtSearchResultsPostMessageOperation from './sendClipArtSearchResultsPostMessageOperation';

type ClipArtCategory = apiTypes.AppBundle.Api.Response.ClipArt.V1.ClipArtListV1Response.ClipArtResultCategory;

const fetchClipArts = async (categoryId: number, searchString: string, limit: number, offset: number) => {
  try {
    const categoryData = (await opApiRequest(
      endpoints.CLIP_ART_V1_LIST,
      {
        clipArtCategoryId: categoryId,
        limit,
        offset,
        query: searchString,
      },
      'data.category',
      'GET',
    )) as apiTypes.AppBundle.Api.Response.ClipArt.V1.ClipArtListV1Response.ClipArtResultCategory;

    if (!categoryData) {
      return undefined;
    }

    if ((categoryData.clipArts.length) >= limit) {
      const nextRequest = await fetchClipArts(categoryId, searchString, limit, offset + limit);
      categoryData.clipArts = [...categoryData.clipArts, ...nextRequest?.clipArts || []];
    }
    return categoryData as ClipArtCategory;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Could not fetch clip arts category, exception: ', e);
    return undefined;
  }
};

const fetchSearchResults = async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  clipArtCategoryId: number,
  searchString: string,
  requestVersion: number,
  limit: number,
) => {
  // Search flow
  const searchResults = await fetchClipArts(clipArtCategoryId, searchString, Number.MAX_SAFE_INTEGER, 0);
  if (searchResults) {
    await dispatch(fetchClipArtsPriceOperation(searchResults));
    dispatch(sendClipArtSearchResultsPostMessageOperation(searchResults, requestVersion));
  }
};

const fetchSearchResultsDebounced = debounce(fetchSearchResults, 250);

const fetchClipArtCategoryOperation = (clipArtCategoryId = 0, searchString = '', requestVersion = 0) => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  dispatch(setClipArtSearchStringAction(searchString));
  const useSarchFlow = Boolean(searchString);
  const limit = 50;
  let actualCategoryId = clipArtCategoryId;
  if (!useSarchFlow) {
    const existingClipArtCategory = getClipArtCategory(getState(), clipArtCategoryId);
    if (!existingClipArtCategory) {
      const fullClipArtCategory = await fetchClipArts(clipArtCategoryId, searchString, limit, 0);
      if (fullClipArtCategory) {
        await dispatch(fetchClipArtsPriceOperation(fullClipArtCategory));
        await dispatch(addClipartCategoryAction(fullClipArtCategory));
        actualCategoryId = fullClipArtCategory.clipArtCategoryId;
      }
    }
    dispatch(sendClipArtCategoryPostMessageOperation(actualCategoryId));
    return;
  }

  fetchSearchResultsDebounced(dispatch, clipArtCategoryId, searchString, requestVersion, limit);
};

export default fetchClipArtCategoryOperation;
