import { AnyAction } from 'redux';
import { Store } from 'src/store/index';
import getCurrentProductAttributeValue from 'src/store/design/selector/getCurrentProductAttributeValue';
import { ThunkDispatch } from 'redux-thunk';
import dataLayerPush from 'src/util/analytics/dataLayer';
import getAbstractProduct from 'src/store/design/selector/getAbstractProduct';
import changeAndCorrectAttribute from 'src/util/formats/changeAndCorrectAttribute';
import sendProductUidChangedPostMessageOperation from 'src/store/design/operation/sendProductUidChangedPostMessageOperation';
import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';
import getProductId from 'src/store/design/selector/getProductId';
import { SelectorValue } from '../reducer/setFormatSelectorValueReducer';
import { setFormatSelectorValueAction } from '../slice';
import getFormatSelectorValues from '../selector/getFormatSelectorValues';

const setFormatSelectorValueOperation = (payload: SelectorValue) => async (
  dispatch: ThunkDispatch<Store, void, AnyAction>,
  getStore: () => Store,
) => {
  const store = getStore();
  const formatSelectorValue = getFormatSelectorValues(store)[payload.typeName];
  const abstractProduct = getAbstractProduct(store);
  const productAttrValue = getCurrentProductAttributeValue(store, payload.typeName)?.value || '';
  const currentAttrValue = formatSelectorValue || productAttrValue;
  if (currentAttrValue !== payload.value) {
    dataLayerPush('apiEditorFormatAttributeChange', {
      productName: abstractProduct?.name,
      attributeType: payload.typeName,
      previousValue: currentAttrValue,
      newValue: payload.value,
    });
  }
  dispatch(setFormatSelectorValueAction(payload));

  const integrationLayer = useIntegrationLayer();
  const productId = getProductId(store);
  if (!productId) {
    return;
  }

  const { attributesToChange, targetProduct } = changeAndCorrectAttribute(integrationLayer, productId, payload);
  // change attributes which were forced to set
  attributesToChange.forEach((attribute: { typeName: string; value: string}) => {
    dispatch(setFormatSelectorValueAction(attribute));
  });

  const data = {
    productId: targetProduct.productId,
    productUid: targetProduct.productUid,
    internalUid: targetProduct.internalUid,
    categoryId: integrationLayer.categoryId,
  };

  await dispatch(sendProductUidChangedPostMessageOperation({
    ...data,
    categoryId: window.integrationLayer.categoryId,
  }));
};

export default setFormatSelectorValueOperation;
