import { cloneDeep } from 'lodash';
import { AppBundle } from 'src/api/optimalprint-sdk.d';
import { BundledProduct } from 'src/store/productBundle/types';
import { IntegrationLayer } from 'src/types.d';

type ProductItem = AppBundle.Api.Entity.App.V1.AbstractProduct.ProductItem;

const getAllProducts = (integrationLayer: IntegrationLayer) => (
  (integrationLayer.abstractProduct && integrationLayer.abstractProduct.products as any[] as ProductItem[]) || []
);

const getCurrentProduct = (integrationLayer: IntegrationLayer, productId: number) => (
  getAllProducts(integrationLayer).find((product) => product.productId === productId) as ProductItem
);

const getAvailableBundledProducts = (bundleGroupId: number, productId: number, integrationLayer: IntegrationLayer) => {
  const currentProduct = getCurrentProduct(integrationLayer, productId);
  if (!currentProduct) {
    return undefined;
  }
  const bundleGroup = currentProduct.bundleGroups.find((bundleGroup) => bundleGroup.bundleGroupId === bundleGroupId);
  if (!bundleGroup) {
    return undefined;
  }
  return bundleGroup.items;
};

const getMatchingScore = (
  originalProduct: AppBundle.Api.Entity.App.V1.AbstractProduct.IProductItem,
  productToScore: AppBundle.Api.Entity.App.V1.AbstractProduct.IProductItem,
) => {
  let matchingScore = 0;
  originalProduct.attributes?.forEach((attribute) => {
    matchingScore += Number(productToScore.attributes
      ?.some((attributeOfProductToScore) => attributeOfProductToScore.typeName === attribute.typeName && attributeOfProductToScore.value === attribute.value));
  });
  return matchingScore;
};

const findOriginalBundledProduct = (bundleGroupId: number, productId: number, integrationLayer: IntegrationLayer) => {
  let originalBundledProduct;
  getAllProducts(integrationLayer)
    .find((product) => product.bundleGroups
      ?.find((bundledGroup) => bundledGroup.bundleGroupId === bundleGroupId)
      ?.items?.find((item) => {
        if (item.productId === productId) {
          originalBundledProduct = item;
          return true;
        }
        return false;
      }));
  return originalBundledProduct;
};

const updateBundledProducts = (selectedProducts: BundledProduct[], productId: number, partialMatch = false) => {
  const { integrationLayer } = window;
  if (!productId) {
    return selectedProducts;
  }

  let result = cloneDeep(selectedProducts) as BundledProduct[];
  result.forEach((bundledProduct) => {
    if (!bundledProduct.productId) {
      return;
    }
    const availableBundledProducts = getAvailableBundledProducts(bundledProduct.bundleGroupId, productId, integrationLayer);
    if (!availableBundledProducts) {
      bundledProduct.productId = 0;
      return;
    }
    let originalBundledProduct = availableBundledProducts.find((product) => product.productId === bundledProduct.productId);
    if (!partialMatch) {
      bundledProduct.productId = originalBundledProduct ? bundledProduct.productId : 0;
      return;
    }
    if (!originalBundledProduct) {
      originalBundledProduct = findOriginalBundledProduct(bundledProduct.bundleGroupId, bundledProduct.productId, integrationLayer);
    }

    if (!originalBundledProduct) {
      bundledProduct.productId = 0;
      return;
    }
    // find closest matching product to bundle
    let closestMatchBundleProduct = availableBundledProducts[0];
    let biggestMatchBundleScore = getMatchingScore(originalBundledProduct, closestMatchBundleProduct);

    availableBundledProducts.forEach((productToBundle) => {
      if (productToBundle === closestMatchBundleProduct || !originalBundledProduct) {
        return;
      }
      const matchingScore = getMatchingScore(originalBundledProduct, productToBundle);
      if (matchingScore > biggestMatchBundleScore) {
        biggestMatchBundleScore = matchingScore;
        closestMatchBundleProduct = productToBundle;
      }
    });
    bundledProduct.productId = closestMatchBundleProduct.productId;
  });

  // exclude bundled products which were not resolved
  result = result.filter((bundledProduct) => bundledProduct.productId > 0);
  return result;
};

export default updateBundledProducts;
