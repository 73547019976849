import { PayloadAction } from '@reduxjs/toolkit';
import { EditorState } from 'src/store/editor/types';
import update from 'immutability-helper';

export interface SelectorValue {
  typeName: string;
  value: string;
}

const setFormatSelectorValueReducer = (
  state: EditorState, action: PayloadAction<SelectorValue>,
) => {
  if (!action.payload) return state;
  return update<EditorState>(state, {
    formatSelectors: {
      [action.payload.typeName]: { $set: action.payload.value },
    },
  });
};

export default setFormatSelectorValueReducer;
