import React, { MutableRefObject, useEffect } from 'react';
import {
  FileUploader,
  FileUploaderConfig,
  FileUploaderCallback,
  FileUploaderMessage,
} from 'file-uploader';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Store } from 'src/store';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import { MESSAGE_TYPE } from 'src/store/app/types';
import addDesignErrorOperation from 'src/store/design/operation/addDesignErrorOperation';
import removeDesignErrorOperation from 'src/store/design/operation/removeDesignErrorOperation';
import { DesignError } from 'src/store/design/types';
import importImageFromTemporaryStorageToOpOperation
  from 'src/store/gallery/operation/importImageFromTemporaryStorageToOpOperation';
import { UploadedImageData } from 'src/store/gallery/types';
import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';
import setOpenedUploaderOperation from 'src/store/app/operation/setOpenedUploaderOperation';

interface Props {
  uploaderRef: MutableRefObject<FileUploader | undefined>;
  dispatch: ThunkDispatch<Store, void, AnyAction>;
}

const ApiFileUploader = ({ uploaderRef, dispatch }: Props) => {
  const { uploaderCompanionUrl } = useIntegrationLayer();

  useEffect(() => {
    const fileUploaderConfig: FileUploaderConfig = {
      core: {
        id: 'uppy-app',
        restrictions: {
          maxNumberOfFiles: 200,
          allowedFileTypes: ['image/*', '.heic'],
        },
        autoProceed: true,
        allowMultipleUploads: true,
      },
      dashboard: {
        disableStatusBar: true,
      },
      statusBar: false,
      companionUrl: uploaderCompanionUrl,
      s3: {
        limit: 5,
      },
    };

    const onBackdropClick = () => {
      uploaderRef?.current?.close();
    };

    const findBackdropElement = () => {
      const elements = document.getElementsByClassName('uppy-Dashboard-overlay');
      if (elements.length) {
        return elements.item(0);
      }
      return undefined;
    };

    const findCloseElement = () => {
      const elements = document.getElementsByClassName('uppy-Dashboard-close');
      if (elements.length) {
        return elements.item(0);
      }
      return undefined;
    };

    const attachBackdropClick = () => {
      const backdrop = findBackdropElement();
      if (backdrop) {
        backdrop.addEventListener('click', onBackdropClick);
      }
    };

    const attachCloseClick = () => {
      const closeElement = findCloseElement();
      if (closeElement) {
        closeElement.addEventListener('click', onBackdropClick);
      }
    };

    const detachBackdropClick = () => {
      const backdrop = findBackdropElement();
      if (backdrop) {
        backdrop.removeEventListener('click', onBackdropClick);
      }
    };

    const detachCloseClick = () => {
      const closeElement = findCloseElement();
      if (closeElement) {
        closeElement.removeEventListener('click', onBackdropClick);
      }
    };

    const fileUploaderCallback: FileUploaderCallback = (
      message: FileUploaderMessage,
      data,
    ) => {
      switch (message) {
        case FileUploaderMessage.previewGeneratedSuccess:
          dispatch(sendPostMessage({
            type: MESSAGE_TYPE['uploader.previewGeneratedSuccess'],
            data,
          }));
          break;
        case FileUploaderMessage.previewGeneratedRemotelySuccess:
          dispatch(sendPostMessage({
            type: MESSAGE_TYPE['uploader.previewGeneratedRemotelySuccess'],
            data,
          }));
          break;
        case FileUploaderMessage.previewGeneratedError:
          dispatch(sendPostMessage({
            type: MESSAGE_TYPE['uploader.previewGeneratedError'],
            data,
          }));
          break;
        case FileUploaderMessage.previewGeneratedRemotelyError:
          dispatch(sendPostMessage({
            type: MESSAGE_TYPE['uploader.previewGeneratedRemotelyErrorError'],
            data,
          }));
          break;
        case FileUploaderMessage.uploadSuccess:
          dispatch(importImageFromTemporaryStorageToOpOperation(data as UploadedImageData));
          break;
        case FileUploaderMessage.uploadError:
          dispatch(sendPostMessage({
            type: MESSAGE_TYPE['uploader.uploadError'],
            data,
          }));
          break;
        case FileUploaderMessage.uploadStart:
          dispatch(addDesignErrorOperation(DesignError.uploadInProgress));
          dispatch(sendPostMessage({
            type: MESSAGE_TYPE['uploader.uploadStart'],
            data,
          }));
          break;
        case FileUploaderMessage.uploadComplete:
          dispatch(removeDesignErrorOperation(DesignError.uploadInProgress));
          break;
        case FileUploaderMessage.openModal:
          attachBackdropClick();
          attachCloseClick();
          dispatch(setOpenedUploaderOperation(true));
          break;
        case FileUploaderMessage.closeModal:
          detachBackdropClick();
          detachCloseClick();
          dispatch(setOpenedUploaderOperation(false));
          break;
        case FileUploaderMessage.closed:
          detachBackdropClick();
          dispatch(setOpenedUploaderOperation(false));
          break;
        case FileUploaderMessage.uploadProgress:
          dispatch(sendPostMessage({
            type: MESSAGE_TYPE['uploader.uploadProgress'],
            data: {
              file: (data as any).file,
              bytesUploaded: (data as any).progress.bytesUploaded,
              bytesTotal: (data as any).progress.bytesTotal,
            },
          }));
          break;
        default:
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    uploaderRef.current = new FileUploader(
      fileUploaderConfig,
      fileUploaderCallback,
    );
  }, []);

  return (<></>);
};

export default connect()(ApiFileUploader);
