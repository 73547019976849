import { AnyAction } from 'redux';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import { MESSAGE_TYPE } from 'src/store/app/types';
import { ThunkDispatch } from 'redux-thunk';
import { Store } from 'src/store/index';
import i18n from 'src/i18n';

const sendExitFromEditorConfirmationOperation = () => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => {
  dispatch(sendPostMessage({
    type: MESSAGE_TYPE['editor.requestUserConfirmation'],
    data: {
      popupTitle: i18n.t('opeh_warning'),
      textLines: [i18n.t('opeh_want_to_quit'), i18n.t('opeh_warning_lost_all_changes')],
      options: [
        {
          title: i18n.t('opeh_no_stay_in_editor'),
        },
        {
          title: i18n.t('opeh_yes_quit_from_editor'),
          callbackMessage: {
            type: MESSAGE_TYPE['host.leaveEditor'],
            data: undefined,
          },
        },
      ],
    },
  }));
};

export default sendExitFromEditorConfirmationOperation;
