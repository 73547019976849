import ProductType from 'src/api/productTypes';
import { FormatProductType } from 'src/store/format/types';
import { Store } from 'src/store/index';
import getProductTypeId from 'src/store/design/selector/getProductTypeId';

const getPaperFormatProductType = (store: Store) => {
  const productTypeId = getProductTypeId(store);
  if (productTypeId === ProductType.PHOTOBOOK_LAY_FLAT) {
    return FormatProductType.LAYFLAT;
  }

  return undefined;
};

export default getPaperFormatProductType;
