import { AnyAction } from 'redux';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import { MESSAGE_TYPE } from 'src/store/app/types';
import { ThunkDispatch } from 'redux-thunk';
import { Store } from 'src/store/index';
import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';

const sendSetEditorTranslationsPostMessageOperation = () => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => {
  const { editorAppTranslations } = useIntegrationLayer();
  dispatch(sendPostMessage({
    type: MESSAGE_TYPE['editor.setTranslations'],
    data: editorAppTranslations,
  }));
};

export default sendSetEditorTranslationsPostMessageOperation;
