import { AnyAction } from 'redux';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import { MESSAGE_TYPE } from 'src/store/app/types';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import layoutSchemas from 'src/util/layoutSchemas/layoutSchemas';
import { setLayoutSchemaSetNameAction } from 'src/store/editor/slice';

const sendLayoutSchemasPostMessage = (layoutSchemaSet: string, addEmptyLayouts = false) => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => {
  dispatch(setLayoutSchemaSetNameAction(layoutSchemaSet));
  await dispatch(sendPostMessage({
    type: MESSAGE_TYPE['editor.setLayoutSchemas'],
    data: addEmptyLayouts ? [...layoutSchemas.empty, ...layoutSchemas[layoutSchemaSet]] : layoutSchemas[layoutSchemaSet],
  }));
};

export default sendLayoutSchemasPostMessage;
