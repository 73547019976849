import { AnyAction } from 'redux';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import getStructure from 'src/store/design/selector/getStructure';
import isFeatureEnabled from 'src/util/feature/isFeatureEnabled';
import calculateUsedClipArtsPriceOperation from 'src/store/addon/operation/calculateUsedClipArtsPriceOperation';
import { Feature } from 'src/store/app/types';
import calculateUpsellPriceOperation from 'src/store/upsell/operation/calculateUpsellPriceOperation';

const calculateInitialExtraPriceOperation = () => async (
  dispatch: ThunkDispatch<Store, void, AnyAction>,
  getStore: () => Store,
) => {
  const store = getStore();
  const designData = getStructure(store);
  if (!designData) return;

  if (isFeatureEnabled(Feature.supportClipArt)) {
    dispatch(calculateUsedClipArtsPriceOperation(designData));
  }
  if (isFeatureEnabled(Feature.extraChargePerPage)) {
    dispatch(calculateUpsellPriceOperation(designData));
  }
};

export default calculateInitialExtraPriceOperation;
