import { AnyAction } from 'redux';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import apiTypes from 'src/api/optimalprint-sdk.d';
import getPrice from 'src/store/price/selector/getPrice';
import fetchPricesOperation from 'src/store/price/operation/fetchPricesOperation';
import { EditorSelectorOptionWithValue, FormatsTabData } from 'src/util/formats/generateFormatTabData';
import getCategoryId from 'src/store/design/selector/getCategoryId';
import { cloneDeep } from 'lodash';
import formatPrice from 'src/util/price/formatPrice';
import getAbstractProduct from 'src/store/design/selector/getAbstractProduct';
import getProductId from 'src/store/design/selector/getProductId';
import getCurrencyId from '../selector/getCurrencyId';
import getPriceFormat from '../selector/getPriceFormat';

type ProductPrice = apiTypes.AppBundle.Api.Entity.Product.V1.IProductPrice;

const prefillPriceForFormatTabDataOperation = (formatTabData: FormatsTabData) => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  let store = getState();
  const categoryId = getCategoryId(store);
  const currencyId = getCurrencyId(store);
  const priceFormat = getPriceFormat(store);

  if (!categoryId || !currencyId || !priceFormat) return formatTabData;

  const resultFormatTabData = cloneDeep(formatTabData);

  const fillPrice = (productId: number, price: ProductPrice) => {
    resultFormatTabData.priceData[productId] = {
      price: price.p,
      priceInitial: price.pi,
      priceFormatted: formatPrice(price.p, priceFormat, currencyId),
      priceInitialFormatted: formatPrice(price.pi, priceFormat, currencyId),
    };
  };

  interface ProductAndCategory {
    productId: number;
    categoryId: number;
  }

  const fillPriceDataAndReturnProductIdsWithNoPrices = () => {
    const productIdsToFetchPrice: ProductAndCategory[] = [];
    formatTabData.selectors.forEach((selector) => {
      selector.options.forEach((option) => {
        if ((option as any).productId) {
          const priceForProduct = getPrice(store, (option as any).categoryId || categoryId, (option as any).productId, 1);
          if (!priceForProduct) {
            productIdsToFetchPrice.push({
              productId: (option as any).productId,
              categoryId: (option as any).categoryId || categoryId,
            });
          } else {
            fillPrice((option as any).productId, priceForProduct);
          }
        }
      });
    });
    return productIdsToFetchPrice;
  };

  const fetchPricesForAllProducts = async (productIdsToFetch: ProductAndCategory[]) => {
    const abstractProduct = getAbstractProduct(store);
    let productPricesToFecth = abstractProduct?.products.map((product) => ({
      productId: product.productId,
      categoryId,
    })) || [];
    productPricesToFecth.push(...productIdsToFetch);

    // check if we need to fetch the prices
    productPricesToFecth = productPricesToFecth.filter(({ categoryId, productId }) => !getPrice(store, categoryId, productId, 1));
    if (!productPricesToFecth.length) {
      return false;
    }
    // group by categories
    const groupedProductIds: { [categoryId: number]: number[] } = {};
    productPricesToFecth.forEach(({ categoryId, productId }) => {
      if (!groupedProductIds[categoryId]) {
        groupedProductIds[categoryId] = [];
      }
      if (groupedProductIds[categoryId].indexOf(productId) === -1) {
        groupedProductIds[categoryId].push(productId);
      }
    });

    const priceRequestPromises: Promise<any>[] = [];
    Object.keys(groupedProductIds).forEach((categoryId) => {
      priceRequestPromises.push(
        dispatch(fetchPricesOperation(Number(categoryId), groupedProductIds[Number(categoryId)])),
      );
    });
    await Promise.all(priceRequestPromises);
    return true;
  };

  const fillPriceWhereProductIdSet = () => {
    const currentProductId = getProductId(store);

    resultFormatTabData.selectors.forEach((selector) => {
      (selector.options as EditorSelectorOptionWithValue[]).forEach((option: any) => {
        if (!option.productId) {
          return;
        }
        const price = resultFormatTabData.priceData[option.productId];
        if (!price) return;
        if (!option.preActivated) {
          option.active = option.productId === currentProductId;
        }
        option.priceFormatted = price.priceFormatted;
        option.priceInitialFormatted = price.priceInitialFormatted;
      });
    });
  };

  const productIdsWithoutPrice = fillPriceDataAndReturnProductIdsWithNoPrices();
  if (await fetchPricesForAllProducts(productIdsWithoutPrice)) {
    store = getState();
    fillPriceDataAndReturnProductIdsWithNoPrices();
  }

  fillPriceWhereProductIdSet();
  return resultFormatTabData;
};

export default prefillPriceForFormatTabDataOperation;
