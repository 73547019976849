import getAllFormats from 'src/store/format/selector/getAllFormats';
import getCurrentFormat from 'src/store/format/selector/getCurrentFormat';
import { FormatData } from 'src/store/format/types';
import { Store } from 'src/store/index';

const getOneFormat = (store: Store, format?: FormatData) => {
  let f = format;
  if (!format) {
    f = getCurrentFormat(store);
  }
  const allFormats = getAllFormats(store);
  if (typeof f !== 'undefined' && f && allFormats) {
    const matchingFormats = allFormats.filter((elem) => (
      f && elem.paperFormatId === f.paperFormatId
      && elem.productTypeId === f.productTypeId
      && elem.orientationId === f.orientationId
    ));
    if (matchingFormats) {
      return matchingFormats.shift();
    }
  }

  return undefined;
};

export default getOneFormat;
