import { AnyAction } from 'redux';
import { opApiRequest } from 'src/api/apiRequest';
import endpoints from 'src/api/opEndpoints';
import apiTypes from 'src/api/optimalprint-sdk.d';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import isFeatureEnabled from 'src/util/feature/isFeatureEnabled';
import { Feature } from 'src/store/app/types';
import { SetPricesActionPayload } from 'src/store/price/reducer/setPricesReducer';
import { setUpsellPriceDataAction } from '../slice';
import calculateUpsellPriceOperation from './calculateUpsellPriceOperation';

const fetchUpsellDataOperation = (categoryId: number, productIds: number[]) => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const response = (await opApiRequest(
    endpoints.PRODUCT_V1_UPSELL_LIST,
    {
      categoryId,
      productIds,
    },
    'data',
    'POST',
  )) as apiTypes.AppBundle.Api.Response.Product.V1.ProductUpsellListV1Response;

  if (!response) {
    // eslint-disable-next-line no-console
    console.error('Cannot fetch upsell data');
    return;
  }

  const payload: SetPricesActionPayload = {
    categoryId,
    prices: response.products,
  };

  dispatch(setUpsellPriceDataAction(payload));
  if (isFeatureEnabled(Feature.extraChargePerPage)) {
    dispatch(calculateUpsellPriceOperation());
  }
};

export default fetchUpsellDataOperation;
