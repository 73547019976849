import { createSlice } from '@reduxjs/toolkit';
import setAllFormatsAreLoadedReducer from 'src/store/format/reducer/setAllFormatsAreLoadedReducer';
import setAllFormatsAreLoadingReducer from 'src/store/format/reducer/setAllFormatsAreLoadingReducer';
import setAllFormatsReducer from 'src/store/format/reducer/setAllFormatsReducer';
import setAvailableFormatsReducer from 'src/store/format/reducer/setAvailableFormatsReducer';
import setCurrentFormatReducer from 'src/store/format/reducer/setCurrentFormatReducer';
import setAvailableFormatsAreLoadedReducer from 'src/store/format/reducer/setAvailableFormatsAreLoadedReducer';
import setAvailableFormatsAreLoadingReducer from 'src/store/format/reducer/setAvailableFormatsAreLoadingReducer';
import { FormatState } from 'src/store/format/types';

export const formatInitialState: FormatState = {
  allFormats: [],
  allFormatsAreLoaded: false,
  allFormatsAreLoading: false,
  availableFormatsAreLoading: false,
  availableFormatsAreLoaded: false,
  availableFormats: [],
};

export const slice = createSlice({
  name: 'format',
  initialState: formatInitialState,
  reducers: {
    setAvailableFormatsAreLoadingAction: setAvailableFormatsAreLoadingReducer,
    setAvailableFormatsAreLoadedAction: setAvailableFormatsAreLoadedReducer,
    setCurrentFormatAction: setCurrentFormatReducer,
    setAvailableFormatsAction: setAvailableFormatsReducer,
    setAllFormatsAction: setAllFormatsReducer,
    setAllFormatsAreLoadingAction: setAllFormatsAreLoadingReducer,
    setAllFormatsAreLoadedAction: setAllFormatsAreLoadedReducer,
  },
});

export const {
  setAvailableFormatsAreLoadingAction,
  setAvailableFormatsAreLoadedAction,
  setCurrentFormatAction,
  setAvailableFormatsAction,
  setAllFormatsAction,
  setAllFormatsAreLoadingAction,
  setAllFormatsAreLoadedAction,
} = slice.actions;

const format = slice.reducer;

export default format;
