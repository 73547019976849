import { AnyAction } from 'redux';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import updateBundledProducts from 'src/util/bundles/updateBundledProducts';
import getProductId from 'src/store/design/selector/getProductId';
import setProductBundleSelectedProductsOperation from './setProductBundleSelectedProductsOperation';
import getProductBundleSelectedProducts from '../selector/getProductBundleSelectedProducts';

const updateBundledProductsOperation = () => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const store = getState();
  const selectedProducts = getProductBundleSelectedProducts(store);
  const productId = getProductId(store);
  if (!productId) {
    return;
  }
  const updatedBundledProducts = updateBundledProducts(selectedProducts, productId, true);
  await dispatch(setProductBundleSelectedProductsOperation(updatedBundledProducts));
};

export default updateBundledProductsOperation;
