import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Price from 'src/component/Price';
import { Store } from 'src/store';
import getCategoryId from 'src/store/design/selector/getCategoryId';
import getProductId from 'src/store/design/selector/getProductId';
import getProductTypeId from 'src/store/design/selector/getProductTypeId';
import getQuantity from 'src/store/design/selector/getQuantity';
import getCurrencyId from 'src/store/price/selector/getCurrencyId';
import getPrice from 'src/store/price/selector/getPrice';
import getPriceFormat from 'src/store/price/selector/getPriceFormat';
import productPriceToPrice from 'src/util/price/productPriceToPrice';
import styles from './index.module.scss';

const ProductDetails = () => {
  const { t } = useTranslation();

  const currencyId = useSelector(getCurrencyId);
  const productTypeId = useSelector(getProductTypeId);
  const priceFormat = useSelector(getPriceFormat);
  const categoryId = useSelector(getCategoryId) || 1;
  const quantity = useSelector(getQuantity) || 1;
  const productId = useSelector(getProductId) || 1;

  const framePrice = useSelector((store: Store) => getPrice(store, categoryId, productId, quantity));

  if (!framePrice) {
    return null;
  }

  // removed initial price to avoid displaying it
  const framePriceFinal = {
    ...framePrice,
    pi: framePrice?.p,
  };

  const translationKey = `txt_product_type_name_singular_${productTypeId}`;

  return (
    <div className={styles.productDetails}>
      {t(translationKey)}
      &nbsp;
      -
      &nbsp;
      {framePriceFinal
        && currencyId
        && priceFormat
        && <Price className={styles.price} price={productPriceToPrice(framePriceFinal as any)} currencyId={currencyId} priceFormat={priceFormat} />}
    </div>
  );
};

export default ProductDetails;
