import React from 'react';
import { connect } from 'react-redux';
import AddToBasketButton from 'src/component/TopBar/RightSection/Buttons/AddToBasketButton';
import DoneButton from 'src/component/TopBar/RightSection/Buttons/DoneButton';
import SaveEditedFromBasketButton from 'src/component/TopBar/RightSection/Buttons/SaveEditedFromBasketButton';
import SaveEditedFromDraftsButton from 'src/component/TopBar/RightSection/Buttons/SaveEditedFromDraftsButton';
import SaveToDraftsButton from 'src/component/TopBar/RightSection/Buttons/SaveToDraftsButton';
import { Store } from 'src/store';
import AddToBasketFromDraftButton from './Buttons/AddToBasketFromDraftButton';

import styles from './index.module.scss';

interface OwnProps {
  showLabels: boolean;
}

interface Props extends OwnProps {
}

const RightSection = ({ showLabels }: Props) => (
  <div className={styles.rightSection}>
    <SaveEditedFromBasketButton showLabel={showLabels} />
    <SaveEditedFromDraftsButton showLabel={showLabels} />
    <SaveToDraftsButton showLabel={showLabels} />
    <DoneButton showLabel={showLabels} />
    <AddToBasketButton showLabel={showLabels} />
    <AddToBasketFromDraftButton showLabel={showLabels} />
  </div>
);

const mapStateToProps = (store: Store) => ({
});

export default connect(mapStateToProps)(RightSection);
