import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import CenterSection from 'src/component/TopBar/CenterSection';
import LeftSection from 'src/component/TopBar/LeftSection';
import RightSection from 'src/component/TopBar/RightSection';
import { Store } from 'src/store';
import getIsMobile from 'src/store/app/selector/getIsMobile';
import styles from './index.module.scss';

interface Props {
  isMobile: boolean;
}

const TopBar = ({ isMobile }: Props) => {
  if (isMobile) {
    return (
      <div className={classNames([styles.topBar, styles.mobile])}>
        <div className={styles.buttons}>
          <LeftSection showLabels={false} />
          <RightSection showLabels={false} />
        </div>
        <div className={styles.descriptions}>
          <CenterSection isMobile={isMobile} />
        </div>
      </div>
    );
  }
  return (
    <div className={styles.topBar}>
      <LeftSection showLabels />
      <CenterSection isMobile={isMobile} />
      <RightSection showLabels />
    </div>
  );
};

const mapStateToProps = (store: Store) => ({
  isMobile: getIsMobile(store),
});

export default connect(mapStateToProps)(TopBar);
