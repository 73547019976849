import React, { useRef, useState } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import isMobileSelector from 'src/store/app/selector/getIsMobile';
import styles from './index.module.scss';
import CarouselLeftArrow from './CarouselLeftArrow';
import CarouselRightArrow from './CarouselRightArrow';

export const SCROLL_TOLERANCE = 50;

export interface OwnProps {
  children: React.ReactNode;
  className?: string;
}

const Carousel = ({ children, className }: OwnProps) => {
  const carouselEl = useRef<HTMLDivElement>(null);
  const isMobile = useSelector(isMobileSelector);
  const [scrollOffset, setScrollOffset] = useState(0);

  const onSectionScroll = (event: any) => {
    setScrollOffset(event.nativeEvent.target.scrollLeft);
  };

  return (
    <div className={styles.carousel} ref={carouselEl} onScroll={onSectionScroll}>
      {!isMobile && <CarouselLeftArrow scrollArea={carouselEl} scrollOffset={scrollOffset} />}
      <div className={cn(styles.carouselItems, { className: Boolean(className), [styles.mobile]: isMobile })}>
        {children}
      </div>
      {!isMobile && <CarouselRightArrow scrollArea={carouselEl} scrollOffset={scrollOffset} />}
    </div>
  );
};

export default Carousel;
