import getSelectedProductAttributes from 'src/store/design/selector/getSelectedProductAttributes';
import { Store } from 'src/store/index';

const isAttributeSelected = (store: Store, selectorTypeName: string, value: string) => {
  const selectedAttributes = getSelectedProductAttributes(store);
  const selected = selectedAttributes[selectorTypeName];

  if (!selected) {
    return false;
  }

  return selected.value === value;
};

export default isAttributeSelected;
