import { AnyAction } from 'redux';
import { opApiRequest } from 'src/api/apiRequest';
import endpoints from 'src/api/opEndpoints';
import apiTypes from 'src/api/optimalprint-sdk.d';
import sendSetDesignDataPostMessageOperation from 'src/store/app/operation/sendSetDesignDataPostMessageOperation';
import addCustomerDesignOperation from 'src/store/design/operation/addCustomerDesignOperation';
import { DesignData } from 'src/store/design/types';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';
import {
  setDesignIsLoadingAction,
  setDesignEncPublicIdAction,
  setDesignProductTypeIdAction,
  setDesignCategoryIdAction,
  setDesignQuantityAction,
  setDesignProductUidAction,
  setDesignIdAction,
  setDesignIsPublicAction,
  setDesignProductIdAction,
  setDesignStructureAction,
  setDesignTotalPagesCountAction,
} from 'src/store/design/slice';

const fetchPublicDesignOperation = () => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => {
  const {
    isPublicDesign, encPublicDesignId, categoryId, quantity,
  } = useIntegrationLayer();

  if (!isPublicDesign) {
    return;
  }

  await dispatch(setDesignIsLoadingAction(true));

  let response;

  try {
    response = (await opApiRequest(
      endpoints.PUBLIC_DESIGN_V3_GET,
      {
        encPublicDesignId,
      },
      'data',
      'GET',
    )) as apiTypes.AppBundle.Api.Response.Design.V3.DesignPublicGetV3Response;
    if (!response) {
      // eslint-disable-next-line no-console
      console.error('Cannot fetch public design');
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Cannot fetch public design, exception: ', e);
  }

  if (response) {
    const {
      designId, designStructure, productTypeId, productId, productUid,
    } = response;

    await dispatch(setDesignIsPublicAction(true));
    await dispatch(setDesignEncPublicIdAction(encPublicDesignId));
    await dispatch(setDesignIdAction(designId));
    await dispatch(setDesignStructureAction(designStructure as unknown as DesignData));
    await dispatch(setDesignProductTypeIdAction(productTypeId));
    await dispatch(setDesignProductIdAction(productId));
    await dispatch(setDesignProductUidAction(productUid));
    await dispatch(setDesignCategoryIdAction(categoryId));
    await dispatch(setDesignQuantityAction(quantity));
    const totalPagesCount = (designStructure as unknown as DesignData).page_count;
    await dispatch(setDesignTotalPagesCountAction(totalPagesCount));
    await dispatch(addCustomerDesignOperation(categoryId, productTypeId, (designStructure as unknown as DesignData), encPublicDesignId));
    await dispatch(sendSetDesignDataPostMessageOperation(designStructure));
  }
  await dispatch(setDesignIsLoadingAction(false));
};

export default fetchPublicDesignOperation;
