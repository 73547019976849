const gtmSendEvent = (event: any) => {
  if (!event) return;

  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  window.dataLayer.push(event);
};

export default gtmSendEvent;
