import { createSlice } from '@reduxjs/toolkit';
import { TemplateState } from 'src/store/template/types';
import setTemplateCanAddPagesReducer from './reducer/setTemplateCanAddPagesReducer';
import setTemplateForcedMinPageCountReducer from './reducer/setTemplateForcedMinPageCountReducer';

export const templateInitialState: TemplateState = {
  canAddPages: false,
  forcedMinPageCount: undefined,
};

export const slice = createSlice({
  name: 'template',
  initialState: templateInitialState,
  reducers: {
    setTemplateCanAddPagesAction: setTemplateCanAddPagesReducer,
    setTemplateForcedMinPageCountAction: setTemplateForcedMinPageCountReducer,
  },
});

export const {
  setTemplateCanAddPagesAction,
  setTemplateForcedMinPageCountAction,
} = slice.actions;

const template = slice.reducer;
export default template;
