import React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import apiTypes from 'src/api/optimalprint-sdk.d';
import IconDropDownArrow from 'src/component/Icon/IconDropDownArrow';
import PhotobooksDropdownMenu from 'src/component/TopBar/CenterSection/DesignFormat/PhotobooksDropdownMenu';
import { Store } from 'src/store';
import setOpenedDropdownMenuOperation from 'src/store/app/operation/setOpenedDropdownMenuOperation';
import isFeatureEnabled from 'src/store/app/selector/isFeatureEnabled';
import { DropDownMenuEnum, Feature } from 'src/store/app/types';
import getProductTypeId from 'src/store/design/selector/getProductTypeId';
import getCurrentFormat from 'src/store/format/selector/getCurrentFormat';
import getOneFormat from 'src/store/format/selector/getOneFormat';
import { FormatData } from 'src/store/format/types';
import styles from './index.module.scss';

interface Props {
  productTypeId?: number;
  isFormatSwitcherEnabled: boolean;
  currentFormat?: FormatData;
  extendedFormatInfo?: apiTypes.AppBundle.Api.Entity.PaperFormat.V1.IPaperFormat;
  dispatch: ThunkDispatch<Store, void, AnyAction>;
}

const DesignFormat = (
  {
    productTypeId,
    currentFormat,
    dispatch,
    extendedFormatInfo,
    isFormatSwitcherEnabled,
  }: Props,
) => {
  if (!productTypeId || !currentFormat || !isFormatSwitcherEnabled) {
    return null;
  }

  const onSelectorClick = () => {
    dispatch(setOpenedDropdownMenuOperation(DropDownMenuEnum.photobooksFormatSelector));
  };

  let name;
  if (extendedFormatInfo) {
    name = extendedFormatInfo.name;
  } else {
    name = currentFormat.name;
  }

  return (
    <div className={styles.format} onClick={onSelectorClick}>
      <div className={styles.name}>{name}</div>
      <IconDropDownArrow className={styles.icon} />
      <PhotobooksDropdownMenu />
    </div>
  );
};

const mapStateToProps = (store: Store) => ({
  productTypeId: getProductTypeId(store),
  currentFormat: getCurrentFormat(store),
  extendedFormatInfo: getOneFormat(store),
  isFormatSwitcherEnabled: isFeatureEnabled(store, Feature.formatSwitcher),
});

export default connect(mapStateToProps)(DesignFormat);
