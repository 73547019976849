import { AnyAction } from 'redux';
import { opApiRequest } from 'src/api/apiRequest';
import endpoints from 'src/api/opEndpoints';
import getOrderItemId from 'src/store/design/selector/getOrderItemId';
import getQuantity from 'src/store/design/selector/getQuantity';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import getCategoryId from 'src/store/design/selector/getCategoryId';
import getDesignId from 'src/store/design/selector/getDesignId';
import getProductBundleSelectedProducts from 'src/store/productBundle/selector/getProductBundleSelectedProducts';

const updateBundledProductsOperation = () => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const store = getState();

  if (getOrderItemId(store)) {
    try {
      await opApiRequest(
        endpoints.CART_V1_REMOVE_ALL_BUNDLED_ITEMS,
        {
          orderItemId: getOrderItemId(store),
        },
        'data',
        'POST',
      );

      const bundledProducts = getProductBundleSelectedProducts(store);
      for (let i = 0; i < bundledProducts.length; i += 1) {
        if (bundledProducts[i].productId) {
          // eslint-disable-next-line no-await-in-loop
          await opApiRequest(
            endpoints.CART_V1_PRODUCT_ADD,
            {
              quantity: getQuantity(store),
              customerDesignId: getDesignId(store),
              productId: bundledProducts[i].productId,
              categoryId: getCategoryId(store),
              includeAnalytics: true,
              bundleOwnerOrderItemId: getOrderItemId(store),
            },
            'data',
            'POST',
          );
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Cannot update bundled products', e);
      throw e;
    }
  }
};

export default updateBundledProductsOperation;
