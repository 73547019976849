import React from 'react';
import { useSelector } from 'react-redux';
import Price from 'src/component/Price';
import { Store } from 'src/store';
import getCategoryId from 'src/store/design/selector/getCategoryId';
import getQuantity from 'src/store/design/selector/getQuantity';
import getCurrencyId from 'src/store/price/selector/getCurrencyId';
import getPrice from 'src/store/price/selector/getPrice';
import getPriceFormat from 'src/store/price/selector/getPriceFormat';
import getFirstBundledProductId from 'src/store/productBundle/selector/getFirstBundledProductId';
import getFrameUpsellFrameTitle from 'src/store/upsell/selector/getFrameUpsellFrameTitle';
import productPriceToPrice from 'src/util/price/productPriceToPrice';
import styles from './index.module.scss';

const BundledProductDetails = () => {
  const currencyId = useSelector(getCurrencyId);
  const priceFormat = useSelector(getPriceFormat);
  const categoryId = useSelector(getCategoryId) || 1;
  const quantity = useSelector(getQuantity) || 1;
  const bundledProductId = useSelector(getFirstBundledProductId);
  const bundledProductTitle = useSelector(getFrameUpsellFrameTitle);

  const framePrice = useSelector((store: Store) => getPrice(store, categoryId, bundledProductId, quantity));
  const framePriceFinal = {
    ...framePrice,
    pi: framePrice?.p,
  };

  return (
    <div className={styles.bundledProduct}>
      {bundledProductTitle}
      &nbsp;
      -
      &nbsp;
      {framePriceFinal
        && currencyId
        && priceFormat
        && <Price className={styles.price} price={productPriceToPrice(framePriceFinal as any)} currencyId={currencyId} priceFormat={priceFormat} />}
    </div>
  );
};

export default BundledProductDetails;
