import { BundledProduct } from 'src/store/productBundle/types';

interface AppGlobalVars {
  bundledProducts: BundledProduct[];
  productId?: number;
}

const globalVars: AppGlobalVars = {
  bundledProducts: [],
  productId: undefined,
};

(window as any)._globalVars = globalVars;

export default globalVars;
