import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setDesignQuantityAction } from 'src/store/design/slice';
import gtmSendEvent from 'src/util/gtm/gtmSendEvent';
import styles from './index.module.scss';

const QuantitySelector = () => {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);

  const sendGtmEvent = () => {
    gtmSendEvent({
      event: 'api_upsell_quantity_changes',
      quantity,
    });
  };

  const incQuantity = () => {
    setQuantity(Math.min(quantity + 1, 99));
    sendGtmEvent();
  };

  const decQuantity = () => {
    setQuantity(Math.max(quantity - 1, 1));
    sendGtmEvent();
  };

  useEffect(() => {
    dispatch(setDesignQuantityAction(quantity));
  }, [quantity]);

  return (
    <div className={styles.quantitySelector}>
      <div className={styles.btn} onClick={decQuantity}>
        -
      </div>
      <div className={styles.quantity}>
        {quantity}
      </div>
      <div className={styles.btn} onClick={incQuantity}>
        +
      </div>
    </div>
  );
};

export default QuantitySelector;
