import getAbstractProduct from 'src/store/design/selector/getAbstractProduct';
import getSelectedProductAttributes from 'src/store/design/selector/getSelectedProductAttributes';
import { Selector } from 'src/store/design/types';
import { Store } from 'src/store/index';

const getSelectorSelectedOption = (store: Store, selector: Selector) => {
  const abstractProduct = getAbstractProduct(store);
  if (!abstractProduct) {
    return undefined;
  }

  const selectedAttributes = getSelectedProductAttributes(store);
  const attributeValue = selectedAttributes[selector.typeName];

  if (!attributeValue) {
    return undefined;
  }

  const selectedSelectorOption = selector.options.find((o) => o.value === attributeValue.value);
  if (!selectedSelectorOption) {
    return undefined;
  }

  return selectedSelectorOption;
};

export default getSelectorSelectedOption;
