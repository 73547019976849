import layoutSchemasWallArt from './layoutSchemasWallArt';
import layoutSchemasLayflat from './layoutSchemasLayflat';
import layoutSchemasMugSimplePhoto from './layoutSchemasMugSimplePhoto';
import layoutSchemasMugSimplePhotoRight from './layoutSchemasMugSimplePhotoRight';
import layoutSchemasMugSimplePhotoLeft from './layoutSchemasMugSimplePhotoLeft';
import layoutSchemasMugWrapAround from './layoutSchemasMugWrapAround';
import layoutSchemasEmpty from './layoutSchemasEmpty';
import { LayoutSchema } from './layoutTypes';

const layoutSchemas: {
  [layoutSchemaSet: string]: LayoutSchema[];
} = {
  empty: layoutSchemasEmpty,
  wallart: layoutSchemasWallArt,
  layflat: layoutSchemasLayflat,
  'mug-simple-photo': layoutSchemasMugSimplePhoto,
  'mug-wrap-around': layoutSchemasMugWrapAround,
  'mug-simple-photo-right': layoutSchemasMugSimplePhotoRight,
  'mug-simple-photo-left': layoutSchemasMugSimplePhotoLeft,
};

export default layoutSchemas;
