import {
  LayoutSchema,
} from './layoutTypes';
import defineLayoutSchema from './defineLayoutSchema';

const INTERNAL_PADDING = 8;
const EXTERNAL_PADDING = 12;

const layoutSchemasWallArt: LayoutSchema[] = [
  defineLayoutSchema('wa_i1_1', 1, 1, 10, 0, 0, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
    ],
  ], true),
  defineLayoutSchema('wa_i1_2', 1, 1, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
    ],
  ], false),
  defineLayoutSchema('wa_i2_1', 2, 1, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
  ], false),
  defineLayoutSchema('wa_i2_2', 1, 2, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
    ],
  ], false),
  defineLayoutSchema('wa_i3_1', 2, 2, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 2, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
  ], false),
  defineLayoutSchema('wa_i3_2', 2, 2, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 2 },
      { width: 1, height: 1 },
    ],
    [
      {},
      { width: 1, height: 1 },
    ],
  ], false),
  defineLayoutSchema('wa_i3_3', 2, 2, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      { width: 1, height: 2 },
    ],
    [
      { width: 1, height: 1 },
    ],
  ], false),
  defineLayoutSchema('wa_i3_4', 2, 2, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 2, height: 1 },
    ],
  ], false),
  defineLayoutSchema('wa_i4_1', 2, 2, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
  ], false),
  defineLayoutSchema('wa_i4_2', 2, 3, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 2, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 2, height: 1 },
    ],
  ], false),
  defineLayoutSchema('wa_i4_3', 3, 2, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 2 },
      { width: 1, height: 1 },
      { width: 1, height: 2 },
    ],
    [
      {},
      { width: 1, height: 1 },
    ],
  ], false),
  defineLayoutSchema('wa_i5_1', 2, 3, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 2, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
  ], false),
  defineLayoutSchema('wa_i5_2', 3, 2, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      { width: 1, height: 2 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      {},
      { width: 1, height: 1 },
    ],
  ], false),
  defineLayoutSchema('wa_i6_1', 3, 3, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      { width: 2, height: 1 },
    ],
    [
      { width: 2, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 2, height: 1 },
    ],
  ], false),
  defineLayoutSchema('wa_i6_2', 3, 3, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      { width: 1, height: 2 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 2 },
      {},
      { width: 1, height: 2 },
    ],
    [
      {},
      { width: 1, height: 1 },
    ],
  ], false),
  defineLayoutSchema('wa_i6_3', 4, 4, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      { width: 3, height: 2 },
    ],
    [
      { width: 1, height: 1 },
    ],
    [
      { width: 3, height: 2 },
      { width: 1, height: 1 },
    ],
    [
      {},
      {},
      {},
      { width: 1, height: 1 },
    ],
  ], false),
  defineLayoutSchema('wa_i6_4', 2, 3, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
  ], false),
  defineLayoutSchema('wa_i6_5', 3, 2, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
  ], false),
  defineLayoutSchema('wa_i6_6', 5, 4, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 2, height: 1 },
      { width: 3, height: 2 },
    ],
    [
      { width: 2, height: 1 },
    ],
    [
      { width: 3, height: 2 },
      { width: 2, height: 1 },
    ],
    [
      {},
      {},
      {},
      { width: 2, height: 1 },
    ],
  ], false),
  defineLayoutSchema('wa_i6_7', 4, 5, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 2, height: 3 },
      { width: 1, height: 2 },
      { width: 1, height: 2 },
    ],
    [],
    [
      {},
      {},
      { width: 2, height: 3 },
    ],
    [
      { width: 1, height: 2 },
      { width: 1, height: 2 },
    ],
  ], false),
  defineLayoutSchema('wa_i7_1', 3, 5, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 3, height: 3 },
    ],
    [],
    [],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
  ], false),
  defineLayoutSchema('wa_i7_2', 5, 3, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      { width: 3, height: 3 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      {},
      {},
      {},
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      {},
      {},
      {},
      { width: 1, height: 1 },
    ],
  ], false),
  defineLayoutSchema('wa_i8_1', 3, 4, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      { width: 2, height: 1 },
    ],
    [
      { width: 2, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 2, height: 1 },
    ],
    [
      { width: 2, height: 1 },
      { width: 1, height: 1 },
    ],
  ], false),
  defineLayoutSchema('wa_i8_2', 4, 3, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 2 },
      { width: 1, height: 1 },
      { width: 1, height: 2 },
      { width: 1, height: 1 },
    ],
    [
      {},
      { width: 1, height: 2 },
      {},
      { width: 1, height: 2 },
    ],
    [
      { width: 1, height: 1 },
      {},
      { width: 1, height: 1 },
    ],
  ], false),
  defineLayoutSchema('wa_i8_3', 4, 4, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 2 },
      { width: 1, height: 1 },
      { width: 2, height: 1 },
    ],
    [
      {},
      { width: 2, height: 2 },
      { width: 1, height: 3 },
    ],
    [
      { width: 1, height: 1 },
      {},
      {},
      {},
    ],
    [
      { width: 2, height: 1 },
      { width: 1, height: 1 },
    ],
  ], false),

  defineLayoutSchema('wa_i9_1', 3, 3, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
  ], false),
  defineLayoutSchema('wa_i9_2', 3, 6, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      { width: 2, height: 2 },
    ],
    [
      { width: 1, height: 1 },
    ],
    [
      { width: 2, height: 2 },
      { width: 1, height: 1 },
    ],
    [
      {},
      {},
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 2, height: 2 },
    ],
    [
      { width: 1, height: 1 },
    ],
  ], false),
  defineLayoutSchema('wa_i9_3', 6, 3, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 2, height: 2 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 2, height: 2 },
    ],
    [
      {},
      {},
      { width: 2, height: 2 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      {},
      {},
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
  ], false),
  defineLayoutSchema('wa_i12_1', 3, 4, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
  ], false),
  defineLayoutSchema('wa_i12_2', 4, 3, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
  ], false),
  defineLayoutSchema('wa_i13_1', 4, 4, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 2, height: 2 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      {},
      {},
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
  ], false),
  defineLayoutSchema('wa_i15_1', 4, 6, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 2, height: 2 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 2, height: 2 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      {},
      {},
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 2, height: 2 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
  ], false),
  defineLayoutSchema('wa_i15_2', 6, 4, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 2, height: 2 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 2, height: 2 },
    ],
    [
      {},
      {},
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 2, height: 2 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      {},
      {},
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
  ], false),
  defineLayoutSchema('wa_i16_1', 4, 4, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
  ], false),
  defineLayoutSchema('wa_i24_1', 4, 6, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
  ], false),
  defineLayoutSchema('wa_i24_2', 6, 4, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
  ], false),
];

export default layoutSchemasWallArt;
