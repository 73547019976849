import React from 'react';
import { connect } from 'react-redux';
import CancelButton from 'src/component/TopBar/LeftSection/Buttons/CancelButton';
import { Store } from 'src/store';
import styles from './index.module.scss';

interface OwnProps {
  showLabels: boolean;
}

interface Props extends OwnProps {
}

const LeftSection = ({ showLabels }: Props) => (
  <div className={styles.leftSection}>
    <CancelButton showLabel={showLabels} />
  </div>
);

const mapStateToProps = (store: Store) => ({
});

export default connect(mapStateToProps)(LeftSection);
