import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { opApiRequest } from 'src/api/apiRequest';
import endpoints from 'src/api/opEndpoints';
import apiTypes from 'src/api/optimalprint-sdk.d';
import getProductUid from 'src/store/design/selector/getProductUid';
import {
  setAvailableFormatsAction,
  setCurrentFormatAction,
  setAvailableFormatsAreLoadingAction,
  setAvailableFormatsAreLoadedAction,
} from 'src/store/format/slice';
import getPaperFormatProductType from 'src/store/format/selector/getPaperFormatProductType';
import areAvailableFormatsLoading from 'src/store/format/selector/areAvailableFormatsLoading';
import { FormatData } from 'src/store/format/types';
import { Store } from 'src/store/index';
import fetchPricesOperation from 'src/store/price/operation/fetchPricesOperation';

const fetchAvailableFormatsOperation = () => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const store = getState();
  if (areAvailableFormatsLoading(store)) {
    return;
  }
  await dispatch(setAvailableFormatsAreLoadingAction(true));
  const productType = getPaperFormatProductType(store);
  if (!productType) {
    return;
  }

  const currentProductUid = getProductUid(store);

  let response;
  try {
    response = (await opApiRequest(
      endpoints.PAPER_FORMAT_V1_PRODUCT_DETAILS_LIST,
      {
        productType,
      },
      'data',
    )) as apiTypes.AppBundle.Api.Response.PaperFormat.V1.PaperFormatProductDetailsListV1Response;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Cannot fetch format options for product type ${productType}, exception`, e);
  }

  if (response) {
    const { items } = response;
    const availableFormats = [];
    const productIds = [];
    let currentFormat;
    let categoryForPrice;
    for (let i = 0; i < items.length; i += 1) {
      const pf = items[i];
      const {
        productUid,
        productId,
        categoryId,
        encPublicDesignId,
        name,
        productTypeId,
        paperFormatId,
        orientationId,
      } = pf;
      if (productUid) {
        const f: FormatData = {
          productUid,
          productId,
          categoryId,
          productTypeId,
          paperFormatId,
          orientationId,
          encPublicDesignId: encPublicDesignId || '',
          name,
        };
        categoryForPrice = categoryId;
        productIds.push(productId);
        availableFormats.push(f);
        if (productUid === currentProductUid) {
          currentFormat = f;
        }
      }
    }

    await dispatch(setAvailableFormatsAreLoadingAction(false));
    await dispatch(setAvailableFormatsAreLoadedAction(true));
    await dispatch(setAvailableFormatsAction(availableFormats));
    await dispatch(setCurrentFormatAction(currentFormat));
    if (categoryForPrice) {
      dispatch(fetchPricesOperation(categoryForPrice, productIds));
    }
  } else {
    // eslint-disable-next-line no-console
    console.error('Cannot fetch available formats');
  }
};

export default fetchAvailableFormatsOperation;
