import { AnyAction } from 'redux';
import updateCustomerDesignOperation from 'src/store/design/operation/updateCustomerDesignOperation';
import isDesignSaving from 'src/store/design/selector/isDesignSaving';
import { setDesignIsSavingAction, setDesignIsLoadingAction } from 'src/store/design/slice';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';

const saveToDraftsOperation = () => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const store = getState();

  if (isDesignSaving(store)) {
    return;
  }

  try {
    await dispatch(setDesignIsSavingAction(true));
    await dispatch(setDesignIsLoadingAction(true));
    await dispatch(updateCustomerDesignOperation());
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Cannot save design to drafts', e);
  }
  await dispatch(setDesignIsLoadingAction(false));
  await dispatch(setDesignIsSavingAction(false));
};

export default saveToDraftsOperation;
