import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Store } from 'src/store';
import cn from 'classnames';
import setOpenedDropdownMenuOperation from 'src/store/app/operation/setOpenedDropdownMenuOperation';
import styles from './index.module.scss';

interface OwnProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  onClickOutside?: () => void;
  isVisible: boolean;
  className?: string;
}

interface Props extends OwnProps {
  dispatch: ThunkDispatch<Store, void, AnyAction>;
}

const DropdownMenu = (
  {
    children,
    onClickOutside,
    isVisible,
    className,
    dispatch,
  }: Props,
) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutsideThePreview = (e: MouseEvent) => {
    if (e.target && ref?.current && e.target instanceof Node && !ref.current.contains(e.target)) {
      if (onClickOutside) {
        onClickOutside();
      } else {
        dispatch(setOpenedDropdownMenuOperation(undefined));
      }
    }
  };

  useEffect(() => {
    if (isVisible) {
      window.addEventListener('click', handleClickOutsideThePreview, false);
    }

    return () => {
      window.removeEventListener('click', handleClickOutsideThePreview, false);
    };
  });

  if (!isVisible) {
    return null;
  }

  return (
    <div
      ref={ref}
      className={cn({
        [styles.dropdown]: true,
        [className || '']: true,
      })}
    >
      {children}
    </div>
  );
};

const mapStateToProps = (store: Store) => ({});

export default connect(mapStateToProps)(DropdownMenu);
