import { PayloadAction } from '@reduxjs/toolkit';
import { UpsellState } from 'src/store/upsell/types';

const setFrameUpsellSelectedFrameReducer = (
  state: UpsellState, action: PayloadAction<number | undefined>,
) => {
  state.frameUpsellSelectedFrame = action.payload;
};

export default setFrameUpsellSelectedFrameReducer;
