import { createSlice } from '@reduxjs/toolkit';
import setCurrencyIdReducer from 'src/store/price/reducer/setCurrencyIdReducer';
import setPriceFormatReducer from 'src/store/price/reducer/setPriceFormatReducer';
import setPricesReducer from 'src/store/price/reducer/setPricesReducer';
import { PriceState } from 'src/store/price/types';

export const priceInitialState: PriceState = {
  currencyId: undefined,
  priceFormat: undefined,
  prices: {},
};

export const slice = createSlice({
  name: 'price',
  initialState: priceInitialState,
  reducers: {
    setCurrencyIdAction: setCurrencyIdReducer,
    setPriceFormatAction: setPriceFormatReducer,
    setPricesAction: setPricesReducer,
  },
});

export const {
  setCurrencyIdAction,
  setPriceFormatAction,
  setPricesAction,
} = slice.actions;

const price = slice.reducer;
export default price;
