import {
  setDesignCategoryIdAction,
  setDesignEncPublicIdAction,
  setDesignIdAction, setDesignIsPublicAction,
  setDesignOrderItemIdAction,
  setDesignQuantityAction,
  setDesignOriginalQuantityAction,
  setDesignAbstractProductAction,
  setDesignProductIdAction,
  setDesignSelectedProductAttributesAction,
  setDesignInternalUidAction,
} from 'src/store/design/slice';
import { Attribute } from 'src/store/design/types';
import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';
import { AnyAction } from 'redux';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';

const setDesignInitialStateFromIntegrationLayerOperation = () => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => {
  const {
    orderItemId,
    designId,
    encPublicDesignId,
    categoryId,
    isPublicDesign,
    productId,
    abstractProduct,
    internalUid,
    quantity,
  } = useIntegrationLayer();
  await dispatch(setDesignOrderItemIdAction(orderItemId));
  await dispatch(setDesignIdAction(designId));
  await dispatch(setDesignEncPublicIdAction(encPublicDesignId));
  await dispatch(setDesignQuantityAction(quantity));
  await dispatch(setDesignOriginalQuantityAction(quantity));
  await dispatch(setDesignCategoryIdAction(categoryId));
  await dispatch(setDesignIsPublicAction(isPublicDesign));
  await dispatch(setDesignAbstractProductAction(abstractProduct));
  await dispatch(setDesignProductIdAction(productId));

  if (abstractProduct && internalUid) {
    const product = abstractProduct.products.find((p) => p.internalUid === internalUid);
    if (product) {
      const attr: { [typeName: string]: Attribute } = {};

      product.attributes.forEach((a) => {
        attr[a.typeName] = a;
      });

      await dispatch(setDesignSelectedProductAttributesAction(attr));
    }
    await dispatch(setDesignInternalUidAction(internalUid));
  }
};

export default setDesignInitialStateFromIntegrationLayerOperation;
