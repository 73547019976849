import { AnyAction } from 'redux';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import { Message, MESSAGE_TYPE } from 'src/store/app/types';
import { ThunkDispatch } from 'redux-thunk';
import { Store } from 'src/store/index';

const sendRequestDesignDataPostMessageOperation = (callback?: Message, checkWarnings?: boolean) => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => dispatch(sendPostMessage({
  type: MESSAGE_TYPE['design.requestDesignData'],
  data: checkWarnings
    ? {
      checkWarnings: [{
        type: 'IMAGE_CORRUPTED',
        level: ['HIGH'],
      }],
    }
    : undefined,
  callback,
}));

export default sendRequestDesignDataPostMessageOperation;
