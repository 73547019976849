import React from 'react';
import DesignFormat from 'src/component/TopBar/CenterSection/DesignFormat';
import DesignPrice from 'src/component/TopBar/CenterSection/DesignPrice';
import DesignTitle from 'src/component/TopBar/CenterSection/DesignTitle';
import Selectors from 'src/component/TopBar/CenterSection/Selectors';
import ProductAttributes from './ProductAttributes';
import styles from './index.module.scss';

interface OwnProps {
  isMobile: boolean;
}
interface Props extends OwnProps{
}

const CenterSection = ({ isMobile }: Props) => (
  <div className={styles.centerSection}>
    <div className={styles.row}>
      <DesignTitle />
      {!isMobile ? (<DesignPrice />) : null}
    </div>
    <div className={styles.row}>
      <DesignFormat />
      <Selectors />
      {isMobile && (
        <div className={styles.attributesAndPrice}>
          <ProductAttributes><DesignPrice className={styles.priceInline} /></ProductAttributes>
        </div>
      )}
      {!isMobile && <ProductAttributes />}
    </div>
  </div>
);

export default CenterSection;
