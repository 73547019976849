import { cloneDeep } from 'lodash';
import { DesignData } from 'src/store/design/types';

const adjustMugPrintArea = (design: DesignData, printAreaValue: string) => {
  const result = cloneDeep(design);
  let mediaBoxDefinition = result.spreads[0].pages[0].groups.mediabox?.find((mediaBox) => mediaBox.name === 'Media Box');

  if (!mediaBoxDefinition) {
    mediaBoxDefinition = result.spreads[0].pages[0].groups.mediabox?.[0];
  }

  if (!mediaBoxDefinition) {
    return result;
  }

  switch (printAreaValue) {
    case 'simple-photo-left':
      mediaBoxDefinition.width /= 2;
      break;
    case 'simple-photo':
      mediaBoxDefinition.width /= 2;
      mediaBoxDefinition.x += mediaBoxDefinition.width;
      break;
    default:
  }

  return result;
};

export default adjustMugPrintArea;
