import { PayloadAction } from '@reduxjs/toolkit';
import apiTypes from 'src/api/optimalprint-sdk.d';
import { AddonState } from 'src/store/addon/types';
import update from 'immutability-helper';

const addClipArtCategoryReducer = (
  state: AddonState, action: PayloadAction<apiTypes.AppBundle.Api.Response.ClipArt.V1.ClipArtListV1Response.IClipArtResultCategory>,
) => {
  if (!action.payload) return state;
  return update<AddonState>(state, {
    clipArtCategories: {
      [action.payload.clipArtCategoryId]: { $set: action.payload },
    },
  });
};

export default addClipArtCategoryReducer;
