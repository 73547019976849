import { createSlice } from '@reduxjs/toolkit';
import addDesignErrorReducer from 'src/store/design/reducer/addDesignErrorReducer';
import removeDesignErrorReducer from 'src/store/design/reducer/removeDesignErrorReducer';
import setDesignAbstractProductReducer from 'src/store/design/reducer/setDesignAbstractProductReducer';
import { DesignState } from 'src/store/design/types';
import setDesignOrderItemIdReducer from 'src/store/design/reducer/setDesignOrderItemIdReducer';
import setDesignCategoryIdReducer from 'src/store/design/reducer/setDesignCategoryIdReducer';
import setDesignEncPublicIdReducer from 'src/store/design/reducer/setDesignEncPublicIdReducer';
import setDesignIdReducer from 'src/store/design/reducer/setDesignIdReducer';
import setDesignIsLoadingReducer from 'src/store/design/reducer/setDesignIsLoadingReducer';
import setDesignIsPublicReducer from 'src/store/design/reducer/setDesignIsPublicReducer';
import setDesignIsSavingReducer from 'src/store/design/reducer/setDesignIsSavingReducer';
import setDesignProductIdReducer from 'src/store/design/reducer/setDesignProductIdReducer';
import setDesignProductTypeIdReducer from 'src/store/design/reducer/setDesignProductTypeIdReducer';
import setDesignProductUidReducer from 'src/store/design/reducer/setDesignProductUidReducer';
import setDesignQuantityReducer from 'src/store/design/reducer/setDesignQuantityReducer';
import setDesignStructureReducer from 'src/store/design/reducer/setDesignStructureReducer';
import setDesignTotalPagesCountReducer from 'src/store/design/reducer/setDesignTotalPagesCountReducer';
import setDesignHasStructureChangedReducer from 'src/store/design/reducer/setDesignHasStructureChangedReducer';
import setDesignIsLoadedReducer from 'src/store/design/reducer/setDesignIsLoadedReducer';
import setDesignOriginalQuantityReducer from './reducer/setDesignOriginalQuantityReducer';
import setDesignSelectedProductAttributesReducer from './reducer/setDesignSelectedProductAttributesReducer';
import setDesignInternalUidReducer from './reducer/setDesignInternalUIdReducer';

export const designInitialState: DesignState = {
  isLoading: false,
  isSaving: false,
  isPublic: true,
  encPublicId: undefined,
  designId: undefined,
  orderItemId: undefined,
  structure: undefined,
  productTypeId: undefined,
  productId: undefined,
  productUid: undefined,
  totalPagesCount: undefined,
  categoryId: undefined,
  quantity: undefined,
  hasStructureChanged: false,
  originalQuantity: undefined,
  selectedProductAttributes: {},
  isLoaded: false,
  errors: [],
};

export const slice = createSlice({
  name: 'design',
  initialState: designInitialState,
  reducers: {
    setDesignIsLoadingAction: setDesignIsLoadingReducer,
    setDesignIsSavingAction: setDesignIsSavingReducer,
    setDesignIsPublicAction: setDesignIsPublicReducer,
    setDesignEncPublicIdAction: setDesignEncPublicIdReducer,
    setDesignIdAction: setDesignIdReducer,
    setDesignStructureAction: setDesignStructureReducer,
    setDesignProductTypeIdAction: setDesignProductTypeIdReducer,
    setDesignProductIdAction: setDesignProductIdReducer,
    setDesignProductUidAction: setDesignProductUidReducer,
    setDesignTotalPagesCountAction: setDesignTotalPagesCountReducer,
    setDesignCategoryIdAction: setDesignCategoryIdReducer,
    setDesignQuantityAction: setDesignQuantityReducer,
    setDesignOriginalQuantityAction: setDesignOriginalQuantityReducer,
    setDesignOrderItemIdAction: setDesignOrderItemIdReducer,
    setDesignHasStructureChangedAction: setDesignHasStructureChangedReducer,
    setDesignIsLoadedAction: setDesignIsLoadedReducer,
    addDesignErrorAction: addDesignErrorReducer,
    removeDesignErrorAction: removeDesignErrorReducer,
    setDesignAbstractProductAction: setDesignAbstractProductReducer,
    setDesignSelectedProductAttributesAction: setDesignSelectedProductAttributesReducer,
    setDesignInternalUidAction: setDesignInternalUidReducer,
  },
});

export const {
  setDesignIsLoadingAction,
  setDesignIsSavingAction,
  setDesignIsPublicAction,
  setDesignEncPublicIdAction,
  setDesignIdAction,
  setDesignStructureAction,
  setDesignProductTypeIdAction,
  setDesignProductIdAction,
  setDesignProductUidAction,
  setDesignTotalPagesCountAction,
  setDesignCategoryIdAction,
  setDesignQuantityAction,
  setDesignOrderItemIdAction,
  setDesignHasStructureChangedAction,
  setDesignIsLoadedAction,
  setDesignOriginalQuantityAction,
  addDesignErrorAction,
  removeDesignErrorAction,
  setDesignAbstractProductAction,
  setDesignSelectedProductAttributesAction,
  setDesignInternalUidAction,
} = slice.actions;

const design = slice.reducer;

export default design;
