import { PayloadAction } from '@reduxjs/toolkit';
import { AddonState } from 'src/store/addon/types';
import update from 'immutability-helper';

const addUsedClipArtsHashReducer = (
  state: AddonState, action: PayloadAction<string>,
) => {
  if (!action.payload) return state;
  return update<AddonState>(state, {
    usedClipArts: {
      clipArtsHash: {
        $set: action.payload,
      },
    },
  });
};

export default addUsedClipArtsHashReducer;
