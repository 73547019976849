import { AnyAction } from 'redux';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import { Feature, MESSAGE_TYPE } from 'src/store/app/types';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import sendEnableDesignTrackChangesPostMessageOperation from 'src/store/editor/operation/sendEnableDesignTrackChangesPostMessageOperation';
import isFeatureEnabled from '../selector/isFeatureEnabled';

const sendEditorSettingsPostMessageOperation = () => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const store = getState();
  const settings = {
    enableMirroring: isFeatureEnabled(store, Feature.enableMirroring),
    hideAddonsSearch: !isFeatureEnabled(store, Feature.showClipArtSearch),
  };

  await dispatch(sendPostMessage({
    type: MESSAGE_TYPE['editor.setEditorHostSettings'],
    data: settings,
  }));

  // Enable design structure tracking to catch changes of mirroring property
  // in design structure, due to hard coded mirroring selector on the API editor side
  if (isFeatureEnabled(store, Feature.enableMirroring)) {
    await dispatch(sendEnableDesignTrackChangesPostMessageOperation(true));
  }
};

export default sendEditorSettingsPostMessageOperation;
