import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Button from 'src/component/Button';
import IconBasket from 'src/component/Icon/IconBasket';
import IconTick from 'src/component/Icon/IconTick';
import { Store } from 'src/store';
import getBackUrl from 'src/store/app/selector/getBackUrl';
import getEditorMode from 'src/store/app/selector/getEditorMode';
import { EDITOR_MODE } from 'src/store/app/types';
import hasDesignStructureChanged from 'src/store/design/selector/hasDesignStructureChanged';
import isDesignLoaded from 'src/store/design/selector/isDesignLoaded';
import isDesignLoading from 'src/store/design/selector/isDesignLoading';
import isDesignSaving from 'src/store/design/selector/isDesignSaving';
import isPublicDesign from 'src/store/design/selector/isPublicDesign';

interface OwnProps {
  showLabel: boolean;
}

interface Props extends OwnProps {
  isCustomerDesign: boolean;
  isLoading: boolean;
  isSaving: boolean;
  backUrl?: string;
  isLoaded: boolean;
  hasDesignChanged: boolean;
  editorMode: EDITOR_MODE;
}

const DoneButton = (
  {
    isCustomerDesign,
    isLoading,
    isLoaded,
    isSaving,
    hasDesignChanged,
    backUrl,
    showLabel,
    editorMode,
  }: Props,
) => {
  const { t } = useTranslation();
  if (
    !isCustomerDesign
    || !isLoaded
    || hasDesignChanged
    || isLoading
    || editorMode === EDITOR_MODE['create-new']
  ) {
    return null;
  }

  const onClick = async () => {
    if (backUrl) {
      window.location.href = backUrl;
    } else {
      window.history.go(-1);
    }
  };
  const buttonIcon = editorMode === EDITOR_MODE['edit-from-basket'] ? <IconBasket /> : <IconTick />;
  return (
    <Button
      onClick={onClick}
      withPadding
      primary
      disabled={isSaving || isLoading}
      icon={buttonIcon}
      text={showLabel ? t('opeh_top-bar_done') : null}
    />
  );
};

const mapStateToProps = (store: Store) => ({
  isSaving: isDesignSaving(store),
  isLoading: isDesignLoading(store),
  isLoaded: isDesignLoaded(store),
  isCustomerDesign: !isPublicDesign(store),
  hasDesignChanged: hasDesignStructureChanged(store),
  editorMode: getEditorMode(store),
  backUrl: getBackUrl(store),
});

export default connect(mapStateToProps)(DoneButton);
