import Axios, { AxiosRequestConfig, Method } from 'axios';
import get from 'lodash/get';
import endpoints from 'src/api/opEndpoints';
import apiTypes from 'src/api/optimalprint-sdk.d';
import ApiConnectionType from 'src/api/types';

const UNAUTHORIZED = 401;

const clientInfo = {
  platform: 5,
};

async function sendAxiosRequest(
  config: AxiosRequestConfig,
): Promise<any> {
  let result;
  delete config.adapter;
  config.withCredentials = false;

  try {
    const response = await Axios(config);

    result = { ...response };
    delete result.config;
    delete result.request;
  } catch (err) {
    return err.response;
  }

  return result;
}

export const registerGuest = async (callback?: any) => {
  const authData = await opApiRequest(
    endpoints.AUTH_V1_REGISTER_GUEST,
    {
      keepLogin: true,
    },
    'data',
    'POST',
    false,
    3,
  ) as apiTypes.AppBundle.Api.Response.Auth.V2.AuthLoginV2Response;

  document.cookie = `RTC=${authData.refreshToken.token};path=/;expires=${new Date(authData.refreshToken.expiresAt).toUTCString()}`;

  if (callback) {
    return callback();
  }

  return undefined;
};

const getHeaders = () => ({
  'Content-Type': 'application/json',
});

const prepareFullUrl = (apiBaseUrl: string, url: string) => {
  if (url.indexOf('http') === 0) {
    return url;
  }
  return apiBaseUrl + url;
};

export const opApiRequest = async (
  url: string,
  data: string | any,
  resultField: string,
  method: Method = 'GET',
  withCookies = true,
  maxRetries = 3,
) => apiRequest(ApiConnectionType.op, url, data, resultField, method, withCookies, maxRetries);

const getBaseUrl = (connectionType: ApiConnectionType) => {
  if (connectionType === ApiConnectionType.op) {
    return window.integrationLayer.opApiBaseUrl;
  }

  if (connectionType === ApiConnectionType.preFlight) {
    return window.integrationLayer.preFlightApiBaseUrl;
  }

  return '';
};

const apiRequest = async (
  connectionType: ApiConnectionType,
  url: string,
  data: string | any,
  resultField: string,
  method: Method = 'GET',
  withCookies = true,
  maxRetries = 3,
) => new Promise(
  // eslint-disable-next-line no-async-promise-executor
  async (resolve, reject): Promise<any | any[]> => {
    const baseUrl = getBaseUrl(connectionType);
    let retry = 0;

    const newUrl = prepareFullUrl(baseUrl, url);

    const options: AxiosRequestConfig = {
      method,
      headers: getHeaders(),
    };

    if (method === 'POST') {
      options.data = JSON.stringify({ ...data, clientInfo });
    } else {
      options.params = data;
    }

    const isUnauthorized = (r?: { status: number }) => r && r.status === UNAUTHORIZED;
    const request = async () => {
      retry += 1;

      try {
        const result: any = await sendAxiosRequest(
          {
            url: newUrl,
            ...options,
          },
        );

        const isOpRequest = connectionType === ApiConnectionType.op;

        // login as a guest
        if (isOpRequest && isUnauthorized(result)) {
          registerGuest(request);
          return;
        }
        if (!result) {
          // eslint-disable-next-line no-throw-literal
          throw 'no response';
        }

        if (isOpRequest) {
          if (result.data && result.data.error && (result.data.error.code || result.data.error.message)) {
            let { error } = result.data;

            if (error) {
              error = {
                ...result.error,
                details: JSON.stringify(error.details),
                trace: JSON.stringify(error.trace),
              };
            } else {
              error = result.errorMessage;
            }

            throw (error);
          } else {
            resolve(get(result.data, resultField));
          }
        } else {
          resolve(get(result, resultField));
        }
      } catch (e) {
        if (retry < maxRetries) {
          setTimeout(request, retry * 1000);
        } else {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(`API ERROR: ${JSON.stringify(e)}`);
        }
      }
    };

    // noinspection JSIgnoredPromiseFromCall
    request();
  },
);
