import { AnyAction } from 'redux';
import { opApiRequest } from 'src/api/apiRequest';
import endpoints from 'src/api/opEndpoints';
import apiTypes from 'src/api/optimalprint-sdk.d';
import sendSetDesignDataPostMessageOperation from 'src/store/app/operation/sendSetDesignDataPostMessageOperation';
import addCustomerDesignOperation from 'src/store/design/operation/addCustomerDesignOperation';
import { DesignData } from 'src/store/design/types';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';
import {
  setDesignIsLoadingAction,
  setDesignProductTypeIdAction,
  setDesignCategoryIdAction,
  setDesignQuantityAction,
  setDesignProductUidAction,
  setDesignIsPublicAction,
  setDesignProductIdAction,
  setDesignStructureAction,
  setDesignTotalPagesCountAction,
} from 'src/store/design/slice';
import countNumberOfPages from 'src/util/design/countNumberOfPages';
import fixPhpJSONConvertedObject from 'src/util/design/fixPhpJSONConvertedObject';
import adjustEmptyDesignStructure from 'src/util/design/adjustEmptyDesignStructure';

const fetchPublicDesignByProductUidOperation = () => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const {
    isPublicDesign, categoryId, quantity, productUid, productId, productTypeId,
  } = useIntegrationLayer();

  if (!isPublicDesign) {
    return;
  }

  dispatch(setDesignIsLoadingAction(true));

  let response;

  try {
    response = (await opApiRequest(
      endpoints.PUBLIC_DESIGN_V3_GET_BY_PRODUCT_UID,
      {
        productUid,
      },
      'data',
      'GET',
    )) as apiTypes.AppBundle.Api.Response.Design.V3.DesignPublicGetByProductUidV3Response;
    if (!response) {
      // eslint-disable-next-line no-console
      console.error('Cannot fetch public design');
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Cannot fetch public design, exception: ', e);
  }

  if (response) {
    const {
      designStructure,
    } = response;

    let fixedDesignStructure = fixPhpJSONConvertedObject(designStructure);
    fixedDesignStructure = adjustEmptyDesignStructure(useIntegrationLayer(), getState(), fixedDesignStructure);

    dispatch(setDesignIsPublicAction(true));
    dispatch(setDesignStructureAction(fixedDesignStructure as unknown as DesignData));
    dispatch(setDesignProductTypeIdAction(productTypeId));
    dispatch(setDesignProductIdAction(productId));
    dispatch(setDesignProductUidAction(productUid));
    dispatch(setDesignCategoryIdAction(categoryId));
    dispatch(setDesignQuantityAction(quantity));
    const totalPagesCount = (fixedDesignStructure as unknown as DesignData).page_count || countNumberOfPages(fixedDesignStructure as unknown as DesignData);
    dispatch(setDesignTotalPagesCountAction(totalPagesCount));
    await dispatch(addCustomerDesignOperation(categoryId, productTypeId, (fixedDesignStructure as unknown as DesignData), undefined, productId));
    await dispatch(sendSetDesignDataPostMessageOperation(fixedDesignStructure));
  }
  dispatch(setDesignIsLoadingAction(false));
};

export default fetchPublicDesignByProductUidOperation;
