import { AnyAction } from 'redux';
import sendRequestDesignDataPostMessageOperation
  from 'src/store/app/operation/sendRequestDesignDataPostMessageOperation';
import { MESSAGE_TYPE } from 'src/store/app/types';
import { ThunkDispatch } from 'redux-thunk';
import { Store } from 'src/store/index';

const sendRequestDesignDataPostMessageToSaveToDrafts = () => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => {
  const message = {
    type: MESSAGE_TYPE['host.saveToDrafts'],
  };
  dispatch(sendRequestDesignDataPostMessageOperation(message, true));
};

export default sendRequestDesignDataPostMessageToSaveToDrafts;
