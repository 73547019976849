import { PayloadAction } from '@reduxjs/toolkit';
import { OpPlusProductsPersonalizedV1Response, UpsellState } from 'src/store/upsell/types';

const setPersonalizedOpplusReducer = (
  state: UpsellState, action: PayloadAction<OpPlusProductsPersonalizedV1Response>,
) => {
  state.personalizedOpplusDetails = action.payload;
};

export default setPersonalizedOpplusReducer;
