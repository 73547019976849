import { UpsellState } from 'src/store/upsell/types';
import update from 'immutability-helper';

const clearUpsellPriceReducer = (
  state: UpsellState,
) => update<UpsellState>(state, {
  upsellPrice: {
    $set: {
    },
  },
});

export default clearUpsellPriceReducer;
