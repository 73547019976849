import { AnyAction } from 'redux';
import apiTypes from 'src/api/optimalprint-sdk.d';
import { ThunkDispatch } from 'redux-thunk';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import { MESSAGE_TYPE } from 'src/store/app/types';
import { Store } from 'src/store/index';

const sendSetSpreadBackgroundsPostMessageOperation = (
  scenes: apiTypes.AppBundle.Api.Response.Design.V3.DesignPublicEditorScenesListV3Response.Scene[],
) => (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => dispatch(sendPostMessage({
  type: MESSAGE_TYPE['design.spreadBackgrounds'],
  data: scenes,
}));
export default sendSetSpreadBackgroundsPostMessageOperation;
