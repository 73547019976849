import { AnyAction } from 'redux';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import { BundledProduct } from 'src/store/productBundle/types';
import { setDesignHasStructureChangedAction } from 'src/store/design/slice';
import { changeBundledProductAction } from '../slice';
import updateBundledProductsPriceOperation from './updateBundledProductsPriceOperation';

const changeBundledProductOperation = (selectedProduct: BundledProduct) => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => {
  dispatch(changeBundledProductAction(selectedProduct));
  dispatch(setDesignHasStructureChangedAction(true));
  await dispatch(updateBundledProductsPriceOperation());
};

export default changeBundledProductOperation;
