import React from 'react';
import { useSelector } from 'react-redux';
import getFrameUpsellData from 'src/store/upsell/selector/getFrameUpsellData';
import styles from './index.module.scss';

const VoucherText = () => {
  const voucher = useSelector(getFrameUpsellData)?.voucherText || '';
  if (!voucher) {
    return null;
  }

  return (
    <div className={styles.voucherText}>
      {voucher}
    </div>
  );
};

export default VoucherText;
