import { createSlice } from '@reduxjs/toolkit';
import setEditorScenesReducer from 'src/store/editor/reducer/setEditorScenesReducer';
import resetFormatSelectorValuesReducer from 'src/store/editor/reducer/resetFormatSelectorValuesReducer';
import setFormatSelectorValueReducer from 'src/store/editor/reducer/setFormatSelectorValueReducer';
import setLayoutSchemaSetNameReducer from 'src/store/editor/reducer/setLayoutSchemaSetNameReducer';
import { EditorState } from 'src/store/editor/types';

export const editorInitialState: EditorState = {
  editorScenes: {},
  layoutSchemaSetName: '',
  formatSelectors: {},
};

export const slice = createSlice({
  name: 'editor',
  initialState: editorInitialState,
  reducers: {
    setEditorScenesAction: setEditorScenesReducer,
    setFormatSelectorValueAction: setFormatSelectorValueReducer,
    resetFormatSelectorValuesAction: resetFormatSelectorValuesReducer,
    setLayoutSchemaSetNameAction: setLayoutSchemaSetNameReducer,
  },
});

export const {
  setEditorScenesAction,
  setFormatSelectorValueAction,
  resetFormatSelectorValuesAction,
  setLayoutSchemaSetNameAction,
} = slice.actions;

const editor = slice.reducer;

export default editor;
