import getCategoryId from 'src/store/design/selector/getCategoryId';
import getProductId from 'src/store/design/selector/getProductId';
import getProductTypeId from 'src/store/design/selector/getProductTypeId';
import getQuantity from 'src/store/design/selector/getQuantity';
import { Store } from 'src/store/index';
import getPrice from 'src/store/price/selector/getPrice';

const getCurrentDesignPrice = (store: Store) => {
  const quantity = getQuantity(store);
  const categoryId = getCategoryId(store);
  const productId = getProductId(store);
  const productTypeId = getProductTypeId(store);

  if (!quantity || !categoryId || !productId || !productTypeId) {
    return undefined;
  }

  return getPrice(store, categoryId, productId, quantity);
};
export default getCurrentDesignPrice;
