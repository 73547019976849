import React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import IconDropDownArrow from 'src/component/Icon/IconDropDownArrow';
import DropdownItems from 'src/component/TopBar/CenterSection/Selectors/Dropdown/DropdownItems';
import { Store } from 'src/store';
import setOpenedDropdownMenuOperation from 'src/store/app/operation/setOpenedDropdownMenuOperation';
import { Selector, SelectorOption } from 'src/store/design/types';
import getSelectorSelectedOption from 'src/store/design/selector/getSelectorSelectedOption';
import styles from './index.module.scss';

interface OwnProps {
  selector: Selector;
}

interface Props extends OwnProps {
  selectedOption?: SelectorOption;
  dispatch: ThunkDispatch<Store, void, AnyAction>;
}

const Dropdown = (
  {
    selector,
    selectedOption,
    dispatch,
  }: Props,
) => {
  if (!selectedOption) {
    return null;
  }

  const onSelectorClick = () => {
    dispatch(setOpenedDropdownMenuOperation(selector.typeName));
  };

  return (
    <div className={styles.format} onClick={onSelectorClick}>
      {`${selector.name}: ${selectedOption.name}`}
      <IconDropDownArrow className={styles.icon} />
      <DropdownItems selector={selector} />
    </div>
  );
};

const mapStateToProps = (store: Store, props: OwnProps) => ({
  selectedOption: getSelectorSelectedOption(store, props.selector),
});

export default connect(mapStateToProps)(Dropdown);
