import { cloneDeep } from 'lodash';
import { AppBundle } from 'src/api/optimalprint-sdk.d';
import { MESSAGE_TYPE } from 'src/store/app/types';
import { IntegrationLayer } from 'src/types.d';
import {
  getAttributeValue, getCurrentProduct, getEditorSelectors,
  getFixedAttributes, getMatchingProducts, getOptionsForAttribute,
} from './generateFormatTabData';

type ProductAttribute = AppBundle.Api.Entity.App.V1.AbstractProduct.ProductItem.IAttribute;
type ProductItem = AppBundle.Api.Entity.App.V1.AbstractProduct.ProductItem;
type ProductPageSelector = AppBundle.Api.Entity.App.V1.AbstractProduct.ProductPageSelector;
type ProductPageSelectorOption = AppBundle.Api.Entity.App.V1.AbstractProduct.ProductPageSelector.ProductPageSelectorOption;
export type EditorSelectorOptionWithValue = ProductPageSelectorOption & {
  productId?: number;
  active?: boolean;
  priceInitialFormatted?: string;
  priceFormatted?: string;
  callback?: {
    type: MESSAGE_TYPE;
    data: any;
  };
}

export type FormatsTabData = {
  selectors: ProductPageSelector[];
  productIdsForPriceRquest: number[];
  priceData: {
    [productId: string]: {
      priceInitial: number;
      price: number;
      priceInitialFormatted: string;
      priceFormatted: string;
    };
  };
}

const changeAttributeAndCorrectNextSelectors = (
  integrationLayer: IntegrationLayer, product: ProductItem, fixedAttributes: ProductAttribute[],
  updatedAttribute: ProductAttribute,
) => {
  const attributesToChange: ProductAttribute[] = [];

  const resultSelectors = cloneDeep(getEditorSelectors(integrationLayer).filter((edSel) => !edSel.isHidden));

  const filterAttributes = [...fixedAttributes];
  let correctionMode = false;
  resultSelectors.forEach((attribute, index: number) => {
    // go through products to find matching fixed attributes
    const matchingProducts = getMatchingProducts(integrationLayer, filterAttributes);

    // for matching products collect available values for attribute
    const availableValues = getOptionsForAttribute(attribute.typeName, matchingProducts, filterAttributes);
    if (!correctionMode) {
      if (attribute.typeName === updatedAttribute.typeName) {
        filterAttributes.push(updatedAttribute);
        // start correction mode after changed attribute found
        correctionMode = true;
      } else {
        filterAttributes.push({
          ...attribute,
          value: getAttributeValue(product, attribute.typeName) || '',
        });
      }
      return;
    }
    // correct attributes run
    // get current attribute value
    const currentAtrributeValue = getAttributeValue(product, attribute.typeName) || '';
    // if current product attribute value is not avaiable, register it and change attribute to first available
    if (availableValues.indexOf(currentAtrributeValue) === -1) {
      const forcedAttribute = {
        ...attribute,
        value: availableValues[0],
      };
      attributesToChange.push(forcedAttribute);
      filterAttributes.push(forcedAttribute);
    } else {
      filterAttributes.push({
        ...attribute,
        value: currentAtrributeValue,
      });
    }
  });

  const targetProduct = getMatchingProducts(integrationLayer, filterAttributes)[0];

  return {
    attributesToChange,
    targetProduct,
  };
};

const changeAndCorrectAttribute = (
  integrationLayer: IntegrationLayer,
  productId: number,
  changedAttribute: ProductAttribute,
) => {
  const currentProduct = getCurrentProduct(integrationLayer, productId);
  const fixedAttributes = getFixedAttributes(integrationLayer, productId);
  return changeAttributeAndCorrectNextSelectors(integrationLayer, currentProduct, fixedAttributes, changedAttribute);
};

export default changeAndCorrectAttribute;
