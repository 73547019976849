import { AnyAction } from 'redux';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import { setOpenedUploaderAction } from '../slice';
import getOpenedUploader from '../selector/getOpenedUploader';

const setOpenedUploaderOperation = (value: boolean) => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  if (getOpenedUploader(getState()) === value) {
    return;
  }

  if (value) {
    window.location.hash = 'uploader';
  } else if (window.location.hash === '#uploader') {
    window.history.back();
  }

  await dispatch(setOpenedUploaderAction(value));
};

export default setOpenedUploaderOperation;
