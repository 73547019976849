import { AnyAction } from 'redux';
import apiTypes from 'src/api/optimalprint-sdk.d';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import getCategoryId from 'src/store/design/selector/getCategoryId';
import getPrice from 'src/store/price/selector/getPrice';
import fetchPricesOperation from 'src/store/price/operation/fetchPricesOperation';

type ClipArtCategory = apiTypes.AppBundle.Api.Response.ClipArt.V1.ClipArtListV1Response.ClipArtResultCategory;

const collectProductIdsFromClipartCategory = (clipArtCategory: ClipArtCategory): number[] => {
  const productIds = clipArtCategory.clipArts.map((clipArt) => clipArt.productId);
  const productIdsFromSubCategories = clipArtCategory.subCategories.map((category) => collectProductIdsFromClipartCategory(category as ClipArtCategory)) || [];
  return [...productIds, ...productIdsFromSubCategories.flat()];
};

const fetchClipArtsPrice = async (clipArtCategory: ClipArtCategory, categoryId: number, dispatch: any, store: Store) => {
  const clipArtIds = [...new Set(collectProductIdsFromClipartCategory(clipArtCategory))];
  const missingPricesProductIds: number[] = [];
  clipArtIds.forEach((productId) => {
    if (!getPrice(store, categoryId, productId, 1)) {
      missingPricesProductIds.push(productId);
    }
  });
  if (missingPricesProductIds.length) {
    await dispatch(fetchPricesOperation(categoryId, missingPricesProductIds));
  }
};

const fetchClipArtsPriceOperation = (clipArtCategory: ClipArtCategory) => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const categoryId = getCategoryId(getState());
  await fetchClipArtsPrice(clipArtCategory, categoryId || 1, dispatch, getState());
};

export default fetchClipArtsPriceOperation;
