import { Selector } from 'src/store/design/types';

const mapSlectorsForApiEditor = (selectors: Selector[]) => {
  selectors.forEach((selector) => {
    selector.type = 'complex';
    let optionsHaveNames = false;
    let optionsHaveColors = false;
    selector.options.forEach((option) => {
      if (option.hexColor) {
        optionsHaveColors = true;
      }
      if (option.name) {
        optionsHaveNames = true;
      }
      if (option.hexColor2) {
        option.hexColor = `${option.hexColor};${option.hexColor2}`;
      }
    });
    if (!optionsHaveNames && optionsHaveColors) {
      selector.type = 'color';
    }
  });
  return selectors;
};

export default mapSlectorsForApiEditor;
