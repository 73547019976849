interface AddonCounter {
  clipArtId: number;
  quantity: number;
}

const calculateUsedClipArtHash = (usedClipArts: AddonCounter[]): string => {
  const sortedClipArts = usedClipArts.sort((a: AddonCounter, b: AddonCounter) => a.clipArtId - b.clipArtId);
  return JSON.stringify(sortedClipArts.map((ca) => `${ca.clipArtId}_${ca.quantity}`));
};

export default calculateUsedClipArtHash;
