const goToOptimalprintOperation = (
  url?: string,
) => () => {
  if (url) {
    (window).location.href = url;
  } else {
    (window).history.back();
  }
};

export default goToOptimalprintOperation;
