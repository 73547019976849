import { AnyAction } from 'redux';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import { DesignData } from 'src/store/design/types';
import getDesignStat from 'src/util/design/getDesignStats';
import getUpsellFeature from 'src/util/upsell/getUpsellFeature';
import getCurrentProductUpsellPrice from 'src/store/price/selector/getCurrentProductUpsellPrice';
import spreadNameToUpsellProductTypeId from 'src/util/upsell/spreadNameToUpsellProductTypeId';
import productPriceToPrice from 'src/util/price/productPriceToPrice';
import getStructure from 'src/store/design/selector/getStructure';
import { addUpsellPriceAction, clearUpsellPriceAction, updateUpsellTotalPriceAction } from '../slice';
import { UpsellFeature } from '../types';

const calculateUpsellPriceOperation = (designData?: DesignData) => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const store = getState();
  const designStat = designData ? getDesignStat(designData) : getDesignStat(getStructure(store));
  const upsellFeatures: UpsellFeature[] = [];

  // upsell charge per page
  Object.keys(designStat.pagesWithContent).forEach((spreadName) => {
    const upsellQuantity = designStat.pagesWithContent[spreadName];
    const upsellPrice = getCurrentProductUpsellPrice(store, 'printAreas', spreadNameToUpsellProductTypeId(spreadName), upsellQuantity);
    if (upsellPrice) {
      upsellFeatures.push(
        getUpsellFeature(`chargePerPage-${spreadName}`, productPriceToPrice(upsellPrice), upsellQuantity, productPriceToPrice(upsellPrice)),
      );
    }
  });

  dispatch(clearUpsellPriceAction());
  upsellFeatures.forEach((upsellFeature) => dispatch(addUpsellPriceAction(upsellFeature)));
  dispatch(updateUpsellTotalPriceAction());
};

export default calculateUpsellPriceOperation;
