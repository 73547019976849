import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import Popup from 'src/component/Popup';
import goToOptimalprintOperation from 'src/store/app/operation/goToOptimalprintOperation';
import hidePopupOperation from 'src/store/app/operation/hidePopupOperation';
import endpoints from 'src/api/opEndpoints';
import getForwardUrl from 'src/store/app/selector/getForwardUrl';
import { opApiRequest } from 'src/api/apiRequest';
import getQuantity from 'src/store/design/selector/getQuantity';
import getOrderItemId from 'src/store/design/selector/getOrderItemId';
import gtmSendEvent from 'src/util/gtm/gtmSendEvent';
import getIsMobile from 'src/store/app/selector/getIsMobile';
import Button from '../Button';
import styles from './index.module.scss';
import ProductPreview from '../FrameSelectorPopup/ProductPreview';
import ProductDetails from './ProductDetails';
import BundledProductDetails from './BundledProductDetails';
import DesignPrice from '../TopBar/CenterSection/DesignPrice';
import QuantitySelector from './QuantitySelector';

const QuantitySelectPopup = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useSelector(getIsMobile);
  const forwardUrl = useSelector(getForwardUrl);
  const quantity = useSelector(getQuantity) || 1;
  const orderItemId = useSelector(getOrderItemId);

  const closePopup = () => {
    dispatch(hidePopupOperation());
    dispatch(goToOptimalprintOperation(forwardUrl));
  };

  const redirectToCheckout = async () => {
    dispatch(hidePopupOperation());
    if (quantity > 1) {
      await updateQuantity();
    }
    gtmSendEvent({
      event: 'api_upsell_quantity_checkout',
    });
    closePopup();
  };

  const updateQuantity = async () => {
    await opApiRequest(
      endpoints.CART_V1_ITEM_QUANTITY_UPDATE,
      {
        quantity,
        orderItemId,
      },
      'data',
      'POST',
    );
  };

  return (
    <Popup
      popupId="SelectQuantityPopup"
      onClosePopup={closePopup}
    >
      <div className={styles.quantitySelectPopup}>
        <div className={styles.columns}>
          <ProductPreview className={cn(styles.preview, { [styles.mobile]: isMobile })} />
          <div className={styles.detailsColumn}>
            <div className={styles.rows}>
              <span className={cn(styles.subTitle, { [styles.mobile]: isMobile })}>{t('opeh_framed_poster')}</span>
              <div className={styles.detailsRow}>
                <ProductDetails />
                <BundledProductDetails />
                <div className={styles.totalPrice}>
                  <DesignPrice />
                </div>
                <QuantitySelector />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.buttonGroup}>
          <Button
            onClick={redirectToCheckout}
            withPadding
            primary
            className={styles.withFrame}
            text={t('txt_checkout')}
          />
        </div>
      </div>
    </Popup>
  );
};

export default QuantitySelectPopup;
