import { AnyAction } from 'redux';
import { DesignError } from 'src/store/design/types';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import { removeDesignErrorAction } from 'src/store/design/slice';

const removeDesignErrorOperation = (error: DesignError) => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => {
  await dispatch(removeDesignErrorAction(error));
};

export default removeDesignErrorOperation;
