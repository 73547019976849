import { Store } from 'src/store/index';
import { checkSimplePhotoMug } from 'src/util/product/productTypeIdsHelper';
import getAttributeValue from './getAttributeValue';
import isAttributeSelected from './isAttributeSelected';

const forceFormatChange = (store: Store) => {
  const isWrapAroundMug = isAttributeSelected(store, 'OpMugPrintArea', 'wrap-around');
  const isSimplePhotoMug = checkSimplePhotoMug(getAttributeValue(store, 'OpMugPrintArea') || '');
  return isWrapAroundMug || isSimplePhotoMug;
};

export default forceFormatChange;
