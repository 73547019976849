import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Store } from 'src/store';
import getProductTypeId from 'src/store/design/selector/getProductTypeId';
import styles from './index.module.scss';

interface Props {
  productTypeId?: number;
}

const DesignTitle = ({ productTypeId }: Props) => {
  const { t } = useTranslation();
  if (!productTypeId) {
    return null;
  }

  const translationKey = `txt_product_type_name_singular_${productTypeId}`;
  return (
    <div className={styles.title}>
      {t(translationKey)}
    </div>
  );
};

const mapStateToProps = (store: Store) => ({
  productTypeId: getProductTypeId(store),
});

export default connect(mapStateToProps)(DesignTitle);
