import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import getIsMobile from 'src/store/app/selector/getIsMobile';
import getFrameUpsellFramePreview from 'src/store/upsell/selector/getFrameUpsellFramePreview';
import styles from './index.module.scss';

interface Props {
  className?: string;
}

const ProductPreview = ({ className }: Props) => {
  const previewUrl = useSelector(getFrameUpsellFramePreview);
  const isMobile = useSelector(getIsMobile);

  if (!previewUrl) {
    return null;
  }

  return (
    <div className={cn(styles.productPreview, className, { [styles.mobile]: isMobile })}>
      <img src={previewUrl} alt="" />
    </div>
  );
};

export default ProductPreview;
