import { PayloadAction } from '@reduxjs/toolkit';
import { UpsellState } from 'src/store/upsell/types';
import apiTypes from 'src/api/optimalprint-sdk.d';
import update from 'immutability-helper';

const setFrameUpsellDataReducer = (
  state: UpsellState, action: PayloadAction<apiTypes.AppBundle.Api.Response.ProductUpsell.V1.ProductUpsellEditorScreenV1Response | undefined>,
) => {
  if (!action.payload) return state;
  return update<UpsellState>(state, {
    frameUpsellPopupData: { $set: action.payload },
  });
};

export default setFrameUpsellDataReducer;
