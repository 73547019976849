import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AvailableFormat from 'src/component/TopBar/CenterSection/DesignFormat/AvailableFormats/AvailableFormat';
import getProductUid from 'src/store/design/selector/getProductUid';
import { Store } from 'src/store/index';
import getAvailableFormats from 'src/store/format/selector/getAvailableFormats';
import { FormatData } from 'src/store/format/types';
import styles from './index.module.scss';

interface Props {
  activeProductUid?: string;
  possibleFormats?: FormatData[];
}

const AvailableFormats = ({ activeProductUid, possibleFormats }: Props) => {
  const { t } = useTranslation();

  if (!possibleFormats) {
    return null;
  }

  return (
    <div>
      <h3>{t('opeh_available_formats')}</h3>
      <div className={styles.formatContainer}>
        {possibleFormats.map((pf: FormatData, i) => (
          <AvailableFormat data={pf} key={i} isSelected={pf.productUid === activeProductUid} />
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (store: Store) => ({
  activeProductUid: getProductUid(store),
  possibleFormats: getAvailableFormats(store),
});

export default connect(mapStateToProps)(AvailableFormats);
