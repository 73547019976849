import { AnyAction } from 'redux';
import { opApiRequest } from 'src/api/apiRequest';
import endpoints from 'src/api/opEndpoints';
import { MESSAGE_TYPE } from 'src/store/app/types';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import apiTypes from 'src/api/optimalprint-sdk.d';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import clipArtToAddon from 'src/util/addon/clipArtToAddon';
import getStructure from 'src/store/design/selector/getStructure';
import getPrice from 'src/store/price/selector/getPrice';
import getCategoryId from 'src/store/design/selector/getCategoryId';
import getPriceFormat from 'src/store/price/selector/getPriceFormat';
import getCurrencyId from 'src/store/price/selector/getCurrencyId';
import formatPrice from 'src/util/price/formatPrice';
import productPriceToPrice from 'src/util/price/productPriceToPrice';
import fetchClipArtsPriceOperation from './fetchClipArtsPriceOperation';
import calculateUsedClipArtsPriceOperation from './calculateUsedClipArtsPriceOperation';
import { addUsedClipArtsAction } from '../slice';

const fetchClipArtsInUse = async (designId: number) => {
  try {
    const clipArtsInUse = (await opApiRequest(
      endpoints.CUSTOMER_DESIGN_V3_GET_CLIP_ARTS,
      {
        designId,
      },
      'data.clipArts',
      'POST',
    )) as apiTypes.AppBundle.Api.Response.Customer.Design.Design.V3.CustomerDesignGetClipArtsV3Response.IClipArt[];
    return clipArtsInUse;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Could not fetch cliparts in use, exception: ', e);
    return undefined;
  }
};

const fetchAndSendClipArtInUseOperation = (designId: number) => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const clipArtsInUse = await fetchClipArtsInUse(designId);
  const store = getState();
  const categoryId = getCategoryId(store);
  const priceFormat = getPriceFormat(store) || '';
  const currencyId = getCurrencyId(store) || '';

  const getAddonPrice = (productId: number) => {
    const priceObj = getPrice(store, categoryId || 1, productId, 1);
    if (!priceObj) {
      return '';
    }
    return formatPrice(productPriceToPrice(priceObj).price, priceFormat, currencyId);
  };

  await dispatch(fetchClipArtsPriceOperation({
    clipArts: clipArtsInUse || [],
    subCategories: [],
  } as unknown as apiTypes.AppBundle.Api.Response.ClipArt.V1.ClipArtListV1Response.ClipArtResultCategory));

  if (clipArtsInUse) {
    await dispatch(sendPostMessage({
      type: MESSAGE_TYPE['addons.setAddonsInUse'],
      data: clipArtsInUse.map((clipArt) => clipArtToAddon(clipArt, getAddonPrice)),
    }));
    dispatch(addUsedClipArtsAction(clipArtsInUse));
    const designStructure = getStructure(getState());
    if (designStructure) {
      await dispatch(calculateUsedClipArtsPriceOperation(designStructure));
    }
  }
};

export default fetchAndSendClipArtInUseOperation;
