import { createSlice } from '@reduxjs/toolkit';
import addClipArtCategoryReducer from 'src/store/addon/reducer/addClipArtCategoryReducer';
import addUsedClipArtsReducer from 'src/store/addon/reducer/addUsedClipArtsReducer';
import addUsedClipArtsHashReducer from 'src/store/addon/reducer/addUsedClipArtsHashReducer';
import addUsedClipArtsPriceReducer from 'src/store/addon/reducer/addUsedClipArtsPriceReducer';
import setClipArtSearchStringReducer from 'src/store/addon/reducer/setClipArtSearchStringReducer';
import { AddonState } from 'src/store/addon/types';

export const addonInitialState: AddonState = {
  clipArtCategories: {},
  usedClipArts: {
    clipArts: [],
    clipArtsHash: '',
    clipArtsPrice: {
      price: 0,
      priceInitial: 0,
    },
  },
  searchString: '',
};

export const slice = createSlice({
  name: 'addon',
  initialState: addonInitialState,
  reducers: {
    addClipartCategoryAction: addClipArtCategoryReducer,
    addUsedClipArtsAction: addUsedClipArtsReducer,
    addUsedClipArtsHashAction: addUsedClipArtsHashReducer,
    addUsedClipArtsPriceAction: addUsedClipArtsPriceReducer,
    setClipArtSearchStringAction: setClipArtSearchStringReducer,
  },
});

export const {
  addClipartCategoryAction,
  addUsedClipArtsAction,
  addUsedClipArtsHashAction,
  addUsedClipArtsPriceAction,
  setClipArtSearchStringAction,
} = slice.actions;

const addone = slice.reducer;
export default addone;
