import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Store } from 'src/store/index';
import { showPopupAction } from 'src/store/app/slice';
import showBackdropOperation from './showBackdropOperation';

const showPopupOperation = (popupId: string) => (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => {
  dispatch(showBackdropOperation(true));
  dispatch(showPopupAction(popupId));
};

export default showPopupOperation;
