import { BundledProduct } from 'src/store/productBundle/types';
import { IntegrationLayer } from 'src/types.d';
import getProductAttribute from '../product/getProductAttribute';
import resolveBundledProducts from './resolveBundledProducts';

const SUPPORT_FRAME_PREVIEW_FOR_BUNDLED_PRODUCTS = ['hangers', 'frames'];
const FRAME_MATERIAL_COLORS: { [material: string]: { [key: string]: string }} = {
  wood: {
    white: 'white',
    black: 'black',
    'natural-wood': 'wood',
    'oak-natural-wood': 'wood',
    'dark-wood': 'dark-wood',
  },
  aluminum: {
    gold: 'gold-metallic',
    black: 'black-metallic',
    copper: 'copper-metallic',
    silver: 'silver-metallic',
  },
};

const getPreflightFrameMaterial = (color: string, material: string) => {
  let preflightMaterial;
  try {
    preflightMaterial = FRAME_MATERIAL_COLORS[material][color];
  } catch {
    preflightMaterial = undefined;
  }
  return preflightMaterial;
};

export default function getBundledProductsPreviewParameters(integrationLayer: IntegrationLayer, productId: number, bundledProducts: BundledProduct[]) {
  const extraParameters: string[] = [];
  const selectedProducts = resolveBundledProducts(integrationLayer, productId, bundledProducts);
  if (!selectedProducts.length) return false;

  selectedProducts.forEach((product) => {
    if (SUPPORT_FRAME_PREVIEW_FOR_BUNDLED_PRODUCTS.indexOf(getProductAttribute('ProductType', product)?.value || '') >= 0) {
      let frameType = getProductAttribute('ProductType', product)?.value || '';
      // convert type name to singular: frames to frame
      frameType = frameType.substr(0, frameType.length - 1);

      const frameColor = getProductAttribute('PictureFrameColor', product)?.value;
      const frameMaterial = getProductAttribute('PictureFrameMaterial', product)?.value;
      if (frameColor && frameMaterial) {
        const preflightMaterial = getPreflightFrameMaterial(frameColor, frameMaterial);
        if (preflightMaterial) {
          extraParameters.push(`${frameType}=${preflightMaterial}`);
        }
      }
    }
  });
  return extraParameters;
}
