import { createSlice } from '@reduxjs/toolkit';
import addGalleryImagesReducer from 'src/store/gallery/reducer/addGalleryImagesReducer';
import { GalleryState } from 'src/store/gallery/types';

export const galleryInitialState: GalleryState = {
  images: [],
};

export const slice = createSlice({
  name: 'gallery',
  initialState: galleryInitialState,
  reducers: {
    addGalleryImagesAction: addGalleryImagesReducer,
  },
});

export const {
  addGalleryImagesAction,
} = slice.actions;

const gallery = slice.reducer;
export default gallery;
