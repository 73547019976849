import {
  LayoutSchema,
} from './layoutTypes';
import defineLayoutSchema from './defineLayoutSchema';

const INTERNAL_PADDING = 8;
const EXTERNAL_PADDING = 12;

const layoutSchemasLayflat: LayoutSchema[] = [
  defineLayoutSchema('1', 1, 1, 10, 0, 0, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
    ],
  ], true),
  defineLayoutSchema('i1t1_4', 1, 6, 10, 0, 0, 0, undefined, 1, [
    [
      { width: 1, height: 6 },
    ],
    [],
    [],
    [],
    [],
    [
      { width: 1, height: 1, type: 'text' },
    ],
  ], true),
  defineLayoutSchema('i1t1_4b', 1, 6, 10, 0, 0, 0, undefined, 1, [
    [
      { width: 1, height: 6 },
    ],
    [
      { width: 1, height: 1, type: 'text' },
    ],
    [],
    [],
    [],
    [],
  ], true),
  defineLayoutSchema('2', 1, 1, 10, 0, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
    ],
  ]),
  defineLayoutSchema('i1t1_5', 1, 6, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 6 },
    ],
    [],
    [],
    [],
    [],
    [
      { width: 1, height: 1, type: 'text' },
    ],
  ]),
  defineLayoutSchema('i1t1_5b', 1, 6, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 6 },
    ],
    [
      { width: 1, height: 1, type: 'text' },
    ],
    [],
    [],
    [],
    [],
  ]),
  defineLayoutSchema('i1t1_1', 4, 5, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 3, height: 5 },
      { width: 1, height: 2, type: 'text' },
    ],
  ]),
  defineLayoutSchema('i1t1_2', 4, 5, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 2, type: 'text' },
      { width: 3, height: 5 },
    ],
  ]),
  defineLayoutSchema('i1t1_3', 2, 7, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 2, height: 6 },
    ],
    [
    ],
    [
    ],
    [
    ],
    [
    ],
    [
    ],
    [
      { width: 2, height: 1, type: 'text' },
    ],
  ]),
  defineLayoutSchema('3', 2, 2, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      {
        width: 1, height: 2,
      },
      {
        width: 1, height: 2,
      },
    ],
  ]),
  defineLayoutSchema('i2t2_1', 2, 7, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 6 },
      { width: 1, height: 6 },
    ],
    [
    ],
    [
    ],
    [
    ],
    [
    ],
    [
    ],
    [
      { width: 1, height: 1, type: 'text' },
      { width: 1, height: 1, type: 'text' },
    ],
  ]),
  defineLayoutSchema('4', 2, 2, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 2 },
      {
        width: 1, height: 1,
      },
    ],
    [
      {},
      {
        width: 1, height: 1,
      },
    ],
  ]),
  defineLayoutSchema('5', 2, 2, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      { width: 1, height: 2 },
    ],
    [
      { width: 1, height: 1 },
    ],
  ]),
  defineLayoutSchema('i3t1_1', 2, 7, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 2, height: 3 },
    ],
    [
    ],
    [
    ],
    [
      { width: 1, height: 3 },
      { width: 1, height: 3 },
    ],
    [
    ],
    [
    ],
    [
      { width: 2, height: 1, type: 'text' },
    ],
  ]),
  defineLayoutSchema('i3t2_1', 2, 7, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 6 },
      { width: 1, height: 3 },
    ],
    [
    ],
    [
    ],
    [
      { },
      { width: 1, height: 3 },
    ],
    [
    ],
    [
    ],
    [
      { width: 1, height: 1, type: 'text' },
      { width: 1, height: 1, type: 'text' },
    ],
  ]),
  defineLayoutSchema('i3t2_2', 2, 7, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 3 },
      { width: 1, height: 6 },
    ],
    [
    ],
    [
    ],
    [
      { width: 1, height: 3 },
    ],
    [
    ],
    [
    ],
    [
      { width: 1, height: 1, type: 'text' },
      { width: 1, height: 1, type: 'text' },
    ],
  ]),
  defineLayoutSchema('6', 2, 2, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
  ]),
  defineLayoutSchema('i4t1_1', 2, 9, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 4 },
      { width: 1, height: 4 },
    ],
    [],
    [],
    [],
    [
      { width: 1, height: 4 },
      { width: 1, height: 4 },
    ],
    [],
    [],
    [],
    [
      { width: 2, height: 1, type: 'text' },
    ],
  ]),
  defineLayoutSchema('i4t0_1', 4, 1, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
  ]),
  defineLayoutSchema('i4t2_2', 4, 9, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 8 },
      { width: 1, height: 8 },
      { width: 1, height: 8 },
      { width: 1, height: 8 },
    ],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [
      { width: 2, height: 1, type: 'text' },
      { width: 2, height: 1, type: 'text' },
    ],
  ]),
  defineLayoutSchema('i4t2_1', 2, 7, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 3 },
      { width: 1, height: 3 },
    ],
    [
    ],
    [
    ],
    [
      { width: 1, height: 3 },
      { width: 1, height: 3 },
    ],
    [
    ],
    [
    ],
    [
      { width: 1, height: 1, type: 'text' },
      { width: 1, height: 1, type: 'text' },
    ],
  ]),
  defineLayoutSchema('7', 3, 2, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 2 },
      { width: 1, height: 1 },
      { width: 1, height: 2 },
    ],
    [
      {},
      { width: 1, height: 1 },
    ],
  ]),
  defineLayoutSchema('8', 3, 2, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      { width: 1, height: 2 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      {},
      { width: 1, height: 1 },
    ],
  ]),
  defineLayoutSchema('9', 3, 2, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
  ]),
  defineLayoutSchema('10', 4, 5, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 2, height: 3 },
      { width: 1, height: 2 },
      { width: 1, height: 2 },
    ],
    [],
    [
      {},
      {},
      { width: 2, height: 3 },
    ],
    [
      { width: 1, height: 2 },
      { width: 1, height: 2 },
    ],
  ]),
  defineLayoutSchema('i6t0_1', 4, 8, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 8 },
      { width: 1, height: 4 },
      { width: 1, height: 8 },
      { width: 1, height: 4 },
    ],
    [],
    [],
    [],
    [
      {},
      { width: 1, height: 4 },
      {},
      { width: 1, height: 4 },
    ],
  ]),
  defineLayoutSchema('i6t2_1', 4, 9, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 8 },
      { width: 1, height: 4 },
      { width: 1, height: 8 },
      { width: 1, height: 4 },
    ],
    [],
    [],
    [],
    [
      {},
      { width: 1, height: 4 },
      {},
      { width: 1, height: 4 },
    ],
    [],
    [],
    [],
    [
      { width: 2, height: 1, type: 'text' },
      { width: 2, height: 1, type: 'text' },
    ],
  ]),
  defineLayoutSchema('11', 5, 3, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      { width: 3, height: 3 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      {},
      {},
      {},
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      {},
      {},
      {},
      { width: 1, height: 1 },
    ],
  ]),
  defineLayoutSchema('12', 4, 3, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 2 },
      { width: 1, height: 1 },
      { width: 1, height: 2 },
      { width: 1, height: 1 },
    ],
    [
      {},
      { width: 1, height: 2 },
      {},
      { width: 1, height: 2 },
    ],
    [
      { width: 1, height: 1 },
      {},
      { width: 1, height: 1 },
    ],
  ]),
  defineLayoutSchema('i8t0_1', 4, 8, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 4 },
      { width: 1, height: 4 },
      { width: 1, height: 4 },
      { width: 1, height: 4 },
    ],
    [],
    [],
    [],
    [
      { width: 1, height: 4 },
      { width: 1, height: 4 },
      { width: 1, height: 4 },
      { width: 1, height: 4 },
    ],
  ]),
  defineLayoutSchema('i8t2_1', 4, 9, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 4 },
      { width: 1, height: 4 },
      { width: 1, height: 4 },
      { width: 1, height: 4 },
    ],
    [],
    [],
    [],
    [
      { width: 1, height: 4 },
      { width: 1, height: 4 },
      { width: 1, height: 4 },
      { width: 1, height: 4 },
    ],
    [],
    [],
    [],
    [
      { width: 2, height: 1, type: 'text' },
      { width: 2, height: 1, type: 'text' },
    ],
  ]),
  defineLayoutSchema('i9t1_1', 3, 10, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 3 },
      { width: 1, height: 3 },
      { width: 1, height: 3 },
    ],
    [],
    [],
    [
      { width: 1, height: 3 },
      { width: 1, height: 3 },
      { width: 1, height: 3 },
    ],
    [],
    [],
    [
      { width: 1, height: 3 },
      { width: 1, height: 3 },
      { width: 1, height: 3 },
    ],
    [],
    [],
    [
      { width: 3, height: 1, type: 'text' },
    ],
  ]),
  defineLayoutSchema('13', 6, 3, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 2, height: 2 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 2, height: 2 },
    ],
    [
      {},
      {},
      { width: 2, height: 2 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      {},
      {},
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
  ]),
  defineLayoutSchema('14', 4, 3, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
  ]),
  defineLayoutSchema('15', 6, 4, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 2, height: 2 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 2, height: 2 },
    ],
    [
      {},
      {},
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 2, height: 2 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      {},
      {},
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
  ]),
  defineLayoutSchema('16', 6, 4, 10, INTERNAL_PADDING, EXTERNAL_PADDING, 0, undefined, 1, [
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
    [
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
      { width: 1, height: 1 },
    ],
  ]),
];

export default layoutSchemasLayflat;
