import { AnyAction } from 'redux';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import { MESSAGE_TYPE } from 'src/store/app/types';
import { Store } from 'src/store/index';
import apiTypes from 'src/api/optimalprint-sdk.d';
import { ThunkDispatch } from 'redux-thunk';
import getPrice from 'src/store/price/selector/getPrice';
import getCategoryId from 'src/store/design/selector/getCategoryId';
import formatPrice from 'src/util/price/formatPrice';
import productPriceToPrice from 'src/util/price/productPriceToPrice';
import getCurrencyId from 'src/store/price/selector/getCurrencyId';
import getPriceFormat from 'src/store/price/selector/getPriceFormat';
import clipArtToAddon from 'src/util/addon/clipArtToAddon';
import getClipartSearchString from '../selector/getClipartSearchString';

type ClipArtCategory = apiTypes.AppBundle.Api.Response.ClipArt.V1.ClipArtListV1Response.ClipArtResultCategory;

const sendClipArtSearchResultsPostMessageOperation = (category: ClipArtCategory, requestVersion: number) => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const store = getState();
  const categoryId = getCategoryId(store);

  const currencyId = getCurrencyId(store) || '';
  const priceFormat = getPriceFormat(store) || '';

  const getAddonPrice = (productId: number) => {
    const priceObj = getPrice(store, categoryId || 1, productId, 1);
    if (!priceObj) {
      return '';
    }
    return formatPrice(productPriceToPrice(priceObj).price, priceFormat, currencyId);
  };

  await dispatch(sendPostMessage({
    type: MESSAGE_TYPE['addons.setAddons'],
    data: {
      search: {
        value: getClipartSearchString(store),
        recent: [],
      },
      categoryId: 0,
      resultsVersion: requestVersion,
      results: {
        category: undefined,
        elements: category.clipArts.map((clipArt) => clipArtToAddon(clipArt, getAddonPrice)),
      },
    },
  }));
};

export default sendClipArtSearchResultsPostMessageOperation;
