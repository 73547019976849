import { PayloadAction } from '@reduxjs/toolkit';
import { AddonState } from 'src/store/addon/types';

const setClipArtSearchStringReducer = (
  state: AddonState, action: PayloadAction<string>,
) => {
  state.searchString = action.payload;
};

export default setClipArtSearchStringReducer;
