import { AnyAction } from 'redux';
import { opApiRequest } from 'src/api/apiRequest';
import endpoints from 'src/api/opEndpoints';
import apiTypes from 'src/api/optimalprint-sdk.d';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import getProductBundleSelectedProducts from 'src/store/productBundle/selector/getProductBundleSelectedProducts';
import resolveBundledProducts from 'src/util/bundles/resolveBundledProducts';
import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';
import getProductId from 'src/store/design/selector/getProductId';
import { setEditorScenesAction } from '../slice';

interface ScenesListParams {
  productUid: string;
  bundledUid?: string;
}

const fetchEditorScenesOperation = (productUid: string, bundledProductId: number) => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  try {
    const store = getState();
    const params = {
      productUid,
    } as ScenesListParams;
    const bundledProducts = getProductBundleSelectedProducts(store);
    if (bundledProducts.length) {
      const productId = getProductId(store) || 0;
      const resolvedBandledProducts = resolveBundledProducts(useIntegrationLayer(), productId, bundledProducts);
      if (resolvedBandledProducts.length) {
        params.bundledUid = resolvedBandledProducts[0].productUid;
      }
    }

    const response = (await opApiRequest(
      endpoints.PUBLIC_DESIGN_V3_GET_EDITOR_SCENES_LIST,
      params,
      'data',
      'GET',
    )) as apiTypes.AppBundle.Api.Response.Design.V3.DesignPublicEditorScenesListV3Response;
    if (!response) {
      // eslint-disable-next-line no-console
      console.error('Cannot fetch editor scenes');
    } else {
      await dispatch(setEditorScenesAction({ productUid, bundledProductId, scenes: response.scenes }));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Cannot fetch editor scenes, exception: ', e);
  }
};

export default fetchEditorScenesOperation;
