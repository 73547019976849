import { cloneDeep } from 'lodash';
import { AppBundle } from 'src/api/optimalprint-sdk.d';
import { BundledProduct } from 'src/store/productBundle/types';
import { IntegrationLayer } from 'src/types.d';

type ProductItem = AppBundle.Api.Entity.App.V1.AbstractProduct.ProductItem;

const getAllProducts = (integrationLayer: IntegrationLayer) => (
  (integrationLayer.abstractProduct && integrationLayer.abstractProduct.products as any[] as ProductItem[]) || []
);

const findBundledGroupId = (productId: number, integrationLayer: IntegrationLayer) => {
  let bundleGroupId;
  getAllProducts(integrationLayer).find((product) => product.bundleGroups?.some((bundledGroup) => bundledGroup.items?.find((item) => {
    if (item.productId === productId) {
      bundleGroupId = bundledGroup.bundleGroupId;
      return true;
    }
    return false;
  })));
  return bundleGroupId || 0;
};

const fillBundleGroupId = (selectedProducts: BundledProduct[]) => {
  const { integrationLayer } = window;

  const result = cloneDeep(selectedProducts) as BundledProduct[];
  result.forEach((bundledProduct) => {
    bundledProduct.bundleGroupId = findBundledGroupId(bundledProduct.productId, integrationLayer);
  });
  return result;
};

export default fillBundleGroupId;
