import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';
import keys from './locale/keys.json';

const { hostAppTranslations } = useIntegrationLayer();

const translations = {
  ...keys,
  ...hostAppTranslations,
};

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translations,
      },
    },
    fallbackLng: 'en',
    debug: true,

    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
