/* eslint-disable max-len */
import React from 'react';
import Icon from 'src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const IconBasket = ({ className }: Props) => (
  <Icon className={className}>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="7" y="10" width="18" height="16" stroke="#333333" strokeWidth="2" strokeLinejoin="round" />
      <path
        d="M21 12V9C21 6.23858 18.7614 4 16 4V4C13.2386 4 11 6.23858 11 9V12"
        strokeWidth="2"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Icon>
);

export default IconBasket;
