import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { AppBundle } from 'src/api/optimalprint-sdk.d';
import getProductUid from 'src/store/design/selector/getProductUid';
import fetchEditorScenesOperation from 'src/store/editor/operation/fetchEditorScenesOperation';
import sendSetSpreadBackgroundsPostMessageOperation
  from 'src/store/editor/operation/sendSetSpreadBackgroundsPostMessageOperation';
import sendSetSpreadForegroundsPostMessageOperation
  from 'src/store/editor/operation/sendSetSpreadForegroundsPostMessageOperation';
import getEditorScenes from 'src/store/editor/selector/getEditorScenes';
import { Store } from 'src/store/index';
import getFirstBundledProductId from 'src/store/productBundle/selector/getFirstBundledProductId';
import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';

const fetchAndSendSetSpreadBackgroundsOperation = (inputProductUid?: string) => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const { productUid } = useIntegrationLayer();
  let store = getState();
  const uid = inputProductUid || getProductUid(store) || productUid;
  const bundledProductId = getFirstBundledProductId(store);
  // apply bundled product image as a foreground
  const setForeground = bundledProductId > 0;

  const applyGroundImages = (
    scenes: AppBundle.Api.Response.Design.V3.DesignPublicEditorScenesListV3Response.Scene[],
  ) => {
    if (setForeground) {
      dispatch(sendSetSpreadBackgroundsPostMessageOperation([]));
      dispatch(sendSetSpreadForegroundsPostMessageOperation(scenes));
      return;
    }
    dispatch(sendSetSpreadBackgroundsPostMessageOperation(scenes));
    dispatch(sendSetSpreadForegroundsPostMessageOperation([]));
  };

  if (uid) {
    let scenes = getEditorScenes(store, uid, bundledProductId);
    if (scenes) {
      applyGroundImages(scenes);
    } else {
      await dispatch(fetchEditorScenesOperation(uid, bundledProductId));
      store = getState();
      scenes = getEditorScenes(store, uid, bundledProductId);
      if (scenes) {
        applyGroundImages(scenes);
      }
    }
  }
};

export default fetchAndSendSetSpreadBackgroundsOperation;
