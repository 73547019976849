import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import isFeatureEnabled from 'src/store/app/selector/isFeatureEnabled';
import { Feature, MESSAGE_TYPE } from 'src/store/app/types';
import { Store } from 'src/store/index';

const sendEditorFeaturesPostMessageOperation = () => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const store = getState();

  dispatch(sendPostMessage({
    type: MESSAGE_TYPE['editor.allowAddRemoveElements'],
    data: {
      allowAdd: isFeatureEnabled(store, Feature.allowAddElements),
      allowRemove: isFeatureEnabled(store, Feature.allowRemoveElements),
    },
  }));

  dispatch(sendPostMessage({
    type: MESSAGE_TYPE['editor.allowLockElements'],
    data: {
      allowLock: isFeatureEnabled(store, Feature.allowElementLockControl),
      allowPersonalizationLock: false,
    },
  }));
};

export default sendEditorFeaturesPostMessageOperation;
