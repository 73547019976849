import {
  LayoutSchema,
} from './layoutTypes';
import defineLayoutSchema from './defineLayoutSchema';

const layoutSchemasEmpty: LayoutSchema[] = [
  defineLayoutSchema('empty_1', 1, 1, 10, 0, 0, 0, undefined, 1, [
    [],
  ], true),
];

export default layoutSchemasEmpty;
