/* eslint-disable max-len */
import React from 'react';
import Icon from 'src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const IconDropDownArrow = ({ className }: Props) => (
  <Icon className={className}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.01" fillRule="evenodd" clipRule="evenodd" d="M0 0L16 0L16 16L0 16L0 0Z" fill="#D0021B" fillOpacity="0.1" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.66675 6.66669L8.00008 10L11.3334 6.66669L4.66675 6.66669Z" fill="#17A9ED" />
    </svg>
  </Icon>
);

export default IconDropDownArrow;
