import Axios, { AxiosRequestConfig, Method } from 'axios';
import get from 'lodash/get';

async function sendAxiosRequest(
  config: AxiosRequestConfig,
): Promise<any> {
  let result;
  delete config.adapter;
  config.withCredentials = false;

  try {
    const response = await Axios(config);

    result = { ...response };
  } catch (err) {
    return err.response;
  }

  return result;
}

const getHeaders = () => ({
  'Content-Type': 'application/json',
});

const prepareFullUrl = (apiBaseUrl: string, url: string) => {
  if (url.indexOf('http') === 0) {
    return url;
  }
  return apiBaseUrl + url;
};

const getBaseUrl = () => window.integrationLayer.preFlightApiBaseUrl;

const apiRequest = (
  url: string,
  data: string | any,
  resultField: string,
  method: Method = 'GET',
  withCookies = true,
  maxRetries = 3,
) => new Promise(
  // eslint-disable-next-line no-async-promise-executor
  (resolve, reject): Promise<any | any[]> => {
    const baseUrl = getBaseUrl();

    let retry = 0;

    const newUrl = prepareFullUrl(baseUrl, url);

    const options: AxiosRequestConfig = {
      method,
      headers: getHeaders(),
    };

    if (method === 'POST') {
      options.data = JSON.stringify(data);
    } else {
      options.params = data;
    }
    const request = async () => {
      retry += 1;

      try {
        const result: any = await sendAxiosRequest(
          {
            url: newUrl,
            ...options,
          },
        );
        if (!result) {
          // eslint-disable-next-line no-throw-literal
          throw 'no response';
        }

        resolve(get(result, resultField));
      } catch (e) {
        const error = `API ERROR ${JSON.stringify(e)}`;
        // eslint-disable-next-line no-console
        console.error(error);

        if (retry < maxRetries) {
          setTimeout(request, retry * 1000);
        } else {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(`${error}`);
        }
      }
    };

    // noinspection JSIgnoredPromiseFromCall
    return request();
  },
);

export default apiRequest;
