import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { opApiRequest } from 'src/api/apiRequest';
import endpoints from 'src/api/opEndpoints';
import apiTypes from 'src/api/optimalprint-sdk.d';
import {
  setAllFormatsAction,
  setAllFormatsAreLoadingAction,
  setAllFormatsAreLoadedAction,
} from 'src/store/format/slice';
import areAllFormatsLoading from 'src/store/format/selector/areAllFormatsLoading';
import { Store } from 'src/store/index';

const fetchAllPaperFormatsOperation = () => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const store = getState();
  if (areAllFormatsLoading(store)) {
    return;
  }
  await dispatch(setAllFormatsAreLoadingAction(true));

  let response;
  try {
    response = (await opApiRequest(
      endpoints.PAPER_FORMAT_V1_LIST,
      null,
      'data',
    )) as apiTypes.AppBundle.Api.Response.PaperFormat.V1.PaperFormatListV1Response;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Cannot fetch all formats, exception', e);
  }

  if (response) {
    const { paperFormats } = response;
    await dispatch(setAllFormatsAreLoadingAction(false));
    await dispatch(setAllFormatsAreLoadedAction(true));
    await dispatch(setAllFormatsAction(paperFormats));
  } else {
    // eslint-disable-next-line no-console
    console.error('Cannot fetch available formats');
  }
};

export default fetchAllPaperFormatsOperation;
