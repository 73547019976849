import getSelectedProductAttributes from 'src/store/design/selector/getSelectedProductAttributes';
import { Store } from 'src/store/index';

const getAttributeValue = (store: Store, selectorTypeName: string) => {
  const selectedAttributes = getSelectedProductAttributes(store);
  const selected = selectedAttributes[selectorTypeName];

  if (!selected) {
    return false;
  }

  return selected.value;
};

export default getAttributeValue;
