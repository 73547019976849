import { createSlice } from '@reduxjs/toolkit';
import setProductBundleSelectedProductsReducer
  from 'src/store/productBundle/reducer/setProductBundleSelectedProductsReducer';
import setProductBundleSelectedProductsTotalPriceReducer
  from 'src/store/productBundle/reducer/setProductBundleSelectedProductsTotalPriceReducer';
import { ProductBundleState } from 'src/store/productBundle/types';
import changeBundledProductReducer from './reducer/changeBundledProductReducer';

export const productBundleInitialState: ProductBundleState = {
  selectedProducts: [],
  selectedProductsTotalPrice: {
    price: 0,
    priceInitial: 0,
  },
};

export const slice = createSlice({
  name: 'productBundle',
  initialState: productBundleInitialState,
  reducers: {
    setProductBundleSelectedProductsAction: setProductBundleSelectedProductsReducer,
    setProductBundleSelectedProductsTotalPriceAction: setProductBundleSelectedProductsTotalPriceReducer,
    changeBundledProductAction: changeBundledProductReducer,
  },
});

export const {
  setProductBundleSelectedProductsAction,
  setProductBundleSelectedProductsTotalPriceAction,
  changeBundledProductAction,
} = slice.actions;

const productBundle = slice.reducer;
export default productBundle;
