import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { useHistory } from 'react-router-dom';
import Button from 'src/component/Button';
import IconArrowLeft from 'src/component/Icon/IconArrowLeft';
import { Store } from 'src/store';
import isDesignSaving from 'src/store/design/selector/isDesignSaving';
import sendExitFromEditorConfirmationOperation from 'src/store/app/operation/sendExitFromEditorConfirmationOperation';
import hasDesignStructureChanged from 'src/store/design/selector/hasDesignStructureChanged';

interface OwnProps {
  showLabel: boolean;
}

interface Props extends OwnProps {
  isSaving: boolean;
  hasDesignChanged: boolean;
  dispatch: ThunkDispatch<Store, void, AnyAction>;
}

const CancelButton = ({
  dispatch, isSaving, showLabel, hasDesignChanged,
}: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const onClick = () => {
    if (hasDesignChanged) {
      dispatch(sendExitFromEditorConfirmationOperation());
      return;
    }
    history.goBack();
  };

  return (
    <Button
      onClick={onClick}
      withPadding
      disabled={isSaving}
      icon={<IconArrowLeft />}
      text={showLabel ? t('opeh_top-bar_cancel') : null}
    />
  );
};

const mapStateToProps = (store: Store) => ({
  isSaving: isDesignSaving(store),
  hasDesignChanged: hasDesignStructureChanged(store),
});

export default connect(mapStateToProps)(CancelButton);
