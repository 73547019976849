/* eslint-disable max-len */
import React from 'react';
import Icon from 'src/component/Icon/index';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const IconTick = ({ className }: Props) => (
  <Icon className={className}>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="#ffffff"
        d="M10.7071 14.7929C10.3166 14.4024 9.68342 14.4024 9.29289 14.7929C8.90237 15.1834 8.90237 15.8166 9.29289 16.2071L10.7071 14.7929ZM14 19.5L13.2929 20.2071C13.6834 20.5976 14.3166 20.5976 14.7071 20.2071L14 19.5ZM22.2071 12.7071C22.5976 12.3166 22.5976 11.6834 22.2071 11.2929C21.8166 10.9024 21.1834 10.9024 20.7929 11.2929L22.2071 12.7071ZM9.29289 16.2071L13.2929 20.2071L14.7071 18.7929L10.7071 14.7929L9.29289 16.2071ZM14.7071 20.2071L22.2071 12.7071L20.7929 11.2929L13.2929 18.7929L14.7071 20.2071Z"
      />
    </svg>
  </Icon>
);

export default IconTick;
