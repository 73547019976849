import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import cn from 'classnames';
import Price from 'src/component/Price';
import { Store } from 'src/store';
import apiTypes from 'src/api/optimalprint-sdk.d';
import getCategoryId from 'src/store/design/selector/getCategoryId';
import getMinPagesCount from 'src/store/design/selector/getMinPagesCount';
import getProductId from 'src/store/design/selector/getProductId';
import getProductTypeId from 'src/store/design/selector/getProductTypeId';
import getTotalPagesCount from 'src/store/design/selector/getTotalPagesCount';
import fetchPricesOperation from 'src/store/price/operation/fetchPricesOperation';
import getCurrencyId from 'src/store/price/selector/getCurrencyId';
import getCurrentDesignPrice from 'src/store/price/selector/getCurrentDesignPrice';
import getPriceFormat from 'src/store/price/selector/getPriceFormat';
import calculateDesignPrice from 'src/util/price/calculateDesignPrice';
import getUsedClipArtsPrice from 'src/store/addon/selector/getUsedClipArtsPrice';
import { Price as RawPrice } from 'src/store/price/types';
import addExtraPrice from 'src/util/price/addExtraPrice';
import isFeatureEnabled from 'src/store/app/selector/isFeatureEnabled';
import { Feature } from 'src/store/app/types';
import getUpsellTotalPrice from 'src/store/upsell/selector/getUpsellTotalPrice';
import fetchUpsellDataOperation from 'src/store/upsell/operation/fetchUpsellDataOperation';
import getQuantity from 'src/store/design/selector/getQuantity';
import getProductBundleSelectedProductsTotalPrice from 'src/store/productBundle/selector/getProductBundleSelectedProductsTotalPrice';
import styles from './index.module.scss';

interface Props {
  price?: apiTypes.AppBundle.Api.Entity.Product.V1.IProductPrice;
  currencyId?: string;
  priceFormat?: string;
  productTypeId?: number;
  totalPagesCount?: number;
  className?: string;
  categoryId?: number;
  productId?: number;
  minPages?: number;
  addonPrice: RawPrice;
  upsellPrice: RawPrice;
  bundledProductsPrice: RawPrice;
  addonSupportEnabled: boolean;
  quantity?: number;
  dispatch: ThunkDispatch<Store, void, AnyAction>;
}

const DesignPrice = (
  {
    price,
    currencyId,
    priceFormat,
    productTypeId,
    totalPagesCount,
    categoryId,
    productId,
    dispatch,
    minPages,
    addonPrice,
    upsellPrice,
    addonSupportEnabled,
    bundledProductsPrice,
    className,
    quantity,
  }: Props,
) => {
  useEffect(() => {
    if (categoryId && productId) {
      dispatch(fetchPricesOperation(categoryId, [productId]));
      dispatch(fetchUpsellDataOperation(categoryId, [productId]));
    }
  }, [categoryId, productId]);

  if (
    !price
    || !currencyId
    || !priceFormat
    || !productTypeId
    || !totalPagesCount
  ) {
    return null;
  }

  let calculatedPrice = calculateDesignPrice(price, productTypeId, minPages, totalPagesCount);

  if (addonSupportEnabled) {
    calculatedPrice = addExtraPrice(calculatedPrice, addonPrice, quantity);
  }

  calculatedPrice = addExtraPrice(calculatedPrice, upsellPrice, quantity);
  calculatedPrice = addExtraPrice(calculatedPrice, bundledProductsPrice, quantity);

  return (
    <div className={cn(styles.price, className || '')}>
      <Price price={calculatedPrice} currencyId={currencyId} priceFormat={priceFormat} />
    </div>
  );
};

const mapStateToProps = (store: Store) => (
  {
    price: getCurrentDesignPrice(store),
    currencyId: getCurrencyId(store),
    priceFormat: getPriceFormat(store),
    productTypeId: getProductTypeId(store),
    totalPagesCount: getTotalPagesCount(store),
    categoryId: getCategoryId(store),
    productId: getProductId(store),
    minPages: getMinPagesCount(store),
    addonPrice: getUsedClipArtsPrice(store),
    upsellPrice: getUpsellTotalPrice(store),
    bundledProductsPrice: getProductBundleSelectedProductsTotalPrice(store),
    addonSupportEnabled: isFeatureEnabled(store, Feature.supportClipArt),
    quantity: getQuantity(store),
  }
);

export default connect(mapStateToProps)(DesignPrice);
