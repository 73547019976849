import { AnyAction } from 'redux';
import fetchCustomerDesignOperation from 'src/store/design/operation/fetchCustomerDesignOperation';
import fetchPublicDesignByProductUidOperation from 'src/store/design/operation/fetchPublicDesignByProductUidOperation';
import fetchPublicDesignOperation from 'src/store/design/operation/fetchPublicDesignOperation';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import useIntegrationLayer from 'src/util/hook/useIntegrationLayer';

const fetchDesignStructureOperation = () => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => {
  const { isPublicDesign, abstractProduct } = useIntegrationLayer();
  if (isPublicDesign) {
    if (abstractProduct) {
      await dispatch(fetchPublicDesignByProductUidOperation());
    } else {
      await dispatch(fetchPublicDesignOperation());
    }
  } else {
    await dispatch(fetchCustomerDesignOperation());
  }
};

export default fetchDesignStructureOperation;
