import { PayloadAction } from '@reduxjs/toolkit';
import { AddonState } from 'src/store/addon/types';
import update from 'immutability-helper';
import { Price } from 'src/store/price/types';

const addUsedClipArtsPriceReducer = (
  state: AddonState, action: PayloadAction<Price>,
) => {
  if (!action.payload) return state;
  return update<AddonState>(state, {
    usedClipArts: {
      clipArtsPrice: {
        $set: action.payload,
      },
    },
  });
};

export default addUsedClipArtsPriceReducer;
