import React from 'react';
import { connect } from 'react-redux';
import DropdownMenu from 'src/component/DropdownMenu';
import AvailableFormats from 'src/component/TopBar/CenterSection/DesignFormat/AvailableFormats';
import { Store } from 'src/store';
import getOpenedDropdownMenu from 'src/store/app/selector/getOpenedDropdownMenu';
import { DropDownMenuEnum } from 'src/store/app/types';
import styles from './index.module.scss';

interface Props {
  isDropdownVisible: boolean;
}

const PhotobooksDropdownMenu = ({ isDropdownVisible }: Props) => (
  <DropdownMenu
    isVisible={isDropdownVisible}
    className={styles.dropDown}
  >
    <AvailableFormats />
  </DropdownMenu>
);

const mapStateToProps = (store: Store) => ({
  isDropdownVisible: getOpenedDropdownMenu(store) === DropDownMenuEnum.photobooksFormatSelector,
});

export default connect(mapStateToProps)(PhotobooksDropdownMenu);
