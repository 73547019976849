import React from 'react';
import classNames from 'classnames';
import { Price as PriceType } from 'src/store/price/types';
import formatPrice from 'src/util/price/formatPrice';
import styles from './index.module.scss';

interface Props {
  price: PriceType;
  currencyId: string;
  priceFormat: string;
  className?: string;
}

const Price = (props: Props) => {
  const {
    price, currencyId, priceFormat, className,
  } = props;

  return (
    <span className={classNames(styles.price, className)}>
      <span
        className={classNames({
          [styles.crossed]: price.priceInitial !== price.price,
        })}
      >
        {formatPrice(price.priceInitial, priceFormat, currencyId)}
      </span>
      {price.priceInitial !== price.price ? (
        <span
          className={classNames({
            [styles.red]: price.priceInitial !== price.price,
          })}
        >
          {formatPrice(price.price, priceFormat, currencyId)}
        </span>
      ) : null}
    </span>
  );
};

export default Price;
