import getAbstractProduct from 'src/store/design/selector/getAbstractProduct';
import getSelectedProductAttributes from 'src/store/design/selector/getSelectedProductAttributes';
import { Attribute, SelectorOption } from 'src/store/design/types';
import { Store } from 'src/store/index';

const getSelectorAvailableOptions = (store: Store, selectorTypeName: string) => {
  const abstractProduct = getAbstractProduct(store);
  if (!abstractProduct) {
    return [];
  }

  const selectedAttributes = getSelectedProductAttributes(store);
  const attributesToFilter: Attribute[] = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const typeName in selectedAttributes) {
    if (typeName !== selectorTypeName) {
      attributesToFilter.push(selectedAttributes[typeName]);
    }
  }

  const availableProducts = abstractProduct.products.filter((p) => {
    let matches = 0;
    p.attributes.forEach((a) => {
      attributesToFilter.forEach((v) => {
        if (a.typeName === v.typeName && a.value === v.value) {
          matches += 1;
        }
      });
    });
    return matches === attributesToFilter.length;
  });

  const options: { [optionKey: string]: SelectorOption } = {};
  abstractProduct.editorSelectors.forEach((s) => {
    availableProducts.forEach((p) => {
      p.attributes.forEach((a) => {
        if (a.typeName === selectorTypeName) {
          s.options.forEach((o) => {
            if (s.typeName === a.typeName && o.value === a.value) {
              options[`${s.typeName}${o.value}`] = o;
            }
          });
        }
      });
    });
  });

  return Object.values(options);
};

export default getSelectorAvailableOptions;
