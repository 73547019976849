import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'src/component/Popup';
import goToOptimalprintOperation from 'src/store/app/operation/goToOptimalprintOperation';
import hidePopupOperation from 'src/store/app/operation/hidePopupOperation';
import getForwardUrl from 'src/store/app/selector/getForwardUrl';
import getFrameUpsellData from 'src/store/upsell/selector/getFrameUpsellData';
import updateBundledProductsOperation from 'src/store/app/operation/updateBundledProductsOperation';
import changeBundledProductOperation from 'src/store/productBundle/operation/changeBundledProductOperation';
import getFrameUpsellFrameProductId from 'src/store/upsell/selector/getFrameUpsellFrameProductId';
import fillBundleGroupId from 'src/util/bundles/fillBundleGroupId';
import gtmSendEvent from 'src/util/gtm/gtmSendEvent';
import Button from '../Button';
import FrameSelector from './FrameSelector';
import styles from './index.module.scss';
import ProductPreview from './ProductPreview';
import VoucherText from './VoucherText';

const FrameSelectorPopup = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const forwardUrl = useSelector(getForwardUrl);
  const popupTitle = useSelector(getFrameUpsellData)?.title || '';
  const frameProductId = useSelector(getFrameUpsellFrameProductId) || 0;

  const closePopup = () => {
    dispatch(hidePopupOperation());
    dispatch(goToOptimalprintOperation(forwardUrl));
  };

  const continueWithoutFrame = () => {
    gtmSendEvent({
      event: 'api_upsell_frames_continue_without_frame',
    });
    closePopup();
  };

  const continueWithFrame = async () => {
    const selectedFrame = {
      bundleGroupId: 0,
      productId: frameProductId,
    };
    const selectedBundledProducts = fillBundleGroupId([selectedFrame]);
    dispatch(hidePopupOperation());
    await dispatch(changeBundledProductOperation(selectedBundledProducts[0]));
    await dispatch(updateBundledProductsOperation());
    gtmSendEvent({
      event: 'api_upsell_frames_add_frame_to_basket',
    });
    closePopup();
  };

  return (
    <Popup
      popupId="SelectFramePopup"
      title={popupTitle}
      onClosePopup={closePopup}
    >
      <div className={styles.frameSelectorPopup}>
        <VoucherText />
        <FrameSelector />
        <ProductPreview />
        <div className={styles.buttonGroup}>
          <Button
            onClick={continueWithFrame}
            primary
            className={styles.withFrame}
            disabled={frameProductId === 0}
            text={t('txt_button_add_frame_to_the_basket')}
          />
          <Button
            onClick={continueWithoutFrame}
            text={t('txt_continue_without_the_frame')}
            className={styles.withoutFrame}
          />
        </div>
      </div>
    </Popup>
  );
};

export default FrameSelectorPopup;
