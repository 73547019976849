import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import { MESSAGE_TYPE } from 'src/store/app/types';
import { Store } from 'src/store/index';

const sendPreventImageelementDeletionOperation = (
  enabled: boolean,
) => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => dispatch(sendPostMessage({
  type: MESSAGE_TYPE['editor.allowLockedImageElementDeletion'],
  data: !enabled,
}));
export default sendPreventImageelementDeletionOperation;
