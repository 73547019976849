import { AnyAction } from 'redux';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import { setDesignTotalPagesCountAction } from 'src/store/design/slice';

const setDesignTotalPagesCountOperation = (pagesCount: number) => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => {
  await dispatch(setDesignTotalPagesCountAction(pagesCount));
};

export default setDesignTotalPagesCountOperation;
