import { AppBundle } from 'src/api/optimalprint-sdk.d';
import { BundledProduct } from 'src/store/productBundle/types';
import { IntegrationLayer } from 'src/types.d';
import { getCurrentProduct } from '../formats/generateFormatTabData';

type ProductItem = AppBundle.Api.Entity.App.V1.AbstractProduct.ProductItem;

export default function resolveBundledProducts(integrationLayer: IntegrationLayer, productId: number, bundledProduts: BundledProduct[]) {
  const result: ProductItem[] = [];
  const currentProduct = getCurrentProduct(integrationLayer, productId);
  bundledProduts.forEach((selectedProduct) => {
    const targetBundleGroup = currentProduct.bundleGroups.find((bundleGroup) => bundleGroup.bundleGroupId === selectedProduct.bundleGroupId);
    if (!targetBundleGroup) return;

    const targetProduct = targetBundleGroup.items?.find((item) => item.productId === selectedProduct.productId);
    if (!targetProduct) return;

    result.push(targetProduct as ProductItem);
  });
  return result;
}
