import { createSlice } from '@reduxjs/toolkit';
import addUpsellPriceReducer from 'src/store/upsell/reducer/addUpsellPriceReducer';
import clearUpsellPriceReducer from 'src/store/upsell/reducer/clearUpsellPriceReducer';
import updateUpsellTotalPriceReducer from 'src/store/upsell/reducer/updateUpsellTotalPriceReducer';
import setUpsellPriceDataReducer from 'src/store/upsell/reducer/setUpsellPriceDataReducer';
import setFrameUpsellDataReducer from 'src/store/upsell/reducer/setFrameUpsellDataReducer';

import { UpsellState } from 'src/store/upsell/types';
import setFrameUpsellSelectedFrameReducer from './reducer/setFrameUpsellSelectedFrameReducer';
import setPersonalizedOpplusReducer from './reducer/setPersonalizedOpplusReducer';

export const upsellInitialState: UpsellState = {
  upsellPrice: {},
  upsellTotalPrice: {
    price: 0,
    priceInitial: 0,
  },
  upsellPriceData: {},
  frameUpsellPopupData: undefined,
  frameUpsellSelectedFrame: undefined,
  personalizedOpplusDetails: undefined,
};

export const slice = createSlice({
  name: 'upsell',
  initialState: upsellInitialState,
  reducers: {
    setUpsellPriceDataAction: setUpsellPriceDataReducer,
    addUpsellPriceAction: addUpsellPriceReducer,
    updateUpsellTotalPriceAction: updateUpsellTotalPriceReducer,
    clearUpsellPriceAction: clearUpsellPriceReducer,
    setFrameUpsellDataAction: setFrameUpsellDataReducer,
    setFrameUpsellSelectedFrameAction: setFrameUpsellSelectedFrameReducer,
    setPersonalizedOpplusAction: setPersonalizedOpplusReducer,
  },
});

export const {
  addUpsellPriceAction,
  updateUpsellTotalPriceAction,
  clearUpsellPriceAction,
  setUpsellPriceDataAction,
  setFrameUpsellDataAction,
  setFrameUpsellSelectedFrameAction,
  setPersonalizedOpplusAction,
} = slice.actions;

const addone = slice.reducer;
export default addone;
