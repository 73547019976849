import React from 'react';
import styles from './index.module.scss';

interface Props {

}

const Spinner = (props: Props) => (
  <div className={styles.skChase}>
    <div className={styles.skChaseDot} />
    <div className={styles.skChaseDot} />
    <div className={styles.skChaseDot} />
    <div className={styles.skChaseDot} />
    <div className={styles.skChaseDot} />
    <div className={styles.skChaseDot} />
    <div className={styles.skChaseDot} />
  </div>
);

export default Spinner;
