import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import sendPostMessage from 'src/store/app/operation/sendPostMessage';
import { MESSAGE_TYPE } from 'src/store/app/types';
import getCurrentProductAttributeValue from 'src/store/design/selector/getCurrentProductAttributeValue';
import { Store } from 'src/store/index';

const sendEditorDpiSettingsPostMessageOperiation = (callback?: any) => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const store = getState();
  let low = getCurrentProductAttributeValue(store, 'DpiLevelLow')?.value || 0;
  let medium = getCurrentProductAttributeValue(store, 'DpiLevelMedium')?.value || 0;
  low = 0;
  medium = 0;

  dispatch(sendPostMessage({
    type: MESSAGE_TYPE['editor.setConfigurableSettings'],
    data: {
      dpiLevels: {
        low,
        medium,
      },
    },
    callback,
  }));
};

export default sendEditorDpiSettingsPostMessageOperiation;
