import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import apiTypes from 'src/api/optimalprint-sdk.d';
import { Price as PriceType } from 'src/store/price/types';
import Price from 'src/component/Price';
import setOpenedDropdownMenuOperation from 'src/store/app/operation/setOpenedDropdownMenuOperation';
import sendDesignFormatChangePostMessageOperation
  from 'src/store/design/operation/sendDesignFormatChangePostMessageOperation';
import getQuantity from 'src/store/design/selector/getQuantity';
import getOneFormat from 'src/store/format/selector/getOneFormat';
import { Store } from 'src/store/index';
import getAvailableFormats from 'src/store/format/selector/getAvailableFormats';
import { FormatData } from 'src/store/format/types';
import getCurrencyId from 'src/store/price/selector/getCurrencyId';
import getPrice from 'src/store/price/selector/getPrice';
import getPriceFormat from 'src/store/price/selector/getPriceFormat';
import styles from './index.module.scss';

interface OwnProps {
  data: FormatData;
}

interface Props extends OwnProps {
  price?: apiTypes.AppBundle.Api.Entity.Product.V1.IProductPrice;
  isSelected: boolean;
  extendedFormatInfo?: apiTypes.AppBundle.Api.Entity.PaperFormat.V1.IPaperFormat;
  currencyId?: string;
  priceFormat?: string;
  dispatch: ThunkDispatch<Store, void, AnyAction>;
}

const AvailableFormat = (
  {
    data,
    price,
    isSelected,
    extendedFormatInfo,
    currencyId,
    priceFormat,
    dispatch,
  }: Props,
) => {
  const { t } = useTranslation();
  const onClick = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    await dispatch(setOpenedDropdownMenuOperation(undefined));
    await dispatch(sendDesignFormatChangePostMessageOperation(data));
  };

  if (!price || !priceFormat || !currencyId) {
    return null;
  }

  let name;
  if (extendedFormatInfo) {
    name = extendedFormatInfo.name;
  } else {
    name = data.name;
  }
  const displayPrice: PriceType = { price: price.p, priceInitial: price.pi };

  return (
    <div
      className={styles.format}
      onClick={onClick}
    >
      <div className={styles.checkbox}>
        <input
          type="radio"
          name="alternate_format"
          value={data.productUid}
          defaultChecked={isSelected}
        />
      </div>
      <div className={styles.nameAndPrice}>
        <div>{name}</div>
        <div>
          <div className={styles.from}>{`${t('txt_from_price')}:`}</div>
          <Price price={displayPrice} currencyId={currencyId} priceFormat={priceFormat} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (store: Store, ownProps: OwnProps) => ({
  possibleFormat: getAvailableFormats(store),
  price: getPrice(store, ownProps.data.categoryId, ownProps.data.productId, getQuantity(store) as number),
  extendedFormatInfo: getOneFormat(store, ownProps.data),
  currencyId: getCurrencyId(store),
  priceFormat: getPriceFormat(store),
});

export default connect(mapStateToProps)(AvailableFormat);
