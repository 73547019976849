import { editorIframeElementId } from 'src/component/Editor';
import { Message } from 'src/store/app/types';

const sendPostMessage = (
  message: Message,
) => async () => {
  const iframe = (document as any).getElementById(editorIframeElementId).contentWindow;
  iframe.postMessage(message, '*');
};

export default sendPostMessage;
