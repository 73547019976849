import { AnyAction } from 'redux';
import { FormatData } from 'src/store/format/types';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import { setCurrentFormatAction } from 'src/store/format/slice';
import {
  setDesignIsLoadingAction,
  setDesignEncPublicIdAction,
  setDesignProductIdAction,
  setDesignCategoryIdAction,
  setDesignProductUidAction,
  setDesignInternalUidAction,
} from '../slice';
import updateProductAttributesOperaion from './updateProductAttributesOperaion';

const designFormatChangedOperation = (format: FormatData) => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
) => {
  await dispatch(setDesignProductUidAction(format.productUid));
  await dispatch(setDesignInternalUidAction(format.internalUid));
  await dispatch(updateProductAttributesOperaion());
  await dispatch(setDesignEncPublicIdAction(format.encPublicDesignId));
  await dispatch(setDesignProductIdAction(format.productId));
  await dispatch(setDesignCategoryIdAction(format.categoryId));
  await dispatch(setCurrentFormatAction(format));
  await dispatch(setDesignIsLoadingAction(false));
};

export default designFormatChangedOperation;
