import { AnyAction } from 'redux';
import { opApiRequest } from 'src/api/apiRequest';
import endpoints from 'src/api/opEndpoints';
import goToOptimalprintOperation from 'src/store/app/operation/goToOptimalprintOperation';
import getBasketUrl from 'src/store/app/selector/getBasketUrl';
import updateCustomerDesignOperation from 'src/store/design/operation/updateCustomerDesignOperation';
import getOrderItemId from 'src/store/design/selector/getOrderItemId';
import getOriginalQuantity from 'src/store/design/selector/getOriginalQuantity';
import getQuantity from 'src/store/design/selector/getQuantity';
import isDesignSaving from 'src/store/design/selector/isDesignSaving';
import { setDesignIsSavingAction, setDesignIsLoadingAction } from 'src/store/design/slice';
import { Store } from 'src/store/index';
import { ThunkDispatch } from 'redux-thunk';
import isFeatureEnabled from 'src/util/feature/isFeatureEnabled';
import getStructure from 'src/store/design/selector/getStructure';
import isDesignEmpty from 'src/util/design/isDesignEmpty';
import updateBundledProductsOperation from './updateBundledProductsOperation';
import { Feature } from '../types';
import sendEmptyProductWarningOperation from './sendEmptyProductWarningOperation';

const updateOrderItemOperation = () => async (
  dispatch: ThunkDispatch<Store, undefined, AnyAction>,
  getState: () => Store,
) => {
  const store = getState();
  const designData = getStructure(store);

  if (isDesignSaving(store)) {
    return;
  }

  if (isFeatureEnabled(Feature.DisplayWarningPopupWhenEmptyDesign) && designData && isDesignEmpty(designData)) {
    dispatch(sendEmptyProductWarningOperation());
    return;
  }

  try {
    await dispatch(setDesignIsSavingAction(true));
    await dispatch(setDesignIsLoadingAction(true));
    await dispatch(updateCustomerDesignOperation());
    await dispatch(updateBundledProductsOperation());
    if (getOrderItemId(store) && getQuantity(store) !== getOriginalQuantity(store)) {
      try {
        await opApiRequest(
          endpoints.CART_V1_ITEM_QUANTITY_UPDATE,
          {
            quantity: getQuantity(store),
            orderItemId: getOrderItemId(store),
          },
          'data',
          'POST',
        );
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Cannot update customer order item quantity', e);
        throw e;
      }
    } else {
      // eslint-disable-next-line no-console
      console.error('Cannot update customer order item quantity - orderItemId not provided');
    }

    dispatch(goToOptimalprintOperation(getBasketUrl(store)));
  } catch (e) {
    await dispatch(setDesignIsSavingAction(false));
    await dispatch(setDesignIsLoadingAction(false));
    // eslint-disable-next-line no-console
    console.error('Cannot update customer order item', e);
  }
};

export default updateOrderItemOperation;
