import apiTypes from 'src/api/optimalprint-sdk.d';
import { Store } from 'src/store/index';

const getPrice = (store: Store, categoryId: number, productId: number, quantity: number, softPriceCheck = false) => {
  if (!store.price.prices) {
    return undefined;
  }

  const categoryPrices = store.price.prices[categoryId];
  if (!categoryPrices) {
    return undefined;
  }

  const productPrice = categoryPrices[productId] as unknown as apiTypes.AppBundle.Api.Entity.Product.V1.ProductPriceListProduct;
  if (!productPrice || !productPrice.prices) {
    return undefined;
  }

  const priceForQuantity = productPrice.prices.find((pq: apiTypes.AppBundle.Api.Entity.Product.V1.IProductPrice) => pq.q === quantity);
  if (!priceForQuantity && softPriceCheck) {
    return {
      p: 0,
      pi: 0,
    } as apiTypes.AppBundle.Api.Entity.Product.V1.IProductPrice;
  }
  return priceForQuantity;
};

export default getPrice;
