import { cloneDeep } from 'lodash';
import { AppBundle } from 'src/api/optimalprint-sdk.d';
import { MESSAGE_TYPE } from 'src/store/app/types';
import { BundledProduct } from 'src/store/productBundle/types';
import { IntegrationLayer } from 'src/types.d';
import getBundleProductsSelectors from './getBundledProductsSelectors';
import mapSlectorsForApiEditor from './mapSlectorsForApiEditor';

type ProductAttribute = AppBundle.Api.Entity.App.V1.AbstractProduct.ProductItem.IAttribute;
type ProductItem = AppBundle.Api.Entity.App.V1.AbstractProduct.ProductItem;
type ProductPageSelector = AppBundle.Api.Entity.App.V1.AbstractProduct.ProductPageSelector;
type ProductPageSelectorOption = AppBundle.Api.Entity.App.V1.AbstractProduct.ProductPageSelector.ProductPageSelectorOption;

export type EditorSelectorOptionWithValue = ProductPageSelectorOption & {
  productId?: number;
  active?: boolean;
  priceInitialFormatted?: string;
  priceFormatted?: string;
  callback?: {
    type: MESSAGE_TYPE;
    data: any;
  };
}
type EditorSelectorWithValue = ProductPageSelector & {
  options: EditorSelectorOptionWithValue[];
  value: string;
}

type EditorSelectorValue = {
  [typeName: string]: string;
}

export type FormatsTabData = {
  selectors: ProductPageSelector[];
  productIdsForPriceRquest: number[];
  priceData: {
    [productId: string]: {
      priceInitial: number;
      price: number;
      priceInitialFormatted: string;
      priceFormatted: string;
    };
  };
}

const getAllProducts = (integrationLayer: IntegrationLayer) => (
  (integrationLayer.abstractProduct && integrationLayer.abstractProduct.products as any[] as ProductItem[]) || []
);

export const getCurrentProduct = (integrationLayer: IntegrationLayer, productId: number) => (
  getAllProducts(integrationLayer).find((product) => product.productId === productId) as ProductItem
);

export const getFixedAttributes = (integrationLayer: IntegrationLayer, productId: number) => {
  const currentProduct = getCurrentProduct(integrationLayer, productId);
  if (!currentProduct) return [];

  const hiddenEditorSelectors = getEditorSelectors(integrationLayer).filter((edSel) => edSel.isHidden);
  const allProductAttributes = currentProduct.attributes
    .filter((attr) => hiddenEditorSelectors.find((edSel) => edSel.typeName === attr.typeName));
  return allProductAttributes;
};

export const getMatchingProducts = (integrationLayer: IntegrationLayer, fixedAttributes: any[]) => (
  getAllProducts(integrationLayer).filter((product) => productMatchAttributes(product, fixedAttributes))
);

export const getEditorSelectors = (integrationLayer: IntegrationLayer) => (
  integrationLayer.abstractProduct?.editorSelectors || []
);

const productMatchAttributes = (product: ProductItem, attributesToMatch: ProductAttribute[]) => (
  !attributesToMatch.some((attribute) => {
    const attrMatch = product.attributes
      .find((productAttribute) => productAttribute.typeName === attribute.typeName && productAttribute.value === attribute.value);
    return !attrMatch;
  })
);

export const getAttributeValue = (product: ProductItem, attrName: string) => (
  product.attributes.find((attr) => attr.typeName === attrName)?.value
);

export const getOptionsForAttribute = (typeName: string, products: ProductItem[], filterAttributes: ProductAttribute[]) => {
  let availableOptions: string[] = [];
  products.forEach((product) => {
    if (productMatchAttributes(product, filterAttributes)) {
      availableOptions.push(getAttributeValue(product, typeName) || '');
    }
  });
  // remove duplications
  availableOptions = [...new Set(availableOptions)];
  return availableOptions;
};

const collectOptionsForSelectors = (
  integrationLayer: IntegrationLayer, product: ProductItem, fixedAttributes: ProductAttribute[],
  forcedSelectorValues: EditorSelectorValue,
) => {
  // go through products to find matching fixed attributes
  const matchingProducts = getMatchingProducts(integrationLayer, fixedAttributes);

  const resultSelectors = cloneDeep(getEditorSelectors(integrationLayer).filter((edSel) => !edSel.isHidden));

  const filterAttributes = [...fixedAttributes];
  resultSelectors.forEach((attribute, index: number) => {
    // for matching products collect available values for attribute
    const availableValues = getOptionsForAttribute(attribute.typeName, matchingProducts, filterAttributes);

    // in resultSelectors keep only values which is avaiable
    const currentSelector = resultSelectors.find((es) => es.typeName === attribute.typeName);
    if (!currentSelector) return;

    currentSelector.options = currentSelector.options
      .filter((option) => availableValues.indexOf(option.value) >= 0)
      .map((option) => {
        (option as EditorSelectorOptionWithValue).callback = {
          type: MESSAGE_TYPE['editor.setFormatSelectorValue'],
          data: {
            typeName: currentSelector.typeName,
            value: option.value,
          },
        };
        return option;
      });

    // mark current value as selected
    const currentAttributeValue = forcedSelectorValues[attribute.typeName] || getAttributeValue(product, attribute.typeName) || '';
    const activeOption = currentSelector?.options.find((option) => option.value === currentAttributeValue) as EditorSelectorOptionWithValue;

    if (activeOption) {
      activeOption.active = true;
    }

    // additional way to set active selector value
    (currentSelector as EditorSelectorWithValue).value = currentAttributeValue;

    // add more filter parameter to limit options for next selectors
    filterAttributes.push({
      ...attribute,
      value: currentAttributeValue,
    });
  });
  return resultSelectors;
};

const fillProductIdsForLastSelector = (
  integrationLayer: IntegrationLayer, selectors: EditorSelectorWithValue[], currentProduct: ProductItem, forcedSelectorValues: EditorSelectorValue,
) => {
  const targetSelector = selectors[selectors.length - 1];
  let productAttributes = cloneDeep(currentProduct.attributes);
  Object.keys(forcedSelectorValues).forEach((forcedAttrName) => {
    const productAttr = productAttributes.find((attr) => attr.typeName === forcedAttrName);
    if (productAttr) {
      productAttr.value = forcedSelectorValues[forcedAttrName];
    }
  });

  // productAttributes should have attributes defined in editor selectors only
  const editorSelectors = getEditorSelectors(integrationLayer);
  productAttributes = productAttributes.filter((attr) => editorSelectors.find((edSel) => edSel.typeName === attr.typeName));

  const productIds: number[] = [];
  const targetAttributeIndex = productAttributes.findIndex((attr) => attr.typeName === targetSelector.typeName);
  targetSelector.options.forEach((option: EditorSelectorOptionWithValue) => {
    productAttributes[targetAttributeIndex].value = option.value;
    const matchingProducts = getMatchingProducts(integrationLayer, productAttributes);
    if (matchingProducts.length > 1) {
      // eslint-disable-next-line no-console
      console.error('More than one product with selector attributes found', matchingProducts, productAttributes);
    }
    if (matchingProducts.length === 0) {
      // eslint-disable-next-line no-console
      console.error('No product with selector attributes found', productAttributes);
      return;
    }
    option.productId = matchingProducts[0].productId;
    option.callback = {
      type: MESSAGE_TYPE['editor.formatChangeRequested'],
      data: {
        productId: matchingProducts[0].productId,
        productUid: matchingProducts[0].productUid,
        internalUid: matchingProducts[0].internalUid,
        categoryId: integrationLayer.categoryId,
      },
    };
    productIds.push(matchingProducts[0].productId);
  });
  targetSelector.options = targetSelector.options.filter((option: EditorSelectorOptionWithValue) => option.productId);
  return productIds;
};

const generateFormatTabData = (
  integrationLayer: IntegrationLayer, productId: number,
  forcedSelectorValues: EditorSelectorValue, bundledProducts: BundledProduct[],
) => {
  const currentProduct = getCurrentProduct(integrationLayer, productId);
  if (!currentProduct) {
    return null;
  }

  const fixedAttributes = getFixedAttributes(integrationLayer, productId);
  const formatSelectors = mapSlectorsForApiEditor(collectOptionsForSelectors(integrationLayer, currentProduct, fixedAttributes, forcedSelectorValues));
  const bundledProductsSelectors = getBundleProductsSelectors(currentProduct, bundledProducts);

  const productIdsForPriceRquest = fillProductIdsForLastSelector(
    integrationLayer, formatSelectors as EditorSelectorWithValue[], currentProduct, forcedSelectorValues,
  );

  return {
    selectors: [...formatSelectors, ...bundledProductsSelectors],
    productIdsForPriceRquest,
    priceData: {},
  } as FormatsTabData;
};

export default generateFormatTabData;
