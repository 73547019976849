import { DesignData } from 'src/store/design/types';

export interface DesignStat {
  totalSpreads: number;
  totalPages: number;
  totalPagesWithContent: number;
  pagesWithContent: {
    [pageName: string]: number;
  };
}

const getDesignStat = (designData?: DesignData) => {
  const stat: DesignStat = {
    totalSpreads: 0,
    totalPages: 0,
    totalPagesWithContent: 0,
    pagesWithContent: {},
  };
  if (!designData) {
    return stat;
  }

  stat.totalSpreads = designData.spreads.length;
  designData.spreads.forEach((spread) => {
    spread.pages.forEach((page) => {
      stat.totalPages += 1;
      if (page.groups.media) {
        let elementsOnPage = 0;
        page.groups.media.forEach((el) => {
          if (!el.sample) elementsOnPage += 1;
        });
        if (elementsOnPage > 0) {
          stat.totalPagesWithContent += 1;
          if (!stat.pagesWithContent[spread.name]) stat.pagesWithContent[spread.name] = 0;
          stat.pagesWithContent[spread.name] += 1;
        }
      }
    });
  });
  return stat;
};

export default getDesignStat;
