import { FileUploader } from 'file-uploader';
import React, { MutableRefObject, useEffect } from 'react';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import ApiFileUploader from 'src/component/ApiFileUploader';
import Editor from 'src/component/Editor';
import TopBar from 'src/component/TopBar';
import { connect } from 'react-redux';
import { Store } from 'src/store';
import setProductBundleSelectedProductsOperation
  from 'src/store/productBundle/operation/setProductBundleSelectedProductsOperation';
import FrameSelectorPopup from 'src/component/FrameSelectorPopup';
import { BundledProduct } from 'src/store/productBundle/types';
import useQuery from 'src/util/hook/useQuery';
import fillBundleGroupId from 'src/util/bundles/fillBundleGroupId';
import Backdrop from 'src/component/Backdrop';
import QuantitySelectPopup from 'src/component/QuantitySelectPopup';
import PromotePlusPopup from 'src/component/PromotePlusPopup';
import styles from './index.module.scss';

export interface OwnProps {
  uploaderRef: MutableRefObject<FileUploader | undefined>;
  dispatch: ThunkDispatch<Store, void, AnyAction>;
}

export interface Props extends OwnProps {

}

const Home = ({ uploaderRef, dispatch }: Props) => {
  const query = useQuery();

  useEffect(() => {
    const bundledProductsJson = query.get('bundledProducts');

    if (bundledProductsJson) {
      const decodedObject = JSON.parse(bundledProductsJson) as [{ bgid: string; pid: string }];
      const bundledProducts = decodedObject.map((e) => ({
        bundleGroupId: Number(e.bgid),
        productId: Number(e.pid),
      } as BundledProduct));
      dispatch(setProductBundleSelectedProductsOperation(bundledProducts));
    }
    // when edit product from basket
    if (window.integrationLayer.editorMode === 'edit-from-basket' && window.integrationLayer.bundledProducts) {
      const bundledProducts = fillBundleGroupId(window.integrationLayer.bundledProducts);
      dispatch(setProductBundleSelectedProductsOperation(bundledProducts));
    }
  }, []);

  return (
    <div className={styles.home}>
      <TopBar />
      <Editor />
      <ApiFileUploader uploaderRef={uploaderRef} />
      <Backdrop />
      <FrameSelectorPopup />
      <QuantitySelectPopup />
      <PromotePlusPopup />
    </div>
  );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(Home);
